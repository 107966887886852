@import './../../../config/style/style.scss';
@import './../../../config/style/mixins.scss';

.student-monetary-aid-content-table-container {
  width: 88%;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 10px;
  box-shadow: 1px 1px 4px $gray-tree;
  .student-monetary-aid-content-table-header {
    border-bottom: 2px solid rgba(0, 255, 13, 0.322);
    padding-bottom: 1rem;
    h1 {
      font-size: 1.5rem;
      font-style: italic;
    }
  }
  .student-monetary-aid-content-table-chart {
    @include displayFlex(column, center, center);
    width: 100%;
    .dashboard-content {
      margin-top: 2rem;
      h1 {
        font-size: 1.5rem;
        font-style: italic;
      }
    }
  }
}
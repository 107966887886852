@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.content-table-municipal-education-plan-monitoring-sheet-strategy {
  margin-top: 3rem;
  .button-content {
    @include displayFlex(row, flex-end, end);
    width: 100%;
    .button-element {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border: none;
      color: #fff;
      &.delete-button {
        background-color: crimson;
      }
      &.save-button {
        background-color: $green;
      }
    } 
  }
  .content-table-municipal-education-plan-monitoring-sheet-strategy-table {
    border-collapse: collapse;
    width: 100%;
    th {
      font-size: 1.3rem;
    }
    td, th {
      border: 1px solid black;
      height: 50px;
      input {
        text-align: center;
        height: 50px;
        border: 0px;
        outline: none;
      }
      .strategy-text-area {
        text-align: center;
        width: 100%;
        min-width: 150px;
        height: 100px;
        min-height: 100px;
        border: none;
      }
      .input-select {
        margin-top: 0px;
        .input-content-select {
          margin: 0px;
          input {
            width: 90%;
          }
        }
      }
      .input-content-list-options-y {
        width: 100%;
        top: 60px;
        font-size: 1.3rem;
      }
      &.gray-background {
        background-color: $gray-for;
      }
      &.term {
        .strategy-text-area {
          min-width: 50px;
          width: 50px;

        }
      }
    }
  }
}

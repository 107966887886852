@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';
.form-add-container {
    position: absolute;
    top: 0px;
    @include displayFlex(column, center, center);
    width: 100%;
    height: 90vh;
    z-index: 3;
    &.display-false {
        display: none;
    }
    .form-add-element {
        overflow: hidden;
        background-color: white;
        width: 500px;
        border-radius: 3px;
        box-shadow: 1px 1px 4px $gray-tree;
        .header-form-add-element-content {
            @include displayFlex(row, space-between, center);
            padding: 1rem 3rem;
            margin-bottom: 2rem;
            color: $font-color-primary;
            background-color: #13975a;
            h1 {
                font-size: 1.7rem;
                padding: 0px;
                color: $font-color-primary;
            }
            svg {
                cursor: pointer;
            }
        }
        .body-form-add-element-content {
            overflow-y: auto;
            padding: 3rem;
            height: 380px;
            padding-bottom: 0px;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $green;
                margin: 0.2rem;
                border-radius: 20px;
            }
            .input-content {
                padding: 0px;
                @include displayFlex(column, center, flex-start);
                margin-bottom: 2rem;
                label {
                    cursor: pointer;
                    margin-bottom: 0rem;
                    font-size: 1.7rem;
                }
                textarea {
                    width: 100%;
                    max-width: 100%;
                    max-height: 160px;
                    margin-top: 1rem;
                    font-size: 1.6rem;
                    padding: 1rem;
                    outline: none;
                    border-radius: 10px;
                    border: #13975a solid 1px;
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: $green;
                        margin: 0.2rem;
                        border-radius: 20px;
                    }
                }
                input {
                    width: 100%;
                    padding: 1rem 0.5rem;
                    outline: none;
                    border: none;
                    color: $gray;
                    border-bottom: 1px solid $green;
                    background-color: transparent;
                }
                .error-element {
                    padding: 0.5rem;
                    font-size: 1.2rem;
                    color: red;
                }
            }
            .buttons-content {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin: 3rem 0rem;
                button {
                    cursor: pointer;
                    margin-right: 1rem;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    &.save-button {
                        background-color: $green;
                        color: $font-color-primary;
                    }
                    &.cancel-button {
                        border: 1px solid $green;
                    }
                }
            }
        }
    }
}
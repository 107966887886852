@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';
.notification-information-content {
  position: fixed;
  top: 0px;
  width: 800px;
  height: 500px;
  margin-top: 5rem;
  border-radius: 3px;
  box-shadow: 1px 1px 3px $gray-for;
  background-color: #fff;
  z-index: 99999;
  &.display-false {
    display: none;
  }
  .header-title-notification-information-content {
    @include displayFlex(row, space-between, center);
    background-color: #13975a;
    .title-notification-information-content {
      padding: 1rem;
      color: #fff;
      font-size: 0.9rem;
    }
    .close-button-title-notification-content {
      margin-right: 2rem;
      .close-button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-size: 2rem;
        color: #fff;
      }
    }
  }
  .notification-main {
    overflow-y: scroll;
    padding: 3rem;
    height: 390px;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
    }
    .notification-issuer-content {
      .notification-title {
        font-size: 2rem;
        margin-top: 1rem;
        span {
          font-size: 1.7rem;
          font-weight: 300;
        }
      }
      .issuer-information-list {
        @include displayFlex(row, flex-start, center);
        list-style: none;
        padding: 2rem 0rem;
        li {
          @include displayFlex(row, center, center);
          margin-left: 1rem;
          &:nth-child(1) {
            margin-left: 0rem;
          }
          h1 {
            font-size: 1.8rem;
          }
          span {
            margin-left: 1rem;
            font-size: 1.6rem;
          }
        }
      }
    }
    .notification-content-container {
      margin-top: 1rem;
      .notification-content-title-container {
        h1 {
          font-size: 2rem;
        }
      }
      .notification-content-text-container {
        padding: 0rem;
        margin-top: 2rem;
        p {
          font-size: 1.7rem;
        }
      }
    }
    .notification-visualization-content {
      margin-top: 1rem;
      .notification-visualization-content-title {
        h1 {
          font-size: 2rem;
        }
      }
      .notification-visualization-list-content {
        margin-top: 2rem;
        .notification-visualization-list-header {
          box-shadow: 1px 1px 3px $gray-for;
          border-top: 3px solid $green;
          border-radius: 5px;
          padding: 1rem;
          .notification-visualization-list-header-ul {
            display: grid;
            grid-template-columns: 0.2fr 1fr 1fr 1fr;
            justify-content: space-between;
            list-style: none;
            li {
              margin-left: 0rem;
            }
          }
        }
        .notification-visualization-list-main {
          overflow-y: scroll;
          height: 300px;
          padding: 0.2rem;
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-thumb {
              background-color: $green;
              border-radius: 20px;
          }
        }
      }
    }
  }
}
.notification-information-table-element {
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr 1fr;
  box-shadow: 1px 1px 3px $gray-for;
  border-radius: 3px;
  list-style: none;
  margin-top: 1rem;
  padding: 1rem;
  li {
    h1 {
      font-size: 1.3rem;
    }
  }
}
@import '../../../../config/style/mixins.scss';
@import '../../../../config/style/style.scss';

.add-new-register-psychologist {
  overflow: hidden;
  position: absolute;
  width: 700px;
  height: 500px;
  background-color: #fff;
  box-shadow: 1px 1px 4px $gray-tree;
  border-radius: 3px;
  margin-top: 1rem;
  z-index: 9999;
  &.display-false {
    display: none;
  }
  .new-register-psychologist {
    @include displayFlex(row, space-between, center);
    background-color: $green;
    color: #fff;
    padding: 1rem;
    h1 {
      font-size: 1.8rem;
    }
    .icon-content {
      cursor: pointer;
    }
  }
  .form-new-register-psychologist {
    overflow-y: auto;
    @include displayFlex(column, start, center);
    width: 100%;
    height: 80%;
    margin-top: 2rem;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
    .input-content {
      @include displayFlex(column, flex-start, start);
      width: 70%;
      &:nth-child(2) {
        margin-top: 3rem;
      }
      label {
        font-size: 1.8rem;
      }
      .input-element {
        width: 100%;
        outline: none;
        border: none;
        font-size: 1.5rem;
        border-bottom: 1px solid $green;
        padding: 1rem;
      }
      textarea {
        width: 100%;
        max-width: 100%;
        height: 200px;
        padding: 1rem;
        border-radius: 3px;
        outline: none;
        font-size: 1.6rem;
        border: 1px solid $green;
      }
    }
    .button-content-psychologist {
      @include displayFlex(row, end, start);
      width: 70%;
      margin-top: 2rem;
      button {
        cursor: pointer;
        border: none;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        border-radius: 3px;
        background-color: $green;
        color: #fff;
      }
    }
  }
}
@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.nav-component-reading-fluency {
  background-color: $gray;
  width: 300px !important;
  height: 100vh;
  .strategy-title {
    position: relative;
    @include displayFlex(row, space-between, center);
    background-color: $gray-two;
    div {
      cursor: pointer;
      @include displayFlex(column, flex-start, flex-start);
      width: 100%;
      padding: 1.2rem 0.5rem;
      text-align: center;
      color: $font-color-primary;
      h1 {
        width: 100%;
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 1rem;
      }
      p {
        width: 100%;
        font-size: 1.6rem;
        text-align: center;
        font-size: 1.3rem;
        color: $gray-tree;
      }
    }
  }
  .ul-option {
    .logout-user-content {
      cursor: pointer;
      overflow: hidden;
      @include displayFlex(column, space-between, center);
      width: 100%;
      z-index: 9999;
      background-color: #1e232b;
      border-bottom: 1px solid $gray-two;
      li {
        @include displayFlex(row, space-between, center);
        width: 100%;
        height: 20px;
      }
      &:hover {
        background-color: #242a33;
      }
      &.display-false {
        height: 0px;
      }
      h1 {
        padding: 1.5rem 1rem;
        height: 100%;
        margin-bottom: 0px;
        color: $font-color-primary;
      }
      .logout-icon {
        margin-right: 1rem;
        color: $font-color-primary;
      }
    }
    text-decoration: none;
    li {
      position: relative;
      @include displayFlex(row, flex-start, center);
      width: auto;
      position: relative;
      padding: 1rem;
      background-color: $gray;
      z-index: 2;
      h1 {
        width: fit-content;
        margin-left: 1rem;
        font-weight: 500;
        font-size: 1.4rem;
        color: $font-color-primary;
      }
      &:hover {
        cursor: pointer;
        background-color: $gray-two;
        &::before {
          position: absolute;
          top: 0px;
          left: 0px;
          float: left;
          content: '';
          width: 3px;
          height: 100%;
          background-color: $green;
        }
      }
      .option-title-content {
        @include displayFlex(row, space-between, center);
        width: 90%;
        .icon-arrow {
          color: #fff;
          transition: transform 0.2s ease-in-out;
          &.display-true {
            transform: rotate(180deg);
          }
        }
      }
    }
    .management-options {
      overflow: hidden;
      position: relative;
      height: auto;
      width: 100%;
      padding: 0.3rem 0rem;
      z-index: 1;
      background-color: $gray-two;
      transition: height 0.2s ease-in-out;
      &.display-false {
        position: absolute;
        height: 0px;
        padding: 0px;
      }
      li {
        font-size: 1.4rem;
        background-color: $gray-two;
        color: $font-color-primary;

        &:hover {
          background-color: $gray;
        }
      }
      .option-element {
        position: relative;
        .option-element-title-content {
          width: 97%;
          @include displayFlex(row, space-between, center);
          h1 {
            margin: 0px;
          }
        }
        .icon-arrow {
          transition: transform 0.2s ease-in-out;
          &.display-true {
            transform: rotate(180deg);
          }
        }
        .management-options-element {
          overflow: hidden;
          position: -webkit-sticky;
          left: 0px;
          height: 200px;
          top: 10px;
          width: 100%;
          padding: 0.3rem 0rem;
          background-color: $gray;
          z-index: 9999;
        }
      }
      .option-element-rooms {
        position: relative;
        padding: 0px;
        height: auto;
        background-color: $gray-two;
        &::before {
          background-color: transparent;
        }
        .option-education {
          width: 100%;
          .icon-arrow {
            margin-right: 2rem;
          }
        }
        .option-element-rooms-edu {
          width: 100%;
          &.display-false {
            overflow: hidden !important;
            height: 0px !important;
          }
          .monthly-plan-options {
            li {
              background-color: #2d343d !important;
            }
          }
          li {
            background-color: #232931 !important;
            &:hover {
              background-color: #191e24 !important;
            }
          }
        }
        .option-of-rooms {
          @include displayFlex(column, flex-start, flex-start);
          width: 100%;
          height: 0px;
          padding: 0px;
          background-color: #1e232b;
          &.display-true {
            height: auto;
          }
          li {
            width: 100%;
            background-color: #1b1f25;
            &:hover {
              background-color: #242a33;
            }
          }
        }
      }
    }
  }
}

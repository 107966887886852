@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-workshop-student-report {
  position: relative;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100%;
  margin-top: 0rem;
  h1 {
    padding: 2rem 0rem;
  }
  .title-table {
    text-align: start;
    font-style: italic;
    font-size: 1.6rem;
    width: 95%;
  }
  .btn-create-new-coordinator-container {
    @include displayFlex(row, space-between, center);
    width: 90%;
    button {
      cursor: pointer;
      margin-bottom: 2rem;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: transparent;
      border: none;
      background-color: $green;
      color: $font-color-primary;
    }
  }
  .search-filter-content {
    position: relative;
    @include displayFlex(row, flex-start, center);
    width: 95%;
    h2 {
      margin: 0rem 1rem;
    }
    .date-container {
      @include displayFlex(row, center, center);
      margin-left: 1rem;
      .search-input-content {
        &:nth-child(3) {
          margin-left: 0rem;           
        }
      }
    }
    .input-select {
      padding: 0.6rem;
      margin-top: 0px;
      margin-left: 1rem;
      border-radius: 10px;
      box-shadow: 1px 1px 3px $gray-for;
      @include displayFlex(row, flex-start, center);
      .input-content-title {
        @include displayFlex(row, flex-start, center);
        margin-right: 1rem;
        padding: 0rem;
      }
      .input-content-select {
        @include displayFlex(row, center, center);
        margin: 0px;
        input {
          padding: 0px;
        }
      }
      .input-content-list-options-y {
        overflow-y: auto;
        position: absolute;
        padding: 0px;
        top: 40px;
        left: 0px;
        width: 100%;
        max-height: 150px;
        border-radius: 5px;
        box-shadow: 1px 1px 4px $gray-tree;
        background-color: #fff;
        z-index: 99999;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
        }
        &.display-false {
          overflow: hidden;
          height: 0px;
        }
        ul {
          list-style: none;
          width: 100%;
          li {
            cursor: pointer;
            padding: 1rem 2.5rem;
            font-size: 1.6rem;
            &:hover {
              background-color: $gray-for;
            }
          }
        }
      }
    }
    &.search-filter-content .input-content {
      position: relative;
      @include displayFlex(row, center, center);
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 1px 1px 3px $gray-for;
      margin-left: 2rem;
      padding: 0.7rem;
      &:nth-child(1) {
        margin-left: 0px;
      }
      input {
        width: 200px;
        outline: none;
        border: none;
        font-size: 1.5rem;
      }
      &.input-data {
        width: 120px;
      }
    }
    &.search-filter-content .input-content.input-date-content-two {
      margin-left: 0rem; 
    }
    .search-input-content {
      @include displayFlex(row, center, center);
    }
    .search-date-input-content {
      label {
        font-size: 1.5rem;
      }
      input {
        width: 40px;
        margin-left: 0rem;
      }
    }
    .submit-form-button-content {
      margin-left: 1rem;
      button {
        cursor: pointer;
        padding: 0.7rem 1.2rem;
        border-radius: 5px;
        background-color: transparent;
        border: none;
        background-color: $green;
        color: $font-color-primary;
      }
    }
  }
  .header-table-element {
    width: 95%;
    margin-top: 1rem;
    border-top: 2px solid $green;
    border-radius: 5px;
    box-shadow: 1px 1px 3px $gray-for;
    .header-table-ul {
      display: grid;
      grid-template-columns: 0.1fr 0.2fr 1fr 0.5fr 0.5fr 0.5fr 1fr 0.2fr 0.2fr 0.2fr 0.2fr;
      list-style: none;
      .header-table-ul-li {
        text-align: start;
        font-size: 1.5rem;
        padding: 1rem;
        &.visualization-element {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.status-icon-element {
          padding: 1rem;
        }
      }
    }
  }
  .main-table {
    overflow-y: auto;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    padding: 1rem 0rem;
    max-height: 54vh;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
  }
}

@media (max-width: 600px) {
  .table-container-workshop-student-report {
    .search-filter-content {
      @include displayFlex(column, center, start);
      .date-container {
        @include displayFlex(row, center, center);
      }
      .btn-create-new-coordinator-container {
        margin: 0px;
        margin-top: 1rem;
      }
      .input-content {
        margin: 0px !important;
        margin-top: 1rem;
      }
      .input-select {
        margin-left: 0px;
        margin-top: 1rem;
      }
      .search-input-content {
        margin-top: 1rem !important;
      }
      .submit-form-button-content {
        margin: 0px;
        margin-top: 1rem;
      }
    }
    .header-table-element {
      .header-table-ul {
        .header-table-ul-li {
          &:nth-child(1) {
            display: none;
            padding: 0rem;
          }
        }
      }
    }
  }
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.workshop-file-delivery-data-weekly-plan {
  overflow-y: auto;
  top: -20px;
  right: 0px;
  position: absolute;
  width: 100%;
  height: 89vh;
  padding: 0rem;
  background-color: #fff;
  z-index: 9999;
  &.display-false {
    display: none;
  }
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  .file-delivery-date-input-year-content {
    h1 {
      font-size: 1.7rem;
      font-weight: 400;
      padding-bottom: 0.5rem;
    }
    input {
      margin-left: 2rem;
      padding: 0.8rem;
      font-size: 1.6rem;
      border-radius: 5px;
      border: none;
      box-shadow: 1px 1px 4px $gray-tree;
      outline: none;
    }
  }
  .kindergarten-school-file-delivery-data-title {
    @include displayFlex(row, space-between, center);
    padding: 2rem;
    h1 {
      padding: 0px;
      font-style: italic;
      font-size: 2rem;
    }
    .button-content {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: $green;
      border: none;
      color: #fff;
      margin-right: 1rem;
    }
  }
  .kindergarten-school-file-delivery-data-input-data {
    @include displayFlex(row, space-between, center);
    padding: 0rem 2rem;
    h1 {
      padding: 0px;
      font-style: italic;
      font-size: 1.8rem;
    }
  }

  .default-background-file-delivery {
    width: 70%;
  }

  .kindergarten-school-file-delivery-data-table {
    @include displayFlex(column, center, center);
    width: 100%;
    table {
      width: 90%;
      margin-top: 2rem;
      border-collapse: collapse;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      td,
      th {
        border: 1px solid black;
        border-right: 0px;
        border-bottom: 0px;
      }

      th {
        background-color: #118951;
        color: #fff;
        font-size: 1.7rem;
        padding: 1rem;
        text-align: start;
      }
      td {
        padding: 1rem;
        font-size: 1.5rem;
        p {
          font-size: 1.5rem;
          margin: 0px;
        }
      }
    }
  }
}

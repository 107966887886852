@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.textual-genre-tag-element {
  cursor: pointer;
  width: 90%;
  box-shadow: 1px 1px 3px $gray-for;
  border-radius: 5px;
  margin-top: 1rem;
  .textual-genre-tag-element-ul {
    width: 100%;
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr 0.3fr 0.3fr;
    list-style: none;
    li {
      padding: 1rem;
      font-size: 1.4rem;
      &.color-content {
        @include displayFlex(row, center, center);
      }
      .textual-genre-tag-element-color-content {
        border-radius: 10px;
      }
    }
  }
  &:hover {
    background-color: #f8f8f8;
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.nav-municipal-education-plan-monitoring-sheet {
  background-color: $gray;
  width: 250px;
  height: 100vh;
  .action-title {
    @include displayFlex(column, center, center);
    padding: 1.5rem;
    text-align: center;
    background-color: $gray-two;
    color: $font-color-primary;
    h1 {
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.3rem;
    }
  }
  .ul-option {
    overflow-y: auto;
    overflow-x: hidden;
    height: 74vh;
    text-decoration: none;
    &:hover {
      &::before {
        content: none;
      }
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      margin: 0.2rem;
      border-radius: 20px;
    }
    li {
      @include displayFlex(row, flex-start, center);
      width: auto;
      position: relative;
      padding: 1rem;
      border-collapse: collapse;
      &.option-content-1 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.11);
      }
      h1 {
        width: fit-content;
        margin-left: 1rem;
        font-weight: 500;
        font-size: 1.4rem;
        color: $font-color-primary;
      }
      .option-title-content {
        @include displayFlex(row, space-between, center);
        width: 100%;
        .icon-arrow {
          color: #fff;
          transition: all 0.2s ease-in-out;
          transform: rotate(0deg);
          &.display-true {
            transform: rotate(180deg);
          }
        }
      }
      &:hover {
        cursor: pointer;
        background-color: $gray-two;
        &::before {
          position: absolute;
          top: 0px;
          left: 0px;
          float: left;
          content: '';
          width: 3px;
          height: 100%;
          background-color: $green;
        }
      }
    }
    .option-of-rooms {
      width: 100%;
      .option-element {
        width: 100%;
        color: #fff;
        font-size: 1.4rem;
        padding-left: 0rem;
        background-color: $gray-two;
        border-bottom: 1px solid #12151a;
        .add-new-goal {
          @include displayFlex(row, center, center);
          width: 100%;
          h1 {
            margin: 0px;
          }
          .plus-icon-element {
            margin-left: 1rem;
          }
        }
      }
      &.display-false {
        display: none;
      }
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-reading-fluency-level-record {
    position: relative;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    .btn-create-new-matter-container {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            margin-right: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .mid-content-writing-level-record {
        @include displayFlex(row, space-between, flex-end);
        width: 90%;
        z-index: 1 !important;
        .mid-element-writing-level-record {
            .header-writing-level-record {
                @include displayFlex(row, flex-start, center);
                h1 {
                    font-style: italic;
                    font-size: 1.7rem;
                    color: $gray;
                    &:nth-child(1) {
                        padding-left: 0px;
                    }
                }
            }
            .dashbord-content {
                width: 100%;
                @include displayFlex(row, flex-end, flex-end);
                height: 130px !important;
                z-index: 1 !important;
            }
        }   
    }
    .header-table-element {
        border-bottom: 2px solid $gray-tree;
        width: 90%;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.2fr 2.3fr 1fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem;
            }
        }
    }
    .main-table {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        padding: 1rem 0rem;
        height: 65vh;
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}
@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.table {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr;
  list-style: none;
  &:hover {
    li {
      background-color: rgba(31, 129, 31, 0.089);
      &.config-content {
        background-color: transparent;
        right: 20px;
        div {
          cursor: pointer;
        }
      }
    }
  }
  li {
    background-color: #fff;
    border: 2px solid #0000003a;
    border-left: 0px;
    border-top: 0px;
    &:nth-child(1) {
      border-left: 2px solid #0000003a;
    }
    h1 {
      font-size: 1.5rem;
      font-weight: 400;
      padding: 0.5rem;
      color: #000;
      border-collapse: collapse;
      border-bottom: 0px;
    }
    &.time-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      h1 {
        &:nth-child(2) {
          border-left: 2px solid #0000003a;
        }
      }
    }
    &.config-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: absolute;
      top: 2px;
      right: -100px;
      border: none;
      transition: all 0.2s ease-in-out;
      div {
        &:nth-child(2) {
          margin-left: 1rem;
        }
      }
    }
  }
}
@import "../../../config/style/style.scss";
@import "../../../config/style/mixins.scss";

.dashboard-writing-level-coordinator { 
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 84vh;
    background-color: #ffff;
    .button-content {
        width: 80%;
        button {   
            cursor: pointer;
            margin-bottom: 2rem;
            margin-right: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary;   
        }
    }
    &.display-false {
        display: none;
    }
    .graphic-content {
        margin-top: 5rem;
        &:nth-child(2) {
            margin-top: 0rem;
        }
    }
    .category-count-information {
      width: 50%;
      ul {
        list-style: none;
        li {
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          h1 {
            font-size: 1.7rem;
            padding: 0px;
            margin-left: 1rem;
          }
          .color-content {
            width: 20px;
            height: 20px;
            border-radius: 100%;
          }
        }
      }
    }
    z-index: 2;
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.dashboard-problem-solving {
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 93vh;
  background-color: #fff;
  z-index: 9999;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  .dashboard-problem-solving-header {
    @include displayFlex(row, space-between, center);
    width: 90%;
    margin-top: 3rem;
    h1 {
      font-style: italic;
      font-size: 1.7rem;
    }
    .button-content {
      @include displayFlex(row, space-between, center);
      button {
        cursor: pointer;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        background-color: $green;
        border: 0px;
        color: #fff;
        &:nth-child(1) {
          margin-right: 2rem;
        }
      }
    }
  }
  &.display-false {
    display: none;
  }
  .dashboard-problem-solving-input-select {
    width: 90%;
  }
  .problem-solving-dashboard-content {
    .dashboard-default-image {
      width: 60%;
    }
    padding-bottom: 2rem;
    .problem-solving-dashboard-info {
      width: 100%;
      padding-bottom: 3rem;
      ul {
        list-style: none;
        li {
          @include displayFlex(row, flex-start, center);
          margin-top: 2rem;
          h1 {
            font-size: 1.5rem;
            margin-left: 1rem;
          }
        }
      }
    }
  }
}
@import '../../../../../config/style/mixins.scss';
@import '../../../../../config/style/style.scss';

.textual-genre-form-edit-tag {
  overflow: hidden;
  position: fixed;
  @include displayFlex(column, flex-start, center);
  top: 200px;
  width: 500px;
  height: 200px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 4px $gray-tree;
  .header-form-edit-textual-genre-tag {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.6rem 3rem;
    background-color: $green;
    color: #fff;
    h1 {
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: 1.5rem;
      margin-left: 1rem;
      padding: 0rem;
    }
    .icon-close {
      cursor: pointer;
      margin-right: 1rem;
    }
  }
  &.display-false {
    display: none;
  }
  .content-form-edit-textual-genre-error {
    width: 90%;
    .display-false {
      display: none;
    }
  }
  .content-form-edit-textual-genre-tag-main {
    @include displayFlex(column, space-between, center);
    width: 100%;
    height: 100%;
    .content-form-edit-textual-genre-tag-main-inputs {
      @include displayFlex(row, space-between, center);
      width: 90%;
      .form-edit-textual-genre-tag-input-content {
        margin-top: 1rem;
        input {
          width: 100%;
          padding: 1rem;
          font-size: 1.5rem;
          border: none;
          outline: none;
          border-bottom: 1px solid $green;
        }
        #textual-genre-form-edit-input-color {
          overflow: hidden;
          position: relative;
          width: 0px;
          height: 0px;
          padding: 0px;
          margin: 0px;
          outline: none;
          border: none;
          background-color: transparent;
        }
        .label-input-color {
          .label-input-color-div {
            width: 100px;
            height: 30px;
            border-radius: 3px;
            border: 1px solid #000000;
          }
        }
      }
    }
    .form-edit-textual-genre-tag-button-content {
      position: relative;
      bottom: 0px;
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin-bottom: 2rem;
      .button-element {
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: $green;
        color: #fff;
        border: none;
        border-radius: 5px;
        &.button-cancel {
          margin-right: 1rem;
          background-color: transparent;
          color: #000;
          border: 1px solid $green;
        }
      }
    }
  }
}

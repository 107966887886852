@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.student-monetary-aid-info-container {
  @include displayFlex(column, flex-start, center);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: #fff;
  .student-monetary-aid-info-header {
    @include displayFlex(row, flex-start, center);
    width: 90%;
    margin-top: 2rem;
    border-top: 2px solid $green;
    border-radius: 5px;
    box-shadow: 1px 1px 4px $gray-tree;
    padding: 1rem 1rem;
    .student-monetary-aid-back-button {
      button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        background-color: $green;
        color: #fff;
        margin-right: 2rem;
      }
    }
    .student-monetary-aid-student-name {
      @include displayFlex(row, flex-start, center);
      h1 {
        font-size: 1.6rem;
      }
      span {
        margin-left: 1rem;
        font-size: 1.6rem;
      }
    }
    .student-monetary-aid-info-input-container {
      margin-left: 2rem;
      .input-select {
        @include displayFlex(row, center, center);
        margin-top: 0px;
        .input-content-select {
          @include displayFlex(row, flex-start, center);
          overflow: hidden;
          width: 100px;
          margin: 0px;
          margin-left: 2rem;
          input {
            width: 70px;
          }
        }
        .input-content-list-options-y {
          width: 90px;
          top: 40px;
          left: 50px;
          li {
            @include displayFlex(row, flex-start, center);
          }
        }
      }
    }
    .student-monetary-aid-submit-button {
      margin-left: 2rem;
      button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        color: #fff;
        font-size: 1.2rem;
        border-radius: 5px;
        border: none;
        background-color: $green;
      }
    }
  }
  .table-student-monetary-rooms-table {
    @include displayFlex(column, flex-start, center);
    overflow-y: scroll;
    margin-top: 2rem;
    width: 100%;
    height: 73vh;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
    .empty-element {
      img {
        width: 550px;
      }
    }
  }
  &.display-false {
    display: none;
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-room-student-observer {
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 85vh;
    margin-top: 3rem;
    .header-table-content {
      @include displayFlex(row, flex-start, flex-start);
      width: 90%;
      h1 {
          font-style: italic;
          font-size: 1.4rem;
          color: $gray;
          &:nth-child(1) {
              padding-left: 0px;
          }
      }
    }
    .btn-create-new-room-container {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .filter-content-action {
        @include displayFlex(row, flex-start, center);
        width: 93%;
        margin-bottom: 3rem;
        h1 {
            font-weight: 500;
            font-size: 1.6rem;
            color: $green;
        }
        .filter {
            @include displayFlex(row, flex-start, center);
            list-style: none;
            gap: 1rem;
        }
    }
    .header-table-element-action {
        width: 90%;
        margin-top: 1rem;
        position: relative;
        border-top: 2px solid $green;
        border-radius: 5px;
        box-shadow: 1px 1px 3px $gray-for;
        z-index: 1;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.3fr 2.7fr 3fr 0.3fr 0.3fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem 1rem;
            }
        }
    }
    .main-table-action {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        height: 100%;
        padding-bottom: 1rem;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}

@media (max-width: 600px) {
  .table-container-room-student-observer {
    
  }
}
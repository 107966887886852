@import './../../../config/style/mixins.scss';

.student-monetary-aid-container {
  position: relative;
  @include displayFlex(row, center, center);
  width: 100%;
  height: 100vh;
  &.display-false {
    display: false;
  }
}
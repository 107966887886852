@import './../../../config/style/style.scss';
@import './../../../config/style/mixins.scss';

.exam-option-content {
  margin-top: 2rem;
  .exam-option-content-ul {
    li {
      @include displayFlex(row, start, center);
      margin-top: 1rem;
      input {
        width: 20px;
        height: 20px;
      }
      label {
        margin-left: 1rem;
        font-size: 1.9rem;
      }
    }
  }
}

@media (max-width: 560px) {
  .exam-option-content {
    .exam-option-content-ul {
      li {
        label {
          font-size: 1.4rem;
        }
      }
    }
  }
}
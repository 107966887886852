.main-container-action {
    display: flex;
    .content-containers {
        scroll-behavior: smooth;
        overflow-y: hidden;
        width: 100%;
        height: 100vh;
        .content {
            width: 100%;
        }
        .content#display-false {
            display: none;
        }
    }
}
@import '../../../../../config/style/style.scss';
@import '../../../../../config/style/mixins.scss';

.secretary-new-writing-hypothesis-graphs-elements {
  width: 100%;
  margin-top: 2rem;
  h1 {
    font-style: italic;
    font-size: 2rem;
  }
  .secretary-new-writing-hypothesis-graphs-elements-header {
    @include displayFlex(row, space-between, center);

    .secretary-new-writing-hypothesis-graphs-elements-header-input-content {
      @include displayFlex(row, flex-start, center);
      .input-select {
        @include displayFlex(row, flex-start, center);
        width: auto;
        display: flex;
        margin-top: 0rem;
        .input-content-select {
          margin-top: 0rem;
          margin-bottom: 0px;
          input {
            width: 100%;
          }
  
        }
        &:nth-child(2) {
          margin-left: 3rem;
        }
        .input-content-list-options-y {
          width: 100%;
          top: 50px;
        }
      }
    }
    .delete-button {
      cursor: pointer;
      padding: 0.5rem 1rem;
      background-color: white;
      border-radius: 5px;
      border: 2px solid red;
      color: red;
    }
  }
  .writing-the-name-count-content {
    margin: 2rem 0rem;
    font-weight: 300;
  }
  .secretary-new-writing-hypothesis-graphs-elements-graph {
    @include displayFlex(row, center, center);
    width: 100%;
  }
}
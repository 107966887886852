@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-add-container-room {
    position: absolute;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100vh;
    &.display-false {
        display: none;
    }
    .form-add-element-room {
        overflow: hidden;
        background-color: white;
        width: 500px;
        border-radius: 3px;
        box-shadow: 1px 1px 4px $gray-tree;
        .header-form-add-element-content {
            @include displayFlex(row, space-between, center);
            padding: 1rem 3rem;
            margin-bottom: 2rem;
            color: $font-color-primary;
            background-color: #13975a;
            h1 {
                font-size: 1.7rem;
                padding: 0rem 0rem;
                color: $font-color-primary;
            }
            svg {
                cursor: pointer;
            }
        }
        .body-form-add-element-content {
            padding: 3rem;
            padding-bottom: 0px;
            .input-content {
                position: relative;
                padding: 0px;
                @include displayFlex(column, center, flex-start);
                margin-bottom: 2rem;
                label {
                    cursor: pointer;
                    margin-bottom: 0rem;
                    font-size: 1.7rem;
                }
                input {
                    width: 100%;
                    padding: 1rem 0.5rem;
                    outline: none;
                    border: none;
                    color: $gray;
                    border-bottom: 1px solid $green;
                    background-color: transparent;
                }
                .icon-container {
                    position: absolute;
                    @include displayFlex(row, center, center);
                    right: 0px;
                    bottom: 0px;
                    height: 60%;
                    .icon-content {
                        cursor: pointer;
                        @include displayFlex(row, center, center);
                        padding: 0.2rem;
                        background-color: $green;
                        border-radius: 100%;
                        
                    }
                }
                .error-element {
                    padding: 0.5rem;
                    font-size: 1.2rem;
                    color: red;
                }
            }
            .error-content {
                font-size: 1.3rem;
                color: red;
            }
            .user-infomation {
                h1 {
                    padding: 0px;
                    margin-bottom: 2rem;
                    font-size: 1.5rem;
                }
                .user-ul {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    list-style: none;
                    li {
                        font-size: 1.2rem;
                    }
                    &.user-information-content {
                        padding: 0.5rem;
                        margin-top: 1rem;
                        border-radius: 5px;
                        box-shadow: 1px 1px 4px $gray-tree;
                    }
                }
            }
            .buttons-content {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin: 3rem 0rem;
                button {
                    cursor: pointer;
                    margin-right: 1rem;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    &.save-button {
                        background-color: $green;
                        color: $font-color-primary;
                    }
                    &.cancel-button {
                        border: 1px solid $green;
                    }
                }
            }
        }
    }
    z-index: 2;
}

@media (max-width: 600px) {
  .form-add-container-room {
    .form-add-element-room {
      width: 100%;
    }
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-final-result {
    position: relative;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    .btn-create-new-matter-container {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .header-cardReport {
        @include displayFlex(row, space-between, center);
        width: 90%;
        .header-cardReport-info {
            @include displayFlex(row, flex-start, flex-start);
            h1 {
                font-style: italic;
                font-size: 1.4rem;
                color: $gray;
                &:nth-child(1) {
                    padding-left: 0px;
                }
            }
        }
        .save-button-cardReport {
            button {
                cursor: pointer;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                background-color: transparent;
                border: none;
                background-color: $green;
                color: $font-color-primary;
            }
        }
    }
    .header-table-element {
        width: 90%;
        background-color: $green;
        border: 2px solid $green;
        .header-table-ul {
          display: grid;
          grid-template-columns: 0.5fr 2fr 1fr 0.5fr 0.5fr 0.5fr 0.7fr 0.5fr;
          list-style: none;
          .header-table-ul-li {
            padding: 1rem;
            text-align: start;
            font-size: 1.5rem;
            color: white;
            &.percent-content {
              text-align: center;
            }
          }
        }
      }
      .main-table {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        padding-top: 0rem;
        height: 55.5vh;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}

@media (max-width: 600px) {
  .table-container-final-result { 
    .table-container {
      @include displayFlex(column, flex-start, start);
      overflow-x: auto;
      max-width: 100%;
      
      .header-table-element {
        min-width: 1000px;
      }
      .main-table {
        @include displayFlex(column, flex-start, start);
        min-width: 1000px;
      }
    }
  }
}
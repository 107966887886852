@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';
.new-writing-hypothesi-graph {
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  width: 100%;
  height: 90vh;
  .button {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: none;
    font-size: 1.5rem;
    border-radius: 5px;
    background-color: $green;
    color: #fff;
  }
  .new-writing-hypothesi-graph-header {
    width: 90%;
    h1 {
      font-size: 2rem;
      font-style: italic;
      padding: 2rem 0rem;
    }
  }
  .new-writing-hypothesi-graph-middle {
    @include displayFlex(row, space-between, center);
    width: 90%;
    .new-writing-hypothesi-graph-inputs-select {
      @include displayFlex(row, center, center);
      margin-top: 1rem;
      h1 {
        font-size: 1.5rem;
        padding: 0px;
      }
      .input-select {
        width: 160px;
        display: flex;
        margin-top: 0rem;
        .input-content-select {
          margin-top: 0rem;
          margin-bottom: 0px;
          input {
            width: 120px;
          }

        }
        &:nth-child(2) {
          margin-left: 3rem;
        }
        .input-content-list-options-y {
          width: 100%;
          top: 50px;
        }
      }
    }
  }
  .new-writing-hypothesi-graph-content {
    img {
      animation: 0.5s ease-in-out entryImg ;
      width: 700px;
      @keyframes entryImg {
        0% {
          width: 650px;
          opacity: 0;
        }
        100% {
          width: 700px;
          opacity: 1;
        }
      }
    }
    .graphic-content {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      animation: 0.5s ease-in-out entryGraph;
      margin-top: 4rem;

      &.display-true {
        opacity: 1;
      }
    
      @keyframes entryGraph {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
  h2 {
    font-size: 2rem;
    margin-top: 2rem;
  }
  .graphic-content-bar {
    margin-top: 5rem;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
}
.main-secretary-resource {
    display: flex;
    .content-containers {
        scroll-behavior: smooth;
        overflow-y: hidden;
        width: 100%;
        height: 100vh;
        .content {
            width: 100%;
            &.default-background {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100vh;
              img {
                width: 50%;
              }
              .default-background-title {
                position: absolute;
                top: 30px;
                h1 {
                  font-size: 4rem;
                  font-weight: 300;
                  font-family: 'Pacifico', cursive;
                }
              }
            }
        }
        .content-resource-management#display-false {
            display: none;
        }
    }
}
@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.input-select {
    position: relative;
    margin-top: 3rem;
    .input-content-title {
        label {
            font-size: 1.9rem;
        }
    }
    .input-content-select {
      @include displayFlex(row, flex-start, center);
        width: 210px;
        margin-bottom: 2rem;
        margin-top: 1rem;
        background-color: #fff;
        input {
            cursor: pointer;
            border: none;
            outline: none;
            padding: 1rem 0.5rem;
            font-size: 1.6rem;
            background-color: transparent;
        }
        div {
            .icon-arrow {
                &.display-true  {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .input-content-list-options-y {
        overflow-y: auto;
        position: absolute;
        padding: 0px;
        top: 80px;
        width: 220px;
        max-height: 150px;
        border-radius: 5px;
        box-shadow: 1px 1px 4px $gray-tree;
        background-color: #fff;
        z-index: 99999;
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
        &.display-false {
            overflow: hidden;
            height: 0px;
        }
        ul {
            list-style: none;
            width: 100%;
            li {
                cursor: pointer;
                padding: 1rem 2.5rem;
                font-size: 1.6rem;
                &:hover {
                    background-color: $gray-for;
                }
            }
        }
    }
}

@media (max-width: 600px) {
  .input-select {
    .input-content-title {
      label {
        font-size: 1.2rem;
      }
    }
  }
}
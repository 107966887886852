@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-individual-card-report {
  width: 95%;
  .header-content {
    width: 100%;
    h1 {
      padding: 2.5rem 0rem;
      font-size: 1.8rem;
      font-style: italic;
    }
    .form-search-student-individual-card-report {
      @include displayFlex(row, flex-start, center);
      .input-select {
        @include displayFlex(row, flex-start, center);
        margin: 0px;
        &:nth-child(1) {
          .input-content-select {
            width: 300px;
            input {
              width: 250px;
            }
          }
          .input-content-list-options-y {
            width: 300px;
          }
        }
        &:nth-child(2) {
          .input-select {
            width: 150px;
            input {
              width: 150px;
            }
          }
          .input-content-list-options-y {
            width: 230px;
            left: 100px;
          }
        }
        .input-content-select {
          width: 250px;
          margin: 0px;
          margin-left: 2rem;
          input {
            width: 200px;
          }
        }
        .input-content-list-options-y {
          left: 130px;
          top: 50px;
          width: 250px;
        }
      }
      .search-button {
        cursor: pointer;
        border: none;
        padding: 0.6rem 1.2rem;
        border-radius: 5px;
        outline: none;
        margin-left: 2rem;
        background-color: $green;
        color: #fff;
      }
    }
  }
  .individual-card-report-container {
    width: 100%;
    min-width: 1000px;
    margin-top: 2rem;
    .student-information-content {
      width: 100%;
      background-color: $green;
      ul {
        @include displayFlex(row, space-between, center);
        width: 100%;
        list-style: none;
        padding: 0px;
        li {
          width: auto;
          height: 100%;
          border-left: 0.5px solid #0a0a0a1f;
          padding: 1rem;
          font-size: 1.5rem;
          color: #fff;
        }
      }
    }
    .individual-card-report-header-container {
      display: grid;
      grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr 1fr;
      background-color: $green;
      &.display-general-false {
        grid-template-columns: 1.2fr 1fr;
      }
      .individual-card-report-header-twoMonths-content {
        width: 100%;
        height: 100%;
        .individual-card-report-header-twoMonths-title {
          padding: 1.5rem;
          border: 0.5px solid #0a0a0a1f;
          border-bottom: none;
          h2 {
            width: 100%;
            text-align: center;
            color: #fff;
          }
        }
        .individual-card-report-header-twoMonths-information {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1.5fr;
          div {
            padding: 0.5rem;
            font-size: 1.2rem;
            text-align: center;
            border: 0.5px solid #0a0a0a1f;
            border-collapse: collapse;
            color: #fff;
          }
        }
      }
    }
    .individual-card-report-header-title {
      @include displayFlex(row, center, center);
      width: 100%;
      height: 100%;
      border: 0.5px solid #0a0a0a1f;
      h1 {
        color: #fff;
      }
    }
    .individual-card-report-table-container {
      overflow-y: auto;
      .individual-card-report-table-row {
        display: grid;
        grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr 1fr;
        background-color: #ffffff60;
        &.display-general-false {
          grid-template-columns: 1.2fr 1fr;
        }
        .matter-content {
          text-align: start;
          font-size: 1.5rem;
          padding: 0.5rem 2rem;
          border: 0.5px solid #0a0a0a1f;
        }
        .matter-information {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1.5fr;
          div {
            padding: 0.5rem 0rem;
            font-size: 1.3rem;
            text-align: center;
            border: 1px solid #0a0a0a1f;
          }
        }
      }
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-spelling-level {
    position: relative;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    .header-writing-level-record {
        width: 90%;
        @include displayFlex(row, flex-start, center);
        h1 {
            font-style: italic;
            font-size: 1.4rem;
            color: $gray;
            &:nth-child(1) {
                padding-left: 0px;
            }
        }
    }
    .btn-create-new-spelling-level-container {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .header-table-element {
        width: 90%;
        border-top: 2px solid $green;
        border-radius: 5px;
        box-shadow: 1px 1px 3px $gray-for;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.2fr 1.8fr 1.1fr 0.2fr 0.2fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem;
            }
        }
    }
    .main-table {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        padding: 1rem 0rem;
        height: 57.5vh;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';


.option-element-content {
  width: 90%;
  margin-top: 2rem;
  .option-element-content-header {
    h1 {
      font-size: 1.5rem;
      color: #818181;
    }
    margin-bottom: 2rem;
  }
  .option-element-content-body {
    @include displayFlex(row, center, center);
    width: 100%;
    .icon-content {
      display: none;
      cursor: pointer;
      margin-left: 2rem;
      font-size: 0rem;
      color: #818181;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      .icon-content {
        font-size: 3.5rem;
        display: inline;
      }
    }
    .options-element-content-table {
      width: 100%;
      .option-element-content-add-new-option {
        margin-top: 2rem;
        button {
          cursor: pointer;
          font-size: 1.7rem;
          color: $green;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-workshop-digital-httpi {
  position: relative;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  z-index: 3;
  .header-digital-httpi {
    @include displayFlex(column, start, start);
    width: 90%;
    h1 {
      padding-left: 0rem;
      text-align: start;
      font-style: italic;
      font-size: 1.6rem;
      width: 90%;
    }
  }
  .btn-create-new-student-container {
    @include displayFlex(row, flex-start, center);
    width: 90%;
    button {
      cursor: pointer;
      margin-bottom: 2rem;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: transparent;
      border: none;
      background-color: $green;
      color: $font-color-primary;
    }
  }
  .header-table-element {
    width: 90%;
    margin-top: 1rem;
    border-top: 2px solid $green;
    border-radius: 5px;
    box-shadow: 1px 1px 3px $gray-for;
    .header-table-ul {
      display: grid;
      grid-template-columns: 0.3fr 2fr 1fr 2fr 0.4fr 0.5fr 0.5fr;
      list-style: none;
      .header-table-ul-li {
        text-align: start;
        font-size: 1.5rem;
        padding: 1rem 1rem;
        &.visualization-content {
          @include displayFlex(row, center, center);
        }
      }
    }
  }
  .search-digital-httpi-content {
    @include displayFlex(row, start, center);
    width: 90%;
    .date-container {
      @include displayFlex(row, flex-start, center);
      margin-left: 1rem;
      .search-input-content {
        &:nth-child(3) {
          margin-left: 0rem;           
        }
      }
    }
    .input-date-content {
      @include displayFlex(row, center, center);
      margin-left: 4rem;
      h2 {
        padding: 0rem 2rem;
      }
      .input-content {
        input {
          border: none;
          padding: 0.8rem 1rem;
          outline: none;
          border-radius: 5px;
          font-size: 1.5rem;
          background-color: #fff;
          box-shadow: 1px 1px 4px $gray-tree;
        }
        &:nth-child(2) {
          margin-left: 2rem;
        }
      }
      .button-content {
        button {
          cursor: pointer;
          margin-left: 1.5rem;
          padding: 0.8rem 1rem;
          border: 0px;
          border-radius: 5px;
          font-size: 1.5rem;
          color: #fff;
          background-color: $green;
        }
      }
    }
  }
  .main-table {
    overflow-y: auto;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    padding: 1rem 0rem;
    height: 49vh;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
  }
}

@media (max-width: 600px) {
  .table-container-workshop-digital-httpi {
    .header-digital-httpi {
      width: 98%;
    }
    .btn-create-new-student-container {
      width: 98%;
    }
    .search-element-digital-httpi {
      width: 100%;
    }
    .search-digital-httpi-content {
      @include displayFlex(column, flex-start, center);
      width: 98%;
      .date-container {
        @include displayFlex(row, flex-start, center);
        .input-content {
          input {
            font-size: 1rem;
          }
        }
      }

      .input-date-content {
        @include displayFlex(column, flex-start, start);
        width: 100%;
        margin: 0px;
      }
      .button-content {
        button {
          margin-left: 0px !important;
          margin-top: 1rem;
        }
      }
    }
    .header-table-element {
      width: 98%;
      .header-table-ul {
        .header-table-ul-li {
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
  }
}

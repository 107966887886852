@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.day-of-elementary-school-HTTPI-content {
  overflow-y: auto;
  position: absolute;
  @include displayFlex(row, center, start);
  top: 0px;
  width: 100%;
  height: 86vh;
  background-color: #fff;
  z-index: 9999;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  &.display-false {
    display: none;
  }
  .day-of-HTTPI-main-content {
    width: 90%;
    .header-content {
      @include displayFlex(row, space-between, start);
      margin-top: 2rem;
      h1 {
        font-size: 2rem;
        font-weight: 500;
        padding: 0.5rem 0rem;
        margin: 0px;
        span {
          font-size: 1.7rem;
          margin-left: 1rem;
        }
      }
      .button-content {
        .save-button {
          &:nth-child(1) {
            margin-right: 2rem;
          }
        }
      }
      .save-button {
        cursor: pointer;
        font-size: 1.4rem;
        margin-top: 1rem;
        background-color: $green;
        color: $font-color-primary;
        outline: none;
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1rem;
      }
    }
    .day-of-httpi-table {
      overflow-x: auto;
      width: 100%;
      margin-top: 3rem;
      .day-of-httpi-table-header {
        min-width: 800px;
        .title-table {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          list-style: none;
          border: 2px solid #0000003a;
          border-bottom: 0px;
          li {
            h1 {
              height: 100%;
              font-size: 1.7rem;
              font-weight: 50;
              padding: 0.5rem;
              color: #000;
              border-left: 2px solid #0000003a;
              span {
                margin-left: 1rem;
              }
            }
            &:nth-child(1) {
              h1 {
                border-left: 0px;
              }
            }
          }
        }
        .table {
          display: grid;
          grid-template-columns: 0.5fr 2fr 1fr 1fr;
          list-style: none;
          li {
            background-color: $green;
            border: 2px solid #0000003a;
            border-left: 0px;
            &:nth-child(1) {
              border-left: 2px solid #0000003a;
              h1 {
                border-right: 0px solid #0000003a;
              }
            }
            h1 {
              font-size: 1.4rem;
              font-weight: bold;
              padding: 0.5rem;
              color: #fff;
              border-collapse: collapse;
              border-bottom: 2px solid #0000003a;
            }
            .time-content {
              display: grid;
              grid-template-columns: 1fr 1fr;
              h1 {
                border-top: 0px;
                border-bottom: 0px;
                border-top: 0px;
                &:nth-child(2) {
                  border-left: 2px solid #0000003a;
                }
              }
            }
          }
        }
      }
      .table-main {
        min-width: 800px;
      }
    }
  }
}

@media (max-width: 930px) {
  .day-of-elementary-school-HTTPI-content {
    height: 90vh;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 5px;
    }
  }
}
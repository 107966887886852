@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.student-exam-question-content {
  position: relative;
  width: 100%;
  padding-bottom: 3rem;
  min-height: 400px;
  margin-top: 4rem;
  border-radius: 10px;
  border-top: 5px solid $green;
  box-shadow: 1px 1px 3px $gray-for;
  &:nth-child(1) {
    margin-top: 3rem;
  }
  .student-exam-question-content-options-button-display {
    position: absolute;
    right: 10px;
    top: 10px;
    button {
      @include displayFlex(row, center, center);
      cursor: pointer;
      width: 30px;
      height: 30px;
      background-color: transparent;
      border: none;
      border-radius: 100%;
      transition: all 0.2s ease-in-out;
      color: $green;
      &:hover {
        background-color: #eeeeee;
      }
    }
    .config-display {
      overflow: hidden;
      position: absolute;
      top: 40px;
      right: 0px;
      height: 75px;
      border-radius: 2px;
      box-shadow: 2px 3px 9px rgb(175, 175, 175);
      transition: all 0.2s ease-in-out;
      background-color: #fff;
      z-index: 999;
      &.display-false {
        height: 0px;
      }
      ul {
        list-style: none;
        li {
          cursor: pointer;
          font-size: 1.5rem;
          width: 100px;
          padding: 1rem;
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }
  }
  .button-add-element-student-exam-content {
    @include displayFlex(row, center, center);
    margin-top: 4rem;
    width: 100%;
    background-color: transparent;
    button {
      @include displayFlex(row, space-between, center);
      position: relative;
      cursor: pointer;
      width: 280px;
      padding: 1rem;
      box-shadow: 2px 2px 9px rgb(175, 175, 175);
      background-color: #ffff;
      border: none;
      border-radius: 4px;
      h1 {
        font-size: 1.5rem;
        color: #747474;
      }
      .icon-content {
        color: #eeeeee;
        background-color: $green;
        border-radius: 100%;
      }
    }
  }
  .student-exam-question-content-elements {
    @include displayFlex(column, start, center);
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.workshop-school-student-report-information {
  overflow-y: auto;
  position: absolute;
  width: 100%;
  height: 90vh;
  top: 0px;
  border-radius: 5px;
  background-color: #fff;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
  }
  &.display-false {
      display: none;
  }
  .header-form-describe-element-content {
      @include displayFlex(row, space-between, center);
      padding: 2rem 4rem;
      font-style: italic;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #fff;
      h1 {
          font-size: 1.7rem;

          padding: 0px;
          color: #000;
      }
      svg {
          cursor: pointer;
      }
      button {
        cursor: pointer;
        border-radius: 5px;
        border: none;
        padding: 0.5rem 1rem;
        color: #fff;
        background-color: $green;
      }
  }
  .main-monthly-plan-display-information {
      padding: 1rem 6rem;
      min-height: 200px;
      max-height: 300px;
      &::-webkit-scrollbar {
          width: 10px;
      }
      &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
      }
      .decent-work-information {
        h1 {
            @include displayFlex(row, flex-start, center);
            font-size: 1.7rem;
            font-style: italic;
            font-weight: bold;
            margin-top: 1rem;
            padding: 0rem 1rem;
            span {
                color: rgb(78, 78, 78);
            }
            .file-description-icon {
                font-size: 2.5rem;
                color: $green;
                margin-right: 1rem;
            }
        }
        .visualization-list {
          list-style: none;
          margin: 2rem 2rem;
          li {
            font-size: 1.5rem;
            margin-top: 1rem;
            font-weight: bold;
            color: $green;
            
          }
        }
    }
      .description-content {
          width: 100%;
          h1 {
              width: 100%;
              text-align: center;
              margin-top: 1rem;
          }
          p {
              font-size: 1.6rem;
              padding: 2rem;
          }
      }
      .button-content {
          @include displayFlex(row, flex-end, center);
          width: 100%;
          margin-top: 2rem;
          button {
              cursor: pointer;
              margin-right: 1rem;
              padding: 0.5rem 1rem;
              border-radius: 5px;
              background-color: transparent;
              border: none;
              &.cancel-button {
                  background-color: $green;
                  color: $font-color-primary;
              }
          }
      }
  }
  .adm-hours-information-frame-content {
    margin-top: 3rem;
  }
  z-index: 999;
}
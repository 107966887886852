@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-rooms {
    position: relative;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    .btn-create-new-rooms-container {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .search-filter-content {
        position: relative;
        @include displayFlex(row, flex-start, center);
        width: 90%;
        .input-content {
            position: relative;
            @include displayFlex(row, center, center);
            overflow: hidden;
            margin-left: 3rem;
            border-radius: 10px;
            box-shadow: 1px 1px 3px $gray-for;
            padding: 0.7rem;
            &:nth-child(1) {
                margin-left: 0px;
            }
            input {
                width: 200px;
                outline: none;
                border: none;
                font-size: 1.5rem;
            }
        }
        .search-input-content {
            @include displayFlex(row, center, center);
        }
        .search-date-input-content {
            margin-left: 3rem;
            label {
                font-size: 1.5rem;
            }
            input {
                width: 60px;
                margin-left: 1rem;
            }
        }
        .submit-form-button-content {
            margin-left: 3rem;
            button {
                cursor: pointer;
                padding: 0.7rem 1.2rem;
                border-radius: 5px;
                background-color: transparent;
                border: none;
                background-color: $green;
                color: $font-color-primary;
            }
        }
    }
    .header-table-element {
        border-top: 2px solid $green;
        border-radius: 5px;
        width: 90%;
        margin: 1rem 0rem 1rem;
        box-shadow: 1px 1px 3px $gray-for;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.2fr .5fr 1.3fr 1.3fr 1fr 0.3fr 0.2fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem;
            }
        }
    }
    .main-table {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        padding: 1rem 0rem;
        height: 60%;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}

@media (max-width: 780px) {
  .table-container-rooms {
    .btn-create-new-rooms-container {
      width: 98%;
    }
    .search-filter-content {
      @include displayFlex(column, center, start);
      width: 98%;
      .input-content {
        margin-left: 0px;
        margin-top: 0.5rem;
      }
      .submit-form-button-content {
        margin-left: 0px;
        margin-top: 1rem;
      }
    }
    .header-table-element {
      width: 98%;
      .header-table-ul {
        .header-table-ul-li {
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
  }
}
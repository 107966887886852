@import './../../../config/style/mixins.scss';
@import './../../../config/style/style.scss';

.student-monetary-aid-table-container {
  @include displayFlex(column, center, center);
  width: 100%;
  height: 100vh;
  .student-monetary-aid-title {
    @include displayFlex(row, flex-start, center);
    width: 90%;
    margin-bottom: 2rem;
    h1 {
      font-style: italic;
      font-weight: 600;
      font-size: 1.7rem;
    }
  }
  .student-monetary-aid-filters {
    position: relative;
    @include displayFlex(row, flex-start, center);
    width: 90%;
    margin-bottom: 2rem;
    .input-content {
      overflow: hidden;
      @include displayFlex(row, flex-start, center);
      position: relative;
      width: 300px;
      box-shadow: 1px 1px 4px $gray-tree;
      border-radius: 10px;
      padding: 0px;
      .search-element {
        margin: 0px;
        width: 30px;
        margin-left: 1rem;
      }
      input {
        width: 300px;
        border: none;
        outline: none;
        padding: 1rem 1rem;
        font-size: 1.5rem;
      }
    }
    .student-monetary-aid-list-option {
      position: absolute;
      width: 300px;
      .student-monetary-aid-list-option {
        position: absolute;
        top: 30px;
        left: 0px;
        background-color: #fff;
        box-shadow: 1px 1px 4px $gray-tree;
        border-radius: 5px;
        list-style: none;
        z-index: 9999;
        li {
          cursor: pointer;
          font-size: 1.5rem;
          padding: 1rem 1rem;
          &:hover {
            background-color: $gray-for;
          }
        }
      }
    }
  }
  .student-monetary-aid-header {
    width: 90%;
    border-top: 2px solid $green;
    border-radius: 5px;
    box-shadow: 1px 1px 4px $gray-tree;
    .student-monetary-aid-table {
      display: grid;
      width: 100%;
      list-style: none;
      grid-template-columns: 0.3fr 1.5fr 1.5fr 1fr 0.3fr 0.5fr 0.7fr;
      .student-monetary-aid-li {
        text-align: start;
        font-size: 1.5rem;
        padding: 1rem 1rem;
      }
    }
  }
  .table-container-student-monetary-aid {
    @include displayFlex(column, start, center);
    overflow-y: auto;
    width: 100%;
    height: 54vh;
    margin-top: 2rem;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
  }
}
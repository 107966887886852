@import "../../../config/style/style.scss";
@import "../../../config/style/mixins.scss";

.dashboard-writing-level-final-result { 
    overflow-y: auto;
    @include displayFlex(column, flex-start, center);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 84vh;
    background-color: #ffff;
    .header-content {
        width: 97%;
        margin-bottom: 1.5rem;
        border-top: 3px solid $green;
        box-shadow: 1px 1px 4px $gray-tree;
        border-radius: 4px;
        .filter-content {
            @include displayFlex(row, flex-start, center);
            padding: .7em;
            h1 {
                font-weight: 400;
                font-size: 2rem;
                padding: 0rem .5rem;
                &.display-false {
                  padding: 0rem;
                  display: none;
                }
            }
            .input-content {
                position: relative;
                width: 150px;
                margin-left: 1rem;
                &.display-false {
                    display: none;
                }
                .input-container-element {
                    cursor: pointer;
                    @include displayFlex(row, flex-start, center);
                    input {
                        cursor: pointer;
                        width: 130px;
                        border: none;
                        font-size: 1.5rem;
                        background-color: transparent;
                    }
                    .icon-arrow {
                        width: 20px;
                        color: $gray-tree;
                        transition: all 0.2s ease-in-out;
                        &.display-true {
                            transform: rotate(180deg);
                        }
                        z-index: 3;
                    }
                }
                .list-option-content {
                    overflow: hidden;
                    position: absolute;
                    width: 100%;
                    height: auto;
                    top: 30px;
                    background-color: #ffffff;
                    border-radius: 5px;
                    box-shadow: 1px 1px 4px $gray-tree;
                    list-style: none;
                    z-index: 2;
                    &.display-false {
                        height: 0px;
                    }
                    li {
                        cursor: pointer;
                        padding: 0.5rem 1rem;
                        font-size: 1.5rem;
                        &:hover {
                            background-color: $gray-for;
                        }
                    }
                }
                z-index: 9999;
            }
        }
        .to-compare-button-content {
            @include displayFlex(row, center, center);
            button {
                cursor: pointer;
                font-size: 1.3rem;
                margin-left: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                background-color: transparent;
                border: none;
                background-color: $green;
                color: $font-color-primary;
            }
        }
        .to-compare-input-container {
            @include displayFlex(row, flex-start, center);
            h1 {
                width: 90px;
                padding-right: 0px;
                font-size: 1.5rem;
            }
            &.display-false {
                display: none;
            }
        }
    }
    &.display-false {
        display: none;
    }
    .graphic-content {
        margin-top: 5rem;
        &:nth-child(2) {
            margin-top: 0rem;
        }
    }
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
    }
    z-index: 2;
}
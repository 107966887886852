@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';
.register-container {
    @include displayFlex(row, space-between, flex-start);
    width: 800px;
    height: 600px;
    border-radius: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.075);
    background-color: #fff;
    .form-register-content {
        @include displayFlex(row, center, center);
        width: 400px;
        height: 100%;
    }
    .img-register-content {
        @include displayFlex(row, flex-start, center);
        width: 50%;
        height: 100%;
        img {
            width: 400px;
        }
    }
}
@media (max-width: 800px) {
  .register-container {
    @include displayFlex(row, center, center);
    width: 100%;
    .img-register-content {
      display: none;
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-add-container-workshop-digital-httpi {
    position: absolute;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100vh;
    &.display-false {
        display: none;
    }
    .form-add-element {
        overflow-y: auto;
        background-color: white;
        width: 500px;
        border-radius: 3px;
        box-shadow: 1px 1px 4px $gray-tree;
        .header-form-add-element-content {
            @include displayFlex(row, space-between, center);
            padding: 1rem 3rem;
            margin-bottom: 2rem;
            color: $font-color-primary;
            background-color: #13975a;
            h1 {
                font-size: 1.7rem;
                padding: 0px;
                color: $font-color-primary;
            }
            svg {
                cursor: pointer;
            }
        }
        .body-form-add-element-content {
            overflow-y: auto;
            height: 150px;
            padding: 3rem;
            padding-bottom: 0px;
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $green;
                margin: 0.2rem;
                border-radius: 20px;
            }
            .input-content {
                padding: 0px;
                @include displayFlex(column, center, flex-start);
                margin-bottom: 3.5rem;
                label {
                    cursor: pointer;
                    margin-bottom: 0rem;
                    font-size: 1.7rem;
                }
                .input-element-content {
                    @include displayFlex(row, center, center);
                    input {
                        border: none;
                        width: 120px;
                        font-size: 1.7rem;
                    }
                    .icon-arrow {
                        color: $gray-tree;
                        transition: all 0.2s ease-in-out;
                        &.display-true {
                            transform: rotate(180deg);
                        }
                    }
                }
                input {
                    width: 100%;
                    padding: 1rem 0.5rem;
                    outline: none;
                    border: none;
                    color: $gray;
                    border-bottom: 1px solid $green;
                    background-color: transparent;
                }
                .input-element {
                    z-index: 1;
                }
                .input-list-ra {
                    width: 100%;
                    @include displayFlex(row, space-between, center);
                    z-index: 2;
                    .input-list-content {
                        @include displayFlex(row, center, center);                            
                        margin: 0px;
                        input {
                            width: 50px;

                        }
                        .input-option-list {
                            width: 80px;
                            top: 50px;
                            z-index: 9999 ;

                        }
                    }
                }
                .error-element {
                    padding: 0.5rem;
                    font-size: 1.2rem;
                    color: red;
                }
            }
            .input-list-content {
                position: relative;
                z-index: 4;
                .error-element {
                    display: none;
                }
                input {
                    cursor: pointer;
                }
                .input-option-list {
                    overflow: auto;
                    position: absolute;
                    top: 60px;
                    width: 150px;
                    max-height: 80px;
                    border-radius: 5px;
                    box-shadow: 1px 1px 4px $gray-tree;
                    list-style: none;
                    z-index: 2;
                    &#display-false {
                        height: 0px;
                    }
                    &::-webkit-scrollbar {
                        width: 5px;
                        padding-right: 0.5rem;
                      }
                    &::-webkit-scrollbar-thumb {
                        background-color: $green;
                        border-radius: 20px;
                    }
                    li {
                        cursor: pointer;
                        padding: 1rem;
                        font-size: 1.5rem;
                        z-index: 2;
                        &:hover {
                            background-color: $gray-for;
                        }
                    }
                }
            }
            .buttons-content {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin: 3rem 0rem;
                button {
                    cursor: pointer;
                    margin-right: 1rem;
                    padding: 0.5rem 1rem;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    &.save-button {
                        background-color: $green;
                        color: $font-color-primary;
                    }
                    &.cancel-button {
                        border: 1px solid $green;
                    }
                }
            }
        }
    }
    z-index: 2;
}
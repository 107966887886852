@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.display-nav-button-content {
  position: fixed;
  left: 10px;
  .button-display-nav-element {
    position: relative;
    width: 30px;
    height: 3px;
    border-radius: 3px;
    background-color: $green;
    border: none;
    transition: all 0.1s ease-in-out;
    &::after {
      position: absolute;
      content: '';
      left: 0px;
      top: 8px;
      width: 30px;
      height: 3px;
      border-radius: 3px;
      transform: rotate(0deg);
      background-color: $green;
      transition: all 0.2s ease-in-out;
    }
    &::before {
      position: absolute;
      content: '';
      left: 0px;
      top: 16px;
      width: 30px;
      height: 3px;
      border-radius: 3px;
      transform: rotate(0deg);
      background-color: $green;
      transition: all 0.2s ease-in-out;
    }
  }
  &.display-true {
    .button-display-nav-element {
      background-color: transparent;
      &::after {
        transform: rotate(45deg);
        left: 310px;
      }
      &::before {
        top: 8px;
        transform: rotate(-45deg);
        left: 310px;
      }
    }
  }
  z-index: 1000;
}

.nav-component-home {
  background-color: $gray;
  width: 250px;
  height: 100vh;
  .action-title {
    @include displayFlex(column, center, center);
    padding: 1.5rem;
    text-align: center;
    background-color: $gray-two;
    color: $font-color-primary;
    h1 {
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.3rem;
    }
  }
  .ul-option {
    overflow-y: auto;
    height: 78vh;
    text-decoration: none;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      margin: 0.2rem;
      border-radius: 20px;
    }
    li {
      @include displayFlex(row, flex-start, center);
      width: auto;
      position: relative;
      padding: 1rem;
      border-collapse: collapse;
      &.option-content-1 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.11);
      }
      h1 {
        width: fit-content;
        margin-left: 1rem;
        font-weight: 500;
        font-size: 1.4rem;
        color: $font-color-primary;
      }
      &.option-content-two-months {
        overflow: hidden;
        padding: 0px;
        &.display-false {
          height: 0px;
        }
        .option-content-card-report {
          @include displayFlex(row, space-between, center);
          background-color: $gray-two;
          font-weight: 500;
          font-size: 1.4rem;
          color: $font-color-primary;
          border-bottom: 1px solid rgba(0, 0, 0, 0.212);
          .icon-arrow {
            transition: all 0.2s ease-in-out;
            &.display-true {
              transform: rotate(180deg);
            }
          }
          &:hover {
            background-color: #1f242b;
          }
        }
        &:hover {
          &::before {
            display: none;
          }
        }
        .options-content-room {
          width: 100%;
          .option-content {
            @include displayFlex(row, space-between, center);
            background-color: $gray-two;
            font-weight: 500;
            font-size: 1.4rem;
            color: $font-color-primary;
            border-bottom: 1px solid rgba(0, 0, 0, 0.267);
            .icon-arrow {
              transition: all 0.2s ease-in-out;
              &.display-true {
                transform: rotate(180deg);
              }
            }
            &:hover {
              background-color: #1f242b;
            }
          }
          .option-matter {
            @include displayFlex(column, flex-start, center);
            overflow: hidden;
            width: 100%;
            padding: 0px;
            &.display-false {
              height: 0px;
            }
            .option-li-writing-level-record {
              width: 100%;
              padding: 0px;
              ul {
                height: 100%;
                width: 100%;
                li {
                  height: 100%;
                  width: 100%;
                  background-color: rgb(34, 41, 48);
                  &:hover {
                    background-color: rgb(28, 34, 39);
                  }
                  h1 {
                    padding: 0rem 2rem !important;
                  }
                }
              }
              &.display-false {
                display: none;
              }
            }
            .option-li {
              @include displayFlex(row, space-between, center);
              position: relative;
              width: 100%;
              text-align: start;
              border-bottom: 1px solid rgba(0, 0, 0, 0.233);
              background-color: #1b1f25;
              h1 {
                padding: 0rem 2rem;
              }
              .icon-arrow {
                transition: all 0.2s ease-in-out;
                margin-right: 1rem;
                &.display-true {
                  transform: rotate(180deg);
                }
              }
              &:hover {
                background-color: $gray;
                &::before {
                  position: absolute;
                  top: 0px;
                  left: 10px;
                  float: left;
                  content: '';
                  width: 3px;
                  height: 100%;
                  background-color: $green;
                  z-index: 9999;
                }
              }
            }
            .matter-list {
              width: 100%;
              @include displayFlex(column, flex-start, center);
              li {
                @include displayFlex(row, space-between, center);
                width: 100%;
                background-color: #1b1f25;
                &:hover {
                  background-color: $gray;
                }
                h1 {
                  padding: 0rem 1rem;
                }
              }
            }
            &:hover {
              cursor: pointer;
              background-color: rgb(32, 38, 44);
              &::before {
                display: none;
              }
            }
          }
        }
        .ul-matter-option-element {
          &.display-false {
            display: none;
          }
          border-bottom: 1px solid #000;
          li {
            background-color: rgb(28, 35, 41);
            &:hover {
              background-color: rgb(32, 38, 44);
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
        background-color: $gray-two;
        &::before {
          position: absolute;
          top: 0px;
          left: 0px;
          float: left;
          content: '';
          width: 3px;
          height: 100%;
          background-color: $green;
        }
      }
    }
  }
}

@media (min-width: 930px) {
  .display-nav-button-content {
    display: none;
  }
}

@media (max-width: 930px) {
  .nav-component-home {
    position: fixed;
    left: 0px;
    z-index: 9999;
    transition: all 0.2s ease-in-out;
    &.display-false {
      left: -100%;
    }
  }
} 
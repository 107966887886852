@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.notification-bell-element {
  overflow: hidden;
  width: 700px;
  height: 500px;
  position: fixed;
  right: 30px;
  top: 70px;
  background-color: #fff;
  box-shadow: 1px 1px 3px $gray-for;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  z-index: 100000;
  &.display-false {
    width: 0px;
    height: 0px;
    .notification-bell-element-header {
      .close-button {
        color: #ffffff00;
        background-color: #ffffff00;
      }
    }
    .notification-bell-element-list {
      .notification-bell-element-list-title {
        h1 {
          color: #ffffff00;
        }
        .open-all-notification {
          background-color: #ffffff00;
          color: #ffffff00;
        }
      }
    }
  }
  .notification-bell-element-header {
    padding: 1rem;
    .close-button {
      cursor: pointer;
      background-color: $green;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 5px;
      color: #fff;
      transition: all 0.3s ease-in-out;
    }
  }
  .notification-bell-element-list {
    @include displayFlex(column, center, center);
    .notification-bell-element-list-title {
      @include displayFlex(row, space-between, center);
      width: 85%;
      margin-top: 2rem;
      margin-bottom: 1rem;
      border-top: 2px solid $green;
      box-shadow: 1px 1px 3px $gray-for;
      border-radius: 5px;
      padding: 1rem;
      h1 {
        font-size: 2rem;
        transition: all 0.3s ease-in-out;
      }
      .open-all-notification {
        cursor: pointer;
        border: none;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-weight: 600;
        background-color: transparent;
        border: 1px solid $green;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: #fff;
          background-color: $green;
        }
      }
    }
    .notification-bell-list {
      width: 90%;
      overflow-y: scroll;
      padding: 1rem;
      height: 350px;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}

@media (max-width: 930px) {
  .notification-bell-element {
    right: 0px;
    width: 100%;
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.dashboard-container-mathematics-diagnostic-assessment-secretary {
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: #ffff;
  z-index: 999;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  &.display-false {
    display: none;
  }
  .dashboard-container-mathematics-diagnostic-assessment-secretary-title {
    @include displayFlex(row, flex-start, center);
    width: 90%;
    padding: 1rem;
    border-radius: 5px;
    border-top: 2px solid $green;
    box-shadow: 1px 1px 3px $gray-for;
    h1 {
      font-size: 1.7rem;
      font-weight: 400;
      padding: 0rem;
    }
    .input-select {
      @include displayFlex(row, flex-start, center);
      margin: 0px;
      width: auto;
      margin: 0rem;
      width: 160px;
      &:nth-child(3) {
        width: 240px;
      }
      &:nth-child(2) {
        margin-left: 1rem;
      }
      .input-content-select {
        margin: 0px;
        margin-left: 3rem;
        width: 100%;
        input {
          width: 100%;
          padding: 0px;
        }
      }
      .input-content-list-options-y {
        top: 50px;
        right: 0px;
        width: 100%;
      }
    }
    .button-content {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 5px;
      background-color: $green;
      color: #fff;
      margin-left: 2rem;
    }
  }
}

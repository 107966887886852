@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.secretary-new-writing-hypothesis-graphs {
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 90vh;
  background-color: #fff;
  z-index: 999;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  .button-content {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: $green;
    color: #fff;
    border: none;
  }
  .secretary-new-writing-hypothesis-graphs-header {
    @include displayFlex(row, space-between, center);
    width: 90%;
    margin-top: 2rem;
    h1 {
      font-style: italic;
    }
  }
  &.display-false {
    display: none;
  }
  .graph-content {
    width: 90%;
  }
  .secretary-new-writing-hypothesis-graphs-generate-graph-button-content {
    width: 90%;
    margin: 3rem 0rem;
  }
  .secretary-graph-content {
    @include displayFlex(row, center, center);
  }
  .new-writing-hypothesi-graph-content-info {
    width: 90%;
    margin-top: 4rem;
    h1 {
      margin: 3rem 0rem;
    }
    .new-writing-hypothesi-graph-content-info-list {
      list-style: none;
      li {
        @include displayFlex(row, flex-start, center);
        margin-top: 1rem;
        span {
          margin-left: 1rem;
          font-size: 2rem;
        }
      }
    }
  }
}
@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.loading-container {
  @include displayFlex(flex, center, center);
  width: 100%;
  height: 100%;
  background-color: #ffff;
  .loading-icon {
    color: $green;
    font-size: 7rem;
    animation: iconRotate 0.5s ease-in-out infinite;
    @keyframes iconRotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.secretary-resource {
    @include displayFlex(column, start, center);
    width: 100%;
    height: 100vh;
    .secretary-resource-title {
        width: 95%;
        margin-top: 3rem;
        h1 {
            font-style: italic;
            font-size: 2rem;
        }
    }
    .secretary-resource-btn {
        width: 95%;
        margin-top: 2.5rem;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            font-size: 1.4rem;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary;
        }
    }
    .secretary-resource-table-content {
        width: 95%;
        .secretary-resource-table-title {
            border-top: 2px solid $green;
            border-radius: 5px;
            margin-top: 1rem;
            box-shadow: 1px 1px 3px $gray-for;
            .secretary-resource-table-ul {
                display: grid;
                grid-template-columns: 0.2fr 2fr 0.5fr 2fr 1fr 0.5fr 0.5fr;
                list-style: none;
                .secretary-resource-table-ul-li {
                    text-align: start;
                    font-size: 1.5rem;
                    padding: 1rem;
                }
            }
        }
        .secretary-resource-table-main {
            @include displayFlex(column, start, center);
            width: 100%;
            overflow-y: auto;
            height: 70vh;
            padding: 1rem 0.5rem;
            &::-webkit-scrollbar {
                width: 12px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $green;
                border-radius: 20px;
            }
        }
    }
}

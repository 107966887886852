@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.mathematics-problem-solving-table {
  position: relative;
  top: 0px;
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  width: 100%;
  height: 90vh;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  .mathematics-problem-solving-table-header {
    @include displayFlex(row, space-between, center);
    width: 90%;
    margin-top: 4rem;
    h1 {
      font-size: 1.8rem;
      font-style: italic;
    }
    .button-container {
      @include displayFlex(row, flex-start, center);
      button {
        cursor: pointer;
        text-wrap: nowrap;
        padding: 0.5rem 1rem;
        margin-right: 1rem;
        border: none;
        color: #fff;
        border-radius: 3px;
        background-color: $green;
      }
    }
  }
  .mathematics-problem-solving-table-content {
    width: 90%;
    margin-top: 5rem;
    border-collapse: collapse;
    border: 2px solid $green;
    thead {
      tr {
        background-color: $green;
        th {
          text-align: center;
          padding: 0.5rem;
          font-size: 1.5rem;
          writing-mode: vertical-rl;
          transform: scale(-1);
          color: #fff;
          &:nth-child(1) {
            transform: scale(1);
            text-align: start;
            writing-mode: horizontal-tb;
            padding-left: 1rem;
          }
        }
      }
    }
    tbody {
      tr {
        &:nth-child(2n) {
          td {
            background-color: rgb(226, 226, 226);
          }
        }
        td {
          
          font-size: 1.5rem;
          &:nth-child(1) {
            padding: 0.5rem;
            display: block;
            text-align: start;
            writing-mode: horizontal-tb;
            padding-left: 1rem;
          }
        }
      }
    }
  }
}

.mathematic-diagnostic-assessment-input-select {
  position: relative;
  width: 100%;
  .mathematic-diagnostic-assessment-input-content {
    @include displayFlex(row, center, center);
    input {
      text-align: center;
      width: 100%;
      border: none;
      outline: none;
      font-size: 1.4rem;
      background-color: transparent;
    }
    .icon-arrow {
      color: black;
      &.display-true {
        transform: rotate(180deg);
      }
    }
  }
  .mathematic-diagnostic-assessment-input-select-options {
    position: absolute;
    top: 30px;
    width: 100%;
    background-color: #fff;
    list-style: none;
    border-radius: 3px;
    box-shadow: 1px 1px 4px $gray-tree;
    z-index: 9999;
    li {
      padding: 0.7rem;
      font-size: 1.3rem;
      &:hover {
        cursor: pointer;
        background-color: $gray-for;
      }
    }
    &.display-false {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .mathematics-problem-solving-table  {
    @include displayFlex(column, flex-start, start);
    padding-left: 1rem;
    .mathematics-problem-solving-table-header {
      h1 {
        font-size: 1.2rem;
      }
    }
    .mathematics-problem-solving-table-content {
      min-width: 1200px;
    }
  }
}
@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.search-element-digital-httpi {
    position: relative;
    @include displayFlex(column, center, flex-start);
    margin: 1rem 0rem;
    .input-content {
        @include displayFlex(row, flex-start, center);
        padding: 0.8rem 1rem; 
        height: 20px;
        border-radius: 10px;
        box-shadow: 1px 1px 3px $gray-for;
        .search-element {
            width: 20px;
            color: $gray-tree;
        }
        input {
            width: 140%;
            margin-left: 1rem;
            border: none;
            outline: none;
            font-size: 1.5rem;
        }
    }
    .options-list {
        position: absolute;
        top: 50px;
        width: 350px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 1px 1px 3px $gray-for;
        z-index: 3;
        .list-element {
            list-style: none;
            li {
                cursor: pointer;
                padding: 1rem;
                font-size: 1.5rem;
                &:hover {
                    background-color: $gray-for;
                }
            }
        }
    }
}

@media (max-width: 800px) {
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.image-element-content {
  @include displayFlex(column, center, center);
  width: 90%;
  margin-top: 2rem;
  .image-element-content-header {
    width: 100%;
    h1 {
      font-size: 1.5rem;
      color: #818181;
    }
    margin-bottom: 2rem;
  }
  .img-content {
    width: 100%;
    @include displayFlex(row, center, center);
    img {
      width: 40%;
    }
    .icon-content {
      display: none;
      cursor: pointer;
      margin-left: 2rem;
      font-size: 0rem;
      color: #818181;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      .icon-content {
        font-size: 3.5rem;
        display: inline;
      }
    }
  }
  
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.add-student-to-monitoring-psychologist {
  overflow: hidden;
  position: absolute;
  top: 90px;
  width: 70%;
  height: 500px;
  box-shadow: 1px 1px 4px $gray-tree;
  background-color: #fff;
  border-radius: 3px;
  z-index: 9999;
  &.display-false {
    display: none;
  }
  .add-student-to-monitoring-psychologist-header {
    overflow: hidden;
    @include displayFlex(row, space-between, center);
    background-color: $green;
    color: #fff;
    padding: 1rem;
    h1 {
      font-size: 1.8rem
    }
    .icon-content {
      cursor: pointer;
    }
  }
  .add-student-to-monitoring-psychologist-section {
    overflow-y: auto;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 87%;
    margin-top: 1rem;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
    .title-container {
      width: 80%;
      font-size: 1.8rem;
      margin-top: 1rem;
      &:nth-child(1) {
        margin-top: 0px;
      }
    }
    .input-content {
      @include displayFlex(column, flex-start, start);
      margin-top: 2rem;
      width: 70%;
      label {
        font-size: 1.8rem;
      }
      input {
        width: 100%;
        margin-top: 2rem;
        border: none;
        border-bottom: 1px solid $green;
        font-size: 1.8rem;
        padding: 1rem;
        outline: none;
      }
      textarea {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 300px;
        font-size: 1.8rem;
        margin-top: 1rem;
        padding: 1rem;
        border: 1px solid $green;
      }
    }
    .button-content {
      @include displayFlex(column, flex-end, end);
      margin-top: 3rem;
      width: 70%;
      button {
        cursor: pointer;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        background-color: $green;
        color: #fff;
        border: none;
        border-radius: 3px;
      }
    }
  }
}

@import "../../../config/style/style.scss";
@import "../../../config/style/mixins.scss";

.table-container-frequency .missed-container-information {
    position: absolute;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
    &.display-false {
        display: none;
    }
    .header-frequency-container {
        width: 90%;
        @include displayFlex(row, space-between, flex-start);
        .header-frequency-element {
            @include displayFlex(row, flex-start, flex-start);
            h1 {
                font-style: italic;
                font-size: 1.4rem;
                color: $gray;
                &:nth-child(1) {
                    padding-left: 0px;
                }
            }
        }
        .button-content {
            @include displayFlex(row, flex-start, flex-start);
            button {
                cursor: pointer;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                background-color: transparent;
                border: none;
                background-color: $green;
                color: $font-color-primary;
            }
        }
    }
    .header-table-element-frequency {
        width: 90% !important;
        margin-top: 1rem;
        border-top: 2px solid $green;
        border-radius: 5px;
        box-shadow: 1px 1px 3px $gray-for;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.2fr 2fr 0.5fr 0.5fr 0.5fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem;
                &:nth-child(3) {
                    text-align: center;
                }
                &:nth-child(4) {
                    text-align: center;
                }
                &:nth-child(5) {
                  text-align: center;
                }
            }
        }
    }
    .body-tabele-element-frequency {
        overflow-y: auto;
        width: 90%;
        height: 62%;
        margin-top: 1rem;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}

@media (max-width: 600px) {
  .table-container-frequency .missed-container-information {
   .header-frequency-container {
     display: block;
    .header-table-element-frequency {
    }
   }
  }
}
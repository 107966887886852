@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-edit-container {
    overflow: auto;
    position: absolute;
    @include displayFlex(column, center, center);
    width: 100%;
    height: 85vh;
    padding: 0rem;
    background-color: white;
    z-index: 3;
    &.display-false {
        display: none;
    }
    .form-edit-element {
        width: 75%;
        height: 100%;
        padding-bottom: 2rem;
        .header-form-edit-element  {
            margin-top: 0rem;
            h1 {
                position: relative;
                top: 25px;
                font-size: 2.5rem;
            }
            z-index: 3;
        }
        .body-form-edit-element {
            width: 100%;
            margin-top: 2rem;
            padding: 0em 7rem;
            .input-content {
                width: 100%;
                padding-top: 2rem;
                padding-bottom: 1rem;
                margin-bottom: 0rem;
                @include displayFlex(column, center, flex-start);
                label {
                    cursor: pointer;
                    margin-bottom: 0rem;
                    font-size: 1.8rem;
                }
                .input-element-content {
                    @include displayFlex(row, center, center);
                    input {
                        width: 120px;
                    }
                    .icon-arrow {
                        color: $gray-tree;
                        transition: all 0.2s ease-in-out;
                        &.display-true {
                            transform: rotate(180deg);
                        }
                    }
                }
                input {
                    width: 100%;
                    font-size: 1.7rem;
                    padding: 1rem 0.5rem;
                    outline: none;
                    border: none;
                    color: gray;
                    background-color: transparent;
                    &:focus {
                        border-bottom: 1px solid $green;
                    }
                }
                &.input-list-content {
                    position: relative;
                    .error-element {
                        display: none;
                    }
                    input {
                        cursor: pointer;
                    }
                    .input-option-list {
                        overflow: auto;
                        position: absolute;
                        top: 80px;
                        width: 150px;
                        max-height: 80px;
                        border-radius: 5px;
                        box-shadow: 1px 1px 4px $gray-tree;
                        list-style: none;
                        z-index: 4;
                        &::-webkit-scrollbar {
                            width: 5px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background-color: $green;
                            border-radius: 20px;
                        }
                        &#display-false {
                            height: 0px;
                        }
                        li {
                            cursor: pointer;
                            padding: 1rem;
                            font-size: 1.5rem;
                            &:hover {
                                background-color: $gray-for;
                            }
                        }
                    }
                }
                .error-element {
                    padding: 0.5rem;
                    font-size: 1.2rem;
                    color: red;
                }
            }
            .buttons-content {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                padding: 2rem 0rem;
                margin: 3rem 0rem;
                button {
                    cursor: pointer;
                    margin-right: 1rem;
                    padding: 0.5rem 1rem;
                    font-size: 1.5rem;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    &.save-button {
                        background-color: $green;
                        color: $font-color-primary;
                    }
                    &.cancel-button {
                        border: 1px solid $green;
                    }
                }
            }
        }
    }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.content-table-municipal-education-plan-monitoring-sheet-indicator {
  margin-top: 2rem;

  .button-content {
    @include displayFlex(row, flex-end, end);
    width: 100%;
    .button-element {
      cursor: pointer;
      border: none;
      padding: 0.5rem 1rem;
      color: #fff;
      &.save-button {
        background-color: $green;
      }
      &.delete-button {
        background-color: crimson;
      }
    }
  }
  .content-table-municipal-education-plan-monitoring-sheet-indicator-table {
    border-collapse: collapse;
    td, th {
      border: 1px solid black;
      font-size: 1.3rem;
    }

    th {
      .input-select {
        margin: 0px;
        .input-content-select {
          width: 100%;
          margin: 0px;
          background-color: transparent;
          input {
            width: 100%;
            font-size: 1.4rem;
          }
        }
        .input-content-list-options-y {
          width: 100%;
          li {
            font-size: 1.2rem;
          }
          &.display-true {
            top: 45px;
          }
        }
      }
    }

    .td-large {
      padding: 1rem 0.5rem;
      min-width: 50px;
    }

    input {
      width: 100%;
      text-align: center;
      border: none;
      background-color: transparent;
      outline: none;
    }

    .td-min-height {
      height: 30px;
    }

    .td-background-gray {
      background-color: rgb(214, 214, 214);
    }
    .content-table-municipal-education-plan-monitoring-sheet-indicator-table-header {
    }
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.section-student-list-table {
  width: 100%;
  .content-table-student-psychologist {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 0.5fr 0.5fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    min-height: 50px;
    list-style: none;
    box-shadow: 1px 1px 4px $gray-tree;
    border-radius: 5px;
    &:hover {
      background-color: rgba(241, 241, 241, 0.514);
    }
    li {
      padding: 1rem;
      font-size: 1.5rem;
    }
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-describe-upload-room-observer-sheet {
  overflow-y: auto;
  position: absolute;
  top: -40px;
  width: 100%;
  height: 90vh;
  border-radius: 5px;
  background-color: #fff;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
  }
  &.display-false {
      display: none;
  }
  .header-form-describe-element-content {
      @include displayFlex(row, space-between, center);
      padding: 2rem 5rem;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #fff;
      h1 {
          font-size: 1.7rem;
          font-style: italic;
          padding: 0px;
          color: #000;
      }
      button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        color: #fff;
        background-color: $green;
      }
      svg {
          cursor: pointer;
      }
  }
  .main-monthly-plan-display-information {
      padding: 1rem 6rem;
      min-height: 200px;
      max-height: 300px;
      &::-webkit-scrollbar {
          width: 10px;
      }
      &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
      }
      .decent-work-information {
          h1 {
              font-size: 1.4rem;
              font-style: italic;
              font-weight: bold;
              margin-top: 1rem;
              padding: 0rem 1rem;
              span {
                  color: rgb(88, 88, 88);
              }
          }
      }
      .description-content {
          width: 100%;
          h1 {
              width: 100%;
              text-align: center;
              margin-top: 1rem;
          }
          p {
              font-size: 1.6rem;
              padding: 2rem;
          }
      }
      .button-content {
          @include displayFlex(row, flex-end, center);
          width: 100%;
          margin-top: 2rem;
          button {
              cursor: pointer;
              margin-right: 1rem;
              padding: 0.5rem 1rem;
              border-radius: 5px;
              background-color: transparent;
              border: none;
              &.cancel-button {
                  background-color: $green;
                  color: $font-color-primary;
              }
          }
      }
  }
  .adm-hours-information-frame-content {
    margin-top: 3rem;
  }
  z-index: 999;
}
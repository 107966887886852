@import '../../config/style/mixins.scss';

.main-container-login {
    width: 100vw;
    height: 100vh;
    .form-login-container {
        @include displayFlex(column, center, center);
        width: 100vw;
        height: 90vh;
        background-color: whitesmoke;
    }

}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.display-information-justification-of-absences-content {
  position: absolute;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100vh;
  &.display-false {
    display: none;
  }
  .display-information-justification-of-absences {
    background-color: white;
    width: 600px;
    border-radius: 3px;
    box-shadow: 1px 1px 4px $gray-tree;
    .header-form-add-element-content {
      @include displayFlex(row, space-between, center);
      padding: 1rem 3rem;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #13975a;
      h1 {
        font-size: 1.7rem;
        padding: 0rem 0rem;
        color: $font-color-primary;
      }
      svg {
        cursor: pointer;
      }
    }
    .information-content {
      overflow-y: auto;
      padding: 4rem 2rem;
      padding-top: 0rem;
      max-height: 300px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
      .title-content {
        font-size: 1.9rem;
        span {
          font-size: 1.7rem;
          color: rgb(75, 75, 75);
        }
      }
      .information-element {
        h1 {
          font-size: 1.6rem;
          padding: 0.5rem 2rem;
          span {
            font-size: 1.5rem;
            color: rgb(75, 75, 75);
          }
        }
      }
    }
  z-index: 2;
  }
}


@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-reading-fluency {
  position: relative;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  .title-table {
    text-align: start;
    font-style: italic;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    width: 90%;
  }
  .btn-create-new-teacher {
    @include displayFlex(row, flex-start, center);
    width: 90%;
    button {
      cursor: pointer;
      margin-bottom: 2rem;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: transparent;
      border: none;
      background-color: $green;
      color: $font-color-primary;
    }
  }
  .search-filter-content {
    position: relative;
    @include displayFlex(row, flex-start, center);
    width: 90%;
    h2 {
      margin: 0rem 2rem;
    }
    .input-content {
      position: relative;
      @include displayFlex(row, center, center);
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 1px 1px 3px $gray-for;
      margin-left: 2rem;
      padding: 0.7rem;
      &:nth-child(1) {
        margin-left: 0px;
      }
      &:nth-child(4) {
        margin-left: 0px;
      }
      input {
        width: 200px;
        outline: none;
        border: none;
        font-size: 1.5rem;
      }
    }
    .search-input-content {
      @include displayFlex(row, center, center);
    }
    .search-date-input-content {
      margin-left: 3rem;
      label {
        font-size: 1.5rem;
      }
      input {
        width: 60px;
        margin-left: 1rem;
      }
    }
    .submit-form-button-content {
      margin-left: 3rem;
      button {
        cursor: pointer;
        padding: 0.7rem 1.2rem;
        border-radius: 5px;
        background-color: transparent;
        border: none;
        background-color: $green;
        color: $font-color-primary;
      }
    }
  }
  .header-table-element {
    border-bottom: 2px solid $gray-tree;
    width: 90%;
    .header-table-ul {
      display: grid;
      grid-template-columns: 0.1fr 1fr 1fr 1fr 1fr 0.2fr 0.2fr;
      list-style: none;
      .header-table-ul-li {
        text-align: start;
        font-size: 1.5rem;
        padding: 1rem;
      }
    }
  }
  .main-table {
    overflow-y: auto;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    padding: 1rem 0rem;
    height: 59vh;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
  }
}
  
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.textual-genre-table-content {
  cursor: pointer;
  width: 100%;
  box-shadow: 1px 1px 3px $gray-for;
  padding: 0.5rem 0rem;
  border-radius: 10px;
  margin-top: 1rem;
  .textual-genre-table-content-ul {
    display: grid;
    grid-template-columns: 0.3fr 2fr 1fr 0.3fr 0.3fr;
    list-style: none;
    li {
      font-size: 1.4rem;
      padding: 1rem;
    }
  }
  &:hover {
    background-color: #f8f8f8;
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.new-writing-the-name-content-table {
  width: 100%;
  box-shadow: 1px 1px 3px $gray-for;
  margin-top: 1rem;
  border-radius: 10px;
  ul {
    display: grid;
    grid-template-columns: 0.2fr 2fr 1fr;
    width: 100%;
    list-style: none;
    li {
      @include displayFlex(row, start, center);
      font-size: 1.5rem;
      padding: 1rem;
      &.writing-the-name-btn-content {
        @include displayFlex(row, center, center);
      }
      .writing-the-name-btn {
        @include displayFlex(row, center, center);
        width: 40px;
        height: 40px;
        border: 3px solid $green;
        border-radius: 100%;
        background-color: #fff;
        .icon {
          width: 20px;
          height: 20px;
          padding: 0.4rem;
          font-size: 1rem;
          border-radius: 100%;
          background-color: $green;
          color: #fff;
          opacity: 1;
          transition: all 0.1s ease-in-out;
          &.display-false {
            padding: 0px;
            opacity: 0;
          }
        }
      }
    }
  }
}

@import '../../../config/style/mixins.scss';

.municipal-education-plan-monitoring-sheet-dashboard-indicator {
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100vh;
  .municipal-education-plan-monitoring-sheet-dashboard-indicator-header {
    width: 90%;
    margin-top: 3rem;
    h1 {
      font-size: 1.9rem;
      font-style: italic;
    }
  }
  .goal-dashboard-filter {
    @include displayFlex(row, center, center);
    width: 90%;
  }
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.mathematics-problem-solving-general-table {
  position: relative;
  top: 0px;
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  width: 100%;
  height: 90vh;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  .mathematics-problem-solving-general-table-header {
    @include displayFlex(row, flex-start, center);
    width: 90%;
    margin-top: 2rem;
    padding: 1rem;
    border-radius: 5px;
    border-top: 2px solid $green;
    box-shadow: 1px 1px 3px $gray-for;
    h1 {
      font-size: 1.7rem;
      font-weight: 400;
    }
    .input-select {
      @include displayFlex(row, flex-start, center);
      margin: 0px;
      width: auto;
      margin: 0rem;
      width: 160px;
      &:nth-child(3) {
        width: 240px;
      }
      &:nth-child(2) {
        margin-left: 1rem;
      }
      .input-content-select {
        margin: 0px;
        margin-left: 3rem;
        width: 100%;
        input {
          width: 100%;
          padding: 0px;
        }
      }
      .input-content-list-options-y {
        top: 50px;
        right: 0px;
        width: 100%;
      }
    }
    .button-content {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 5px;
      background-color: $green;
      color: #fff;
      margin-left: 2rem;
    }
  }
  .problem-solving-dashboard-content {
    @include displayFlex(column, center, center);
    width: 60%;
    margin-top: 3rem;
    img {
      width: 80%;
    }
    .problem-solving-dashboard-info {
      width: 100%;
      padding-bottom: 3rem;
      ul {
        list-style: none;
        li {
          @include displayFlex(row, flex-start, center);
          margin-top: 2rem;
          h1 {
            font-size: 1.5rem;
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

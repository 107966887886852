@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.dashboard-element {
    @include displayFlex(column, center, center);
    width: 100%;
    height: 87vh;
    padding-bottom: 1rem;
    z-index: 3;
    .dashboard-content {
        @include displayFlex(column, center, center);
        width: 100%;
        height: 100%;
    }
}
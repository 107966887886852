;@import '../../../../config/style/mixins.scss';
@import '../../../../config/style/style.scss';


.option-element {
  .goal-li-element {
    @include displayFlex(row, space-between, center);
    width: 100%;
    .icon-arrow {
      margin-right: 1rem;
      transition: transform 0.2s ease-in-out;
      &.display-true {
        transform: rotate(180deg);
      }
    }
  }
}

.goal-options-element {
  &.display-false {
    display: none;
  }
  li {
    border-bottom: 1px solid #1b2027;
    &.delete-content {
      h1 {
        color: crimson;
      }
    }
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-classroom-monitoring {
  @include displayFlex(column, center, center);
  position: relative;
  width: 100%;
  top: 0px;
  .header-container-classroom-monitoring {
    margin-top: 1rem;
  }
  .table-classroom-monitoring {
    overflow-y: auto;
    width: 90%;
    height: 75vh;
    border-collapse: collapse;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      margin: 0.2rem;
      border-radius: 20px;
    }
    .table-row {
      border: 1px solid rgba(109, 109, 109, 0.233);
      &.row {
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        .title-of-row {
          @include displayFlex(column, center, start);
          height: 100%;
        }
        .main-table-row {
          border-left: 1px solid rgba(109, 109, 109, 0.233);
          .table-row-element {
            border-bottom: 1px solid rgba(109, 109, 109, 0.233);
            &.green-row {
              background-color: rgba(18, 187, 32, 0.719);
            }
            &:nth-last-child(1) {
              border-bottom: none;
            }
          }
        }
      }

      .main-table-row {
        width: 100%;
        .table-row-element {
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          list-style: none;
        }
      }
      &.title-table {
        background-color: rgba(18, 187, 32, 0.719);
        border: none;
        .main-table-row {
          .table-row-element {
            grid-template-columns: 0.5fr 1fr;
          }
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .table-container-classroom-monitoring {
    .header-container-classroom-monitoring {
      h1 {
        margin-top: 2rem;
        font-size: 1.5rem;
      }
    }
    .table-classroom-monitoring {
      width: 99%;
      &::-webkit-scrollbar {
        width: 5px;
      }
      .table-row {
        &.row {
          .title-of-row {
            font-size: 0.8rem;
          }
          .main-table-row {
            .table-row-element {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.municipal-education-plan-monitoring-sheet-goal-table-content {
  @include displayFlex(row, center, start);
  width: 100%;
  .municipal-education-plan-monitoring-sheet-goal-main {
    overflow-y: auto;
    width: 95%;
    height: 82vh;
    padding: 2rem;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
    .municipal-education-plan-monitoring-sheet-goal-table-header {
      margin-top: 2rem;
      h1 {
        font-style: italic;
        font-size: 1.7rem;
      }
    }
    .municipal-education-plan-monitoring-sheet-goal-table {
      height: auto;
      margin-top: 5rem;
      border-collapse: collapse;
      table-layout: fixed;
      td {
        border: 1px solid black;
        padding: 0rem 1rem;
        font-size: 1.5rem;
      }
      .table-td-background-gray {
        background-color: $gray-for;
      }
      .table-td-spaced {
        padding: 2rem 1rem;
        width: 350px;
      }
      .municipal-education-plan-monitoring-sheet-goal-table-header {
        td {
        }
      }
    }
  }
}
@import './../../../config/style/style.scss';
@import './../../../config/style/mixins.scss';

.exam-text-content {
  @include displayFlex(row, center, center);
  margin-top: 2rem;
  width: 100%;
  textarea {
    overflow: hidden;
    resize: none;
    width: 75%;
    font-size: 2rem;
    font-weight: 300;
    background-color: transparent;
    border: none;
    color: black;
  }
}

@media (max-width: 560px) {
  .exam-text-content {
    h1 {
      font-size: 1.5rem
    }
  }
}
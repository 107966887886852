@import "../../../config/style/style.scss";
@import "../../../config/style/mixins.scss";

.table-container-writing-level-record-coordinator {
    position: relative;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    z-index: 3;
    .title-writing-level-record-coordinator {
      width: 90%;
      h1 {
        font-style: italic;
        font-size: 1.8rem;
        padding: 0px;
        margin-bottom: 1rem;
      }
    }
    .header-content {
        @include displayFlex(row, space-between, center);
        width: 90%;
        .search-filter-content {
            position: relative;
            @include displayFlex(row, flex-start, center);
            .input-content {
                position: relative;
                @include displayFlex(row, center, center);
                overflow: hidden;
                border-radius: 10px;
                box-shadow: 1px 1px 3px $gray-for;
                padding: 0.7rem;
                input {
                    width: 200px;
                    outline: none;
                    border: none;
                    font-size: 1.5rem;
                }
            }
            .search-input-content {
                @include displayFlex(row, center, center);
            }
            .search-date-input-content {
                margin-left: 3rem;
                label {
                    font-size: 1.5rem;
                }
                input {
                    width: 60px;
                    margin-left: 1rem;
                }
            }
            .submit-form-button-content {
                margin-left: 3rem;
                button {
                    cursor: pointer;
                    padding: 0.7rem 1.2rem;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    background-color: $green;
                    color: $font-color-primary;
                }
            }
        }
        .generate-graph-button {
            button {
                cursor: pointer;
                padding: 0.7rem 1.2rem;
                border-radius: 5px;
                background-color: transparent;
                border: none;
                background-color: $green;
                color: $font-color-primary;
            }
        }
    }
    .main-content {
        width: 90%;
        height: 100vh;
        margin-top: 4rem;
        .header-table-element {
            width: 100%;
            margin-top: 1rem;
            border-top: 2px solid $green;
            border-radius: 5px;
            box-shadow: 1px 1px 3px $gray-for;
            .header-table-ul {
                overflow-y: auto;
                display: grid;
                grid-template-columns: 0.2fr 0.5fr 1fr 1fr 1fr;
                width: 100%;
                list-style: none;
                .header-table-ul-li {
                    text-align: start;
                    font-size: 1.5rem;
                    padding: 1rem;
                }
            }
        }
        .main-table-content {
            @include displayFlex(column, flex-start, center);
            overflow-y: auto;
            height: 60%;
            padding: 0.5rem;
            &::-webkit-scrollbar {
                width: 12px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $green;
                border-radius: 20px;
            }
        }
    }
}

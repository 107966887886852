@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.municipal-education-plan-monitoring-sheet-strategies-table-content {
  @include displayFlex(column, center, center);
  margin-top: 2rem;
  .municipal-education-plan-monitoring-sheet-strategy-header {
    width: 90%;
    @include displayFlex(row, space-between, center);
    h1 {
      font-style: italic;
    }
    .button-content {
      button {
        cursor: pointer;
        border-radius: 5px;
        color: #fff;
        padding: 0.5rem 1rem;
        border: none;
        font-size: 1.4rem;
        background-color: $green;
  
      }
    }
  }
  .municipal-education-plan-monitoring-sheet-strategy-table {
    overflow-y: auto;
    width: 90%;
    height: 80vh;
    margin-top: 1rem;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
  }
}
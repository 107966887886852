@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-student {
    position: relative;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    z-index: 3;
    .btn-create-new-student-container {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 0rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .header-table-element {
        border-top: 2px solid $green;
        width: 90%;
        border-radius: 5px;
        box-shadow: 1px 1px 3px $gray-for;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.3fr 1.5fr 1.5fr 1fr 0.3fr 0.5fr 0.7fr 0.3fr 0.2fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem 1rem;
            }
        }
    }
    .student-filter-content {
      @include displayFlex(row, space-between, center);
      width: 90%;
      .input-select {
        @include displayFlex(row, center, center);
        margin: 0px;
        .input-content-title {
          margin-right: 1rem;
        }
        .input-content-select {
          @include displayFlex(row, center, center);
          padding: 0px;
          margin: 0px;
          input {
            padding: 0px;
          }
        }
        .input-content-list-options-y {
          top: 40px;
          right: 0px;
        }
      }
    }
    .main-table {
      @include displayFlex(column, flex-start, center);
        overflow-y: auto;
        height: 55vh;
        width: 100%;
        padding: 1rem 0rem;
        margin-top: 0.5rem;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}

@media (max-width: 930px) {
  .table-container-student {
    .btn-create-new-student-container {
      width: 95%;
    }
    .header-table-element {
      width: 95%;
    }
  }
}
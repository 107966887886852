@import './../../../config/style/style.scss';
@import './../../../config/style/mixins.scss';
.exam-img-content {
  @include displayFlex(row, center, center);
  width: 100%;
  margin: 7rem 0rem;
  img {
    width: 40%;
  }
}

@media (max-width: 560px) {
  .exam-img-content {
    img {
      width: 100%;
    }
  }
}
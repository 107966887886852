@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.municipal-education-plan-monitoring-sheet-goal-text {
  @include displayFlex(row, center, start);
  width: 100%;
  .municipal-education-plan-monitoring-sheet-goal-text-main {
    @include displayFlex(column, center, center);
    width: 95%;
    .municipal-education-plan-monitoring-sheet-goal-text-header {
      @include displayFlex(row, space-between, start);
      width: 100%;
      margin-top: 2rem;
      h1 {
        font-size: 1.7rem;
        font-style: italic;
      }
      .save-button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        border: none;
        background-color: $green;
        border-radius: 3px;
        color: #fff;
      }
    }
    .municipal-education-plan-monitoring-sheet-goal-text-table {
      border-collapse: collapse;
      margin-top: 5rem;
      td, th {
        padding: 1rem;
        font-size: 1.5rem;
        &.background-gray {
          background-color: $gray-for;
        }
        &.column-small {
          width: 250px;
        }
        &.table-td-textarea {
          padding: 0.2rem;
          textarea {
            text-align: center;
            text-align-last: center;
            min-width: 100%;
            min-height: 100%;
            max-height: 100px;
            height: 100%;
            outline: none;
            border: none;
          }
        }
      }
      td {
        height: 100px;
      }
      * {
        border: 1px solid black;
      }
      .municipal-education-plan-monitoring-sheet-goal-text-table-header {

      }
      .municipal-education-plan-monitoring-sheet-goal-text-table-body {

      }
    }
  }
}
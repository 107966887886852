@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-action {
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 85vh;
    margin-top: 3rem;
    .btn-create-new-action-container {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .filter-content-action {
        @include displayFlex(row, flex-start, center);
        width: 93%;
        margin-bottom: 3rem;
        h1 {
            font-weight: 500;
            font-size: 1.6rem;
            color: $green;
        }
        .filter {
            @include displayFlex(row, flex-start, center);
            list-style: none;
            gap: 1rem;
        }
    }
    .header-table-element-action {
      width: 90%;
        position: relative;
        border-top: 2px solid $green;
        border-radius: 5px;
        box-shadow: 1px 1px 3px $gray-for;
        z-index: 1;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.3fr 4fr 1fr 1fr 1.5fr 1fr 0.5fr 0.3fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem 0rem;
            }
        }
    }
    .main-table-action {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        height: 100%;
        padding-bottom: 1rem;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.input-main {
    position: relative;
    min-width: 120px;
    .input-content {
        position: relative;
        min-width: 60px;
        padding: 0.8rem 1rem;
        border-radius: 5px;
        box-shadow: 1px 1px 4px $gray-tree;
        z-index: 2;
        label {
            cursor: pointer;
            @include displayFlex(row, space-between, center);
            width: 100%;
            font-size: 1.6rem;
            color: $green;
            svg {
                margin-left: 0.5rem;
                transition: transform 0.2s ease-in-out;
                transform: rotate(0.5turn);  
            }
            &.display-false {
                svg {  
                  transform: rotate(0turn);  
                }
            }
        }
        input {
            display: none;
        }
        background-color: white;
    }
    .situation-options-content.display-false {
        overflow: hidden;
        position: absolute;
        height: 0px;
        padding: 0;
    }
    .situation-options-content {
        position: absolute;
        width: 100%;
        height: fit-content;
        margin-top: 1rem;
        border-radius: 5px;
        background-color: white;
        box-shadow: 1px 1px 4px $gray-tree;
        transition: all 0.2s ease-in-out;
        z-index: 2;
        li {
            position: relative;
            cursor: pointer;
            width: 90%;
            padding: 0.5rem 0.5rem;
            font-size: 1.5rem;
        }
    }
    ul {
        list-style: none;
        li {
            width: 100%;
            &:hover {
                background-color: rgba(128, 128, 128, 0.062);
            }
        }
    }
}
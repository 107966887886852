@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.new-writing-hypothesis-element {
  width: 100%;
  box-shadow: 1px 1px 3px $gray-for;
  margin-top: 1rem;
  border-radius: 10px;
  .new-writing-hypothesis-element-ul {
    display: grid;
    grid-template-columns: 0.3fr 3fr 1fr;
    list-style: none;
    li {
      @include displayFlex(row, start, center);
      font-size: 1.5rem;
      padding: 1rem;
      .input-select-new-writing-hypothesis {
        position: relative;
        .input-select-new-writing-hypothesis-input-content {
          @include displayFlex(row, center, center);
          input {
            border: none;
            font-size: 1.5rem;
            background-color: #0000;
          }
          .icon-arrow {
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            color: $green;
            &.display-false {
              transform: rotate(180deg);
            }
          }
        }
        .input-select-new-writing-hypothesis-options {
          overflow-y: scroll;
          top: 40px;
          width: 100%;
          height: 150px;
          position: absolute;
          background-color: #fff;
          box-shadow: 1px 1px 3px $gray-for;
          border-radius: 5px;
          border-top: 2px solid $green;
          border-bottom: 2px solid $green;
          transition: all 0.2s ease-in-out;
          z-index: 9999;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
          }
          li {
            cursor: pointer;
            padding: 1rem 0.5rem;
            &:hover {
              background-color: $gray-for;
            }
          }
          &.display-false {
            overflow: hidden;
            box-shadow: 0px 0px 0px transparent;
            border-radius: 0px;
            border-top: 0px solid transparent;
            border-bottom: 0px solid transparent;
            height: 0px;
          }
        }
      }
    }
  }
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.notification-form-edit {
  position: fixed;
  width: 600px;
  height: 500px;
  box-shadow: 1px 1px 3px $gray-for;
  border-radius: 4px;
  background-color: #fff;
  z-index: 999999;
  &.display-false {
    display: none;
  }
  .header-notification-form-edit {
    @include displayFlex(row, space-between, center);
    background-color: $green;
    h1 {
      font-size: 1.7rem;
      padding: 1rem;
      color: #fff;
    }
    .close-button-content {
      background-color: #ffffff00;
      button {
        cursor: pointer;
        background-color: #ffffff00;
        border: none;
        padding: 1rem;
        margin-right: 1rem;
        color: #fff;
      }
    }
  }
  .notification-form-add-input-content {
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    margin-top: 2rem;
    .form-input-content {
      @include displayFlex(column, start, start);
      width: 90%;
      margin-bottom: 2rem;
      label {
        font-size: 1.7rem;
      }
      textarea {
        min-height: 200px;
        max-height: 200px;
        min-width: 96%;
        max-width: 96%;
        margin-top: 1rem;
        outline: none;
        padding: 1rem;
        border: 1px solid $green;
        border-radius: 5px;
        font-size: 1.5rem;
      }
      input {
        border: none;
        outline: none;
        border-bottom: 1px solid $green;
        padding: 1rem;
        font-size: 1.5rem;
        width: 50%;
      }
    }
    .notification-form-add-submit-button {
      @include displayFlex(row, flex-end, center);
      width: 90%;
      .button-content {
        cursor: pointer;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 3px;
        outline: none;
      }
      .cancel-button {
        color: #000;
        border: 1px solid $green;
        background-color: #ffffff00;
      }
      .save-button {
        color: #fff;
        margin-left: 2rem;
        background-color: $green;
      }
    }
  }
}
@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.save-alert-component {
    overflow: hidden;
    position: fixed;
    top: 50px;
    right: -220px;
    z-index: 9999;
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    h1 {
        @include displayFlex(row, space-between, center);
        width: 200px;
        padding: 1rem 1rem !important;
        font-size: 1.5rem;
        color: white !important;
        background-color: #23c551;
        .icon-element {
            cursor: pointer;
            color: white;
        }
    }
    &.display-true {
        right: 10px;
    }
}
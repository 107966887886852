@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';


.add-day-of-HTTPI {
  position: fixed;
  top: 100px;
  @include displayFlex(row, center, start);
  width: 100%;
  height: 90vh;
  &.display-false {
    display: none;
  }
  z-index: 9999;
  .form-day-of-HTTPI {
    overflow: hidden;
    width: 500px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 4px $gray-tree;
    z-index: 9999;
    .header-form-add-element-content {
      @include displayFlex(row, space-between, center);
      padding: 1rem 3rem;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #13975a;
      h1 {
        font-size: 1.7rem;
        padding: 0rem 0rem;
        color: $font-color-primary;
      }
      svg {
        cursor: pointer;
      }
    }
    .main-form {
      @include displayFlex(column, start, center);
      .input-content {
        @include displayFlex(column, start, start);
        width: 90%;
        label {
          font-size: 1.6rem;
          margin-top: 2rem;
        }
        input {
          border: none;
          outline: none;
          margin-top: 1rem;
          border-bottom: 1px solid #13975a;
          width: 90%;
          font-size: 1.6rem;
          padding: 0.5rem 1rem;
        }
        .date {
          width: 150px;
        }
        .time {
          width: 100px;
        }
      }
    }
    .button-content {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 3rem 0rem;
      button {
        cursor: pointer;
        margin-right: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        background-color: transparent;
        border: none;
        &.save-button {
          background-color: $green;
          color: $font-color-primary;
        }
        &.cancel-button {
          border: 1px solid $green;
        }
      }
    }
  }
}
@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.new-writing-the-name-table {
  @include displayFlex(column, center, center);
  width: 100%;
  .new-writing-the-name-table-header {
    @include displayFlex(row, space-between, center);
    width: 90%;
    h1 {
      font-size: 2rem;
      font-style: italic;
      padding: 2rem 0rem;
    }
    .writing-the-name-count {
      @include displayFlex(row, center, center);
      padding: 0.5rem 1rem;
      font-size: 2rem;
      background-color: $green;
      border-radius: 5px;
      color: #fff;
      span {
        margin-left: 1rem;
      }
    }
  }
  .new-writing-the-name-table-middle-buttons {
    width: 90%;
    margin: 2rem 0rem;
    .btn-element {
      cursor: pointer;
      padding: 0.5rem 1rem;
      background-color: $green;
      border: none;
      border-radius: 5px;
      color: #fff;
    }
  }
  .table-new-writing-the-name-content {
    width: 90%;
    .table-new-writing-the-name-content-header {
      width: 100%;
      border-radius: 5px;
      border-top: 2px solid $green;
      box-shadow: 1px 1px 3px $gray-for;
      ul {
        display: grid;
        grid-template-columns: 0.2fr 2fr 1fr;
        width: 100%;
        list-style: none;
        li {
          font-size: 1.5rem;
          padding: 1rem;
          &.writing-the-name-btn-content {
            @include displayFlex(row, center, center);
          }
        }
      }
    }
    .table-new-writing-the-name-content {
      @include displayFlex(column, start, center);
      overflow-y: auto;
      width: 100%;
      height: 65vh;
      padding: 1rem 0rem;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}
@import './../../../config/style/style.scss';
@import './../../../config/style/mixins.scss';

.exam-title-content {
  margin-top: 2rem;
  h1 {
    font-size: 2rem;
    font-weight: 500;
  }
}
@media (max-width: 560px) {
  .exam-title-content {
    h1 {
      font-size: 1.7rem;
    }
  }
}
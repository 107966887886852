@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.container-back-to-top-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: all 0.2s ease-in-out;
  &.display-false {
    right: -100px;
  }
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    .arrow-icon-back-to-top {
      font-size: 4rem;
      color: $green;
    }
  }
}
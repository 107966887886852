@import '../../../config/style/mixins.scss';

.bell-notification-container {
  position: fixed;
  top: 70px;
  right: 10px;
  z-index: 999;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  &.display-false {
    opacity: 0;
  }
  .bell-content {
    cursor: pointer;
    .notification-count-element {
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 99999;
      border-radius: 100%;
      background-color: #ff0000;
      border: 2px solid #fff;
      h1 {
        @include displayFlex(row, center, center);        
        width: 12px;
        height: 12px;
        padding: 0.3rem;
        font-size: 1rem;
        color: #fff;
      }
    }
    .bell-icon-element {
      transition: all 0.3s ease-in-out;
      color: #000000;
    }
  }
}

@keyframes bellAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-40deg);
  }
  50% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
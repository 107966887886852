@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-edit-container-student-observer {
  position: absolute;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100vh;
  &.display-false {
    display: none;
  }
  .form-add-element-room {
    background-color: white;
    border-radius: 3px;
    width: 600px;
    box-shadow: 1px 1px 4px $gray-tree;
    .header-form-add-element-content {
      @include displayFlex(row, space-between, center);
      padding: 1rem 3rem;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #13975a;
      h1 {
        font-size: 1.7rem;
        padding: 0rem 0rem;
        color: $font-color-primary;
      }
      svg {
        cursor: pointer;
      }
    }
    .input-section {
      @include displayFlex(column, flex-start, center);
      width: 100%;
      overflow-y: auto;
      max-height: 400px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
      .input-element-content {
        position: relative;
        @include displayFlex(column, flex-start, flex-start);
        width: 80%;
        margin-top: 2rem;
        label {
          font-size: 1.7rem;
          margin-bottom: 1rem;
        }
        input {
          width: 100%;
          font-size: 1.6rem;
          padding: 0.5rem 1rem;
          border: none;
          outline: none;
          border-bottom: 1px solid $green;
          &.input-date-element {
            width: 150px !important;
          }
        }
        textarea {
          position: relative;
          min-height: 200px;
          max-height: 200px;
          width: 100%;
          outline: none;
          border-radius: 5px;
          border: 1px solid $green;
          font-size: 1.8rem;
          padding: 1rem;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
          }
        }
        .input-element-content-option {
          position: relative;
          overflow: hidden;
          width: 100%;
          position: absolute;
          top: 70px;
          height: auto;
          max-height: 200px;
          list-style: none;
          border-radius: 10px;
          box-shadow: 1px 1px 4px $gray-tree;
          background-color: #ffff;
          z-index: 1000000;
          li {
            cursor: pointer;
            :hover {
              background-color: $gray-for;
            }
            h1 {
              padding: 1rem 2rem;
              margin: 0px;
              font-size: 1.6rem;
            }
          }
        }
        &:nth-child(3) {
          input {
            width: 80px;
          }
        }
      }
      .body-form-add-element-content {
        width: 90%;
        padding: 0rem 0rem;
        padding-bottom: 0px;
        .error-content {
          font-size: 1.3rem;
          color: red;
        }
        .user-infomation {
          h1 {
            padding: 0px;
            margin-bottom: 2rem;
            font-size: 1.5rem;
          }
          .user-ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            list-style: none;
            li {
              font-size: 1.2rem;
            }
            &.user-information-content {
              padding: 0.5rem;
              margin-top: 1rem;
              border-radius: 5px;
              box-shadow: 1px 1px 4px $gray-tree;
            }
          }
        }
        .buttons-content {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          margin: 3rem 0rem;
          button {
            cursor: pointer;
            margin-right: 1rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            &.save-button {
              background-color: $green;
              color: $font-color-primary;
            }
            &.cancel-button {
              border: 1px solid $green;
            }
          }
        }
      }
    }
  }
  z-index: 2;
}

@media (max-width: 600px) {
  .form-edit-container-student-observer {
    .form-add-element-room {
      width: 100%;
    }
  }
}
.matter-list {
    div {
        width: 100%;
        li {
            width: 100%;
            .icon-arrow {
                transition: all .2s ease-in-out !important;
                margin-right: 2rem;
                &.display-true {
                    transform: rotate(180deg) !important;
                }
            }
        }
        .matter-element-content {
            height: 0px;
            &.display-true {
                height: auto;
            }
            ul {
                li {
                    background-color: rgb(34, 41, 48) !important;
                    &:hover {
                        background-color: rgb(28, 34, 39) !important;
                    }
                    h1 {
                        padding: 0rem 2rem !important;
                    }
                }
            }
        }
    }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.notification-bell-table-content {
  margin-top: 1rem;
  padding: 1.5rem;
  box-shadow: 1px 1px 3px $gray-for;
  border-radius: 10px;
  .notification-bell-table-content-header {
    @include displayFlex(row, space-between, center);
    border-bottom: 2px solid #1192184f;
    margin-bottom: 1rem;
    ul {
      @include displayFlex(row, flex-start, center);
      padding: 1rem;
      padding-left: 0rem;
      margin-left: 0rem;
      list-style: none;
      li {
        @include displayFlex(row, center, center);
        margin-left: 1rem;
        .icon-element {
          margin-right: 1rem;
        }
        h1 {
          font-size: 1.6rem;
        }
        .date-content {
          font-weight: 300;
        }
        .notification-title-content {
          max-width: 400px;
        }
      }
    }
    .visualization-confirmed-content {
      @include displayFlex(row, center, center);
      width: 30px;
      height: 30px;
      .icon-element {
        color: $green;
      }
    }
    .visualization-notification {
      position: relative;
      @include displayFlex(row, center, center);
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      .open-notification-button {
        position: relative;
        cursor: pointer;
        background-color: #ffffff00;
        border: none;
        transition: all ease-in-out 0.2s;
        .email-close {
          display: block;
        }
        .email-open {
          display: none;
        }
      }
      &:hover {
        border-radius: 100%;
        background-color: $green;
        color: #fff;
        .email-open {
          display: block;
        }
        .email-close {
          display: none;
        }
        .open-notification-button {
          color: #fff;
        }
      }
    }
  }
  .notification-bell-content {
    p {
      font-size: 1.5rem;
    }
  }
}


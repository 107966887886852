@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.information-strategy-element {
    overflow: auto;
    @include displayFlex(column, flex-start, center);
    height: 87vh;
    padding-bottom: 1rem;
    z-index: 3;
    .information-main {
        width: 70%;
        margin-top: 6rem;
        .information-header {
            text-align: start;
            h1 {
                font-weight: 500;
                font-size: 2.5rem;
            }
        }
        .information-body {
            .information-content {
                margin-top: 3rem;
                padding: 2.5rem;
                border-radius: 10px;
                box-shadow: 1px 1px 4px $gray-tree;
                h1 {
                    font-weight: 500;
                }
                h2 {
                    margin-top: 0.5rem;
                    padding: 1rem;
                    font-size: 2rem;
                    font-weight: 500;
                    color: rgb(107, 107, 107);
                }
            }
        }
    }
}
@import '../../config/style/mixins.scss';
@import '../../config/style/style.scss';

.ethnicity-chart-content {
    overflow-y: auto;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 90vh;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
    }
    .ethnicity-chart-content-header {
        width: 90%;
        margin-top: 2rem;
        font-size: 1.2rem;
        font-style: italic;
    }
    .ethnicity-chart-content-filters {
        @include displayFlex(row, flex-start, center);
        width: 90%;
        .input-select {
            margin-right: 2.5rem;
        }
    }
}

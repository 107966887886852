@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.config-user {
    overflow: hidden;
    position: absolute;
    @include displayFlex(column, center, center);
    width: 81.9%;
    height: 84vh;
    padding: 0rem;
    background-color: white;
    z-index: 2;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $green;
        margin: 0.2rem;
        border-radius: 20px;
    }
    &.display-false {
        display: none;
    }
    .form-config-user {
        width: 75%;
        height: 100%;
        padding-bottom: 2rem;
        .header-config-user  {
            margin: 3rem 0rem;
            h1 {
                width: 100%;
                text-align: start;
                position: relative;
                top: 25px;
                font-size: 2.5rem;
            }
            z-index: 3;
        }
        .body-config-user {
            width: 100%;
            margin-top: 2rem;
            padding: 0em 7rem;
            .input-content {
                width: 100%;
                padding-top: 2rem;
                padding-bottom: 1rem;
                margin-bottom: 0rem;
                @include displayFlex(column, center, flex-start);
                label {
                    cursor: pointer;
                    margin-bottom: 0rem;
                    font-size: 1.8rem;
                }
                input {
                    width: 100%;
                    font-size: 1.7rem;
                    padding: 1rem 0.5rem;
                    outline: none;
                    border: none;
                    color: gray;
                    background-color: transparent;
                    &:focus {
                        border-bottom: 1px solid $green;
                    }
                }
                .error-element {
                    padding: 0.5rem;
                    font-size: 1.2rem;
                    color: red;
                }
            }
            .buttons-content {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                padding: 2rem 0rem;
                margin: 3rem 0rem;
                button {
                    cursor: pointer;
                    margin-right: 1rem;
                    padding: 0.5rem 1rem;
                    font-size: 1.5rem;
                    border-radius: 5px;
                    background-color: transparent;
                    border: none;
                    &.save-button {
                        background-color: $green;
                        color: $font-color-primary;
                    }
                    &.cancel-button {
                        border: 1px solid $green;
                    }
                }
            }
        }
    }
}
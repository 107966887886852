@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-frequency {
    position: relative;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    .btn-create-new-matter-container {
        @include displayFlex(row, space-between, center);
        width: 100%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .mid-content-activity {
        @include displayFlex(row, space-between, flex-start);
        width: 90%;
        z-index: 1 !important;
        .mid-element-activity {
            .header-activity {
                @include displayFlex(row, flex-start, center);
                h1 {
                    font-style: italic;
                    font-size: 1.4rem;
                    color: $gray;
                    &:nth-child(1) {
                        padding-left: 0px;
                    }
                }
            }
            .dashbord-content {
                width: 100%;
                @include displayFlex(row, flex-end, flex-end);
                height: 130px !important;
                z-index: 1 !important;
            }
        }   
    }
    .header-table-element {
        width: 90%;
        margin-top: 1rem;
        border-top: 2px solid $green;
        border-radius: 5px;
        box-shadow: 1px 1px 3px $gray-for;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.2fr 2.3fr 1fr 1fr 0.2fr 0.2fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem;
            }
        }
    }
    .main-table {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        padding: 1rem 0rem;
        height: 55.5vh;
        &::-webkit-scrollbar {
            width: 12px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}

@media (max-width: 400px) {
  .table-container-frequency {
    .btn-create-new-matter-container {
      width: 95%;
    }
    .mid-content-activity {
      width: 95%;
    }
    .header-table-element {
      width: 95%;
    }
  }
}
@import '../../config/style/mixins.scss';

.loading-popup {
  @include displayFlex(row, center, center);
  position: fixed;
  right: 20px;
  top: 20px;
  border-radius: 5px;
  background-color: #fff;
  padding: 0.5rem;
  h2 {
    width: auto;
    padding: 0px;
    font-style: italic;
    white-space: nowrap;
    margin-left: 1rem;
  }
  &.display-false {
    display: none;
  }
}
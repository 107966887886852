@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.content-table-element-activity {
    width: 90%;
    margin-top: 2rem;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    &.display-animation-false {
      opacity: 0;
    }
    .content-table-ul-roms {
        cursor: pointer;
        display: grid;
        grid-template-columns: 0.2fr 2.3fr 1fr 1fr 0.2fr 0.2fr;
        list-style: none;
        justify-content: center;
        align-items: center;
        min-height: 70px;
        width: 100%;
        box-shadow: 1px 1px 4px $gray-tree;
        border-radius: 10px;
        &:hover {
            background-color: rgba(128, 128, 128, 0.075);
        }
        .content-table-ul-li {
            @include displayFlex(row, flex-start, center);
            padding: 1rem;
            text-align: start;
            font-weight: 300;
            color: $gray;
            &:nth-child(1)::before {
                display: none;
            }
            &:nth-child(5)::before {
                display: none;
            }
            &::before {
                position: relative;
                content: '';
                left: -10px;
                width: 1px;
                height: 50px;
                background-color: $gray-for;
                z-index: 1;
            }
            h2 {
                font-size: 1.3rem;
            }
            p {
                font-size: 1.3rem;
            }
        }
    }
}

@media (max-width: 400px) {
  .content-table-element-activity {
    width: 95%;
  }
}
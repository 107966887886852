@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

$border-color: #0a0a0a1f;

.table-container-ata-card-report {
  width: 98%;
  .header-content {
    width: 100%;
    h1 {
      padding: 2.5rem 0rem;
      font-size: 1.8rem;
      font-style: italic;
    }
    .form-search-student-individual-card-report {
      @include displayFlex(row, flex-start, center);
      .input-select {
        @include displayFlex(row, flex-start, center);
        margin: 0px;
        .input-content-select {
          width: 160px;
          margin: 0px;
          margin-left: 2rem;
          input {
            width: 160px;
          }
        }
        .input-content-list-options-y {
          left: 100px;
          top: 50px;
          width: 190px;
        }
      }
      .search-button {
        cursor: pointer;
        border: none;
        padding: 0.6rem 1.2rem;
        border-radius: 5px;
        outline: none;
        margin-left: 2rem;
        background-color: $green;
        color: #fff;
        &:nth-child(2) {
          margin-left: 6rem !important;
        }
      }
    }
  }
  .ata-card-report {
    min-width: 1000px;
    margin-top: 4rem;
    .ata-card-report-information-table {
      .ata-card-report-information-table-list {
        display: grid;
        grid-template-columns: 1fr 1fr 3fr;
        list-style: none;
        li {
          border: 2px solid $border-color;
          border-bottom: none;
          border-right: none;
          border-left: none;
          background-color: $green;
          &:nth-child(1) {
            border-left: 2px solid $border-color;
          }
          &:nth-last-child(1) {
            border-right: 2px solid $border-color;
          }
          h1 {
            padding: 0.5rem 1rem;
            color: #fff;
          }
        }
      }
    }
    .ata-card-report-header-table {
      border-top: 2px solid $green;
      border-radius: 5px;
      box-shadow: 1px 1px 3px $gray-for;
      .ata-card-report-header-table-title {
        display: grid;
        grid-template-columns: 0.3fr 2.2fr 0.7fr 0.7fr 2.3fr;
        list-style: none;
        li {
          border-collapse: collapse;
          border: 2px solid $border-color;
          border-right: none;
          background-color: $green;
          &:nth-last-child(1) {
            border: 2px solid $border-color;
          }
          h1 {
            display: flex;
            padding: 0.5rem 1rem;
            color: #fff;
            border-bottom: 2px solid $border-color;
          }
          .ata-card-report-frequency-title {
            display: grid;
            grid-template-columns: 0.5fr 0.5fr;
            height: fit-content;
            h2 {
              height: 100%;
              border-left: 2px solid $border-color;
              font-size: 1.5
              rem;
              padding: 0.2rem 0.5rem;
              text-align: center;
              color: #fff;
              &:nth-child(1) {
                border-left: none;
              }
            }
          }
          .ata-card-report-matter-list {
            @include displayFlex(row, flex-start, start);
            li {
              width: 35px;
              list-style: none;
              border-bottom: 1px solid $border-color;
              border-bottom: none;
              border-top: none;
              &:nth-child(1) {
                border-left: none;
              }
              &:nth-last-child(1) {
                border-right: none;
              }
              h1 {
                border-bottom: none;
                padding: 0.3rem 0.5rem;
              }
            }
          }
        }
      }
    }
    .ata-card-report-main-table {
      .ata-card-report-main-table-student-list {
        display: grid;
        grid-template-columns: 0.3fr 2.2fr 0.7fr 0.7fr 2.3fr;
        li {
          display: grid;
          border: 2px solid $border-color;
          border-collapse: collapse;
          border-top: none;
          border-left: none;
          list-style: none;
          &:nth-child(3),&:nth-child(4) {
            h1 {
              text-align: center;
            }
            grid-template-columns: 0.5fr 0.5fr;
          }
          h1 {
            font-size: 1.5rem;
            font-weight: 500;
            padding: 0.3rem 1rem;
            &:nth-child(2) {
              text-align: center;
              border-left: 2px solid $border-color;
            }
          }
          &:nth-child(1) {
            border-left: 1px solid $border-color;
          }
          .ata-card-report-main-table-matter-list {
            @include displayFlex(row, center, center);
            height: 100%;
            li {
              width: 35px;
              list-style: none;
              border-bottom: none;
              height: 100%;
              &:nth-child(1) {
                border-left: none;
              }
              &:nth-last-child(1) {
                border-right: none;
              }
              h1 {
                @include displayFlex(column, center, center);
                width: 100%;
                height: 100%;
                padding: 0px;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .ata-card-report-tag {
      margin-top: 2rem;
      .ata-card-report-tag-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, 250px);
        width: 100%;
        list-style: none;
        li {
          border: 1px solid $border-color;
          border-collapse: collapse;
          background-color: $green;
          h1 {
            color: #fff;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }
}

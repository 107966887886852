@import './../../../config/style/style.scss';
@import './../../../config/style/mixins.scss';

.export-waiting-student {
  overflow: hidden;
  position: fixed;
  display: flex;
  top: 100px;
  flex-direction: column;
  background-color: white;
  z-index: 9999;
  border-radius: 5px;
  padding-bottom: 2rem;
  box-shadow: 1px 1px 4px $gray-tree;
  &.display-false {
    display: none;
  }
  .header-export-waiting-student {
    display: flex;
    justify-content: end;
    background-color: $green;
    padding: 0.5rem;
  }
  .title-export-waiting-student {
    font-size: 0.8rem;
  }
  .btn-waiting-student-container {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 3rem;
    margin-top: 5rem;
    button {
      cursor: pointer;
      background-color: $green;
      padding: 0.7rem;
      border-radius: 3px;
      border: none;
      color: #fff;
    }
  }
}
@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.empty-element-content {
    @include displayFlex(row, center, center);
    max-width: 500px;
    height: 100%;
    .empty-element {
        @include displayFlex(column, center, center);
        .icon-content {
            @include displayFlex(row, center, center);
            width: 10rem;
            height: 10rem;
            border-radius: 100%;
            background-color: rgba(202, 255, 202, 0.616);
            .icon-element {
                color: $green;
            }
        }
        .children-content {
            @include displayFlex(column, center, center);
            h1 {
                font-weight: 500;
                color: $green;
            }
            p {
                text-align: center;
                font-size: 1.7rem;
                color: $gray-tree;
            }
        }
    }
}
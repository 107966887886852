@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.text-element-content {
  width: 90%;
  margin-top: 2rem;
  transition: all 0.5s ease-in-out;
  .header-text-element-content {
    h1 {
      font-size: 1.5rem;
      color: #818181;
    }
    margin-bottom: 2rem;
  }
  .text-element-input-content {
    @include displayFlex(row, center, center);
    width: 100%;
    textarea {
      position: relative;
      width: 80%;
      max-width: 100%;
      height: auto;
      padding: 1rem;
      font-size: 2rem;
      border: none;
      outline: 2px solid transparent;
      transition: all 0.3s ease-in-out;
      border-radius: 0px;
      border-bottom: 2px solid transparent;
      transition: background-color 0.3s ease-in-out;
      background-color: #00000000;
      &.saved-true {
        background-color: #c9ffc9;
      }
      .teste {
        position: absolute;
        top: -20px;
      }
      &:hover {
        border-bottom: 2px solid $green;
      }
      &:focus {
        border-radius: 3px;
        outline: 2px solid $green;
        border-bottom: 2px solid transparent;
      }
    }
    .icon-content {
      display: none;
      cursor: pointer;
      margin-left: 2rem;
      font-size: 0rem;
      color: #818181;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      .icon-content {
        font-size: 3.5rem;
        display: inline;
      }
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.student-info {
  overflow: hidden;
  // overflow-y: auto;
  position: absolute;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 85vh;
  padding: 0rem;
  background-color: rgba(255, 255, 255, 0);
  z-index: 2;
  // &::-webkit-scrollbar {
  //     width: 10px;
  // }
  // &::-webkit-scrollbar-thumb {
  //     background-color: $green;
  //     margin: 0.2rem;
  //     border-radius: 10px;
  // }
  &.display-false {
    display: none;
  }
  .form-edit-element {
    overflow: hidden;
    margin-top: 1rem;
    width: 75%;
    height: 400px;
    padding-bottom: 2rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 4px $gray-tree;
    .header-form-add-element-content {
      @include displayFlex(row, space-between, center);
      padding: 1rem 3rem;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #13975a;
      h1 {
        font-size: 1.7rem;
        padding: 0px;
        color: $font-color-primary;
      }
      svg {
        cursor: pointer;
      }
    }
    .main-content-element {
      overflow-x: hidden;
      overflow-y: auto;
      height: 350px;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
      .header-form-edit-element {
        @include displayFlex(row, flex-start, center);
        margin-top: 0rem;
        h1 {
          position: relative;
          top: 0px;
          padding-right: 0.5rem;
          font-size: 1.7rem;
        }
        span {
          font-size: 1.6rem;
        }
        z-index: 3;
      }
      .body-form-edit-element {
        display: grid;
        grid-template-columns: repeat(auto-fill, 250px);
        grid-template-rows: 1fr 1fr 1fr;
        gap: 10;
        width: 100%;
        margin-top: 1rem;
        padding: 0em 7rem;
        .input-content {
          width: 100%;
          padding-top: 0.5rem;
          padding-bottom: 1rem;
          margin-bottom: 0.5rem;
          @include displayFlex(column, center, flex-start);
          h1 {
            margin-bottom: 0rem;
            padding: 0px;
            font-weight: 500;
            font-size: 1.8rem;
          }
          .info-element {
            font-size: 1.7rem;
            font-weight: 400;
            padding: 1rem 0.5rem;
            outline: none;
            border: none;
            color: rgb(48, 48, 48);
            background-color: transparent;
            &:focus {
              border-bottom: 1px solid $green;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .student-info {
    .form-edit-element {
      width: 100%;
    }
  }
}
@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.file-information {
    @include displayFlex(column, center, center);
    h1 {
        font-size: 1.3rem;
    }
    .icon-content {
        font-size: 5.5rem;
        color: $green;
        &.pdf-icon {
            color: crimson;
        }
        &.img-icon {
            color: rgb(0, 155, 226);
        }
        &.img-docx {
            color: rgb(0, 73, 156);
        }
        &.img-xlsx {
          color: $green;
        }
        &.pptx-icon {
          color: rgb(255, 115, 0);
        }
    }
}

@media (max-width: 610px) {
  .file-information {
    h1 {
      font-size: 1rem;
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-describe-school-teaching-work-plan {
    position: absolute;
    width: 700px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 1px 1px 4px $gray-tree;
    &.display-false {
        display: none;
    }
    .header-form-describe-element-content {
        @include displayFlex(row, space-between, center);
        padding: 1rem 3rem;
        margin-bottom: 2rem;
        color: $font-color-primary;
        background-color: #13975a;
        h1 {
            font-size: 1.7rem;
            padding: 0px;
            color: $font-color-primary;
        }
        svg {
            cursor: pointer;
        }
    }
    .main-monthly-plan-display-information {
        overflow-y: auto;
        padding: 1rem 6rem;
        min-height: 200px;
        max-height: 300px;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
        .decent-work-information {
            &:nth-child(2) {
              margin-top: 1rem;
            }
            h1 {
                font-size: 1.6rem;
                font-style: italic;
                font-weight: bold;
                margin-top: 1rem;
                padding: 0.5rem 1rem;
                span {
                    font-size: 1.4rem;
                    color: rgb(88, 88, 88);
                }
            }
        }
        .description-content {
            width: 100%;
            h1 {
                width: 100%;
                text-align: center;
                margin-top: 1rem;
            }
            p {
                font-size: 1.6rem;
                padding: 2rem;
            }
        }
        .button-content {
            @include displayFlex(row, flex-end, center);
            width: 100%;
            margin-top: 2rem;
            button {
                cursor: pointer;
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                background-color: transparent;
                border: none;
                &.cancel-button {
                    background-color: $green;
                    color: $font-color-primary;
                }
            }
        }
    }
    z-index: 999;
}

@media (max-width: 600px) {
  .form-describe-school-teaching-work-plan {
    width: 100%;
  }
}
@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';
.recover-password-container {
    .form-login-content {
        @include displayFlex(row, center, center);
        width: 100%;
        height: 100vh;
        border-radius: 10px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.075);
        background-color: rgb(245, 245, 245);
    }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-writing-level-record {
  @include displayFlex(column, flex-start, center);
  position: relative;
  overflow-y: hidden;
  width: 100%;
  height: 82%;
  margin-top: 4rem;
  .btn-create-new-matter-container {
    @include displayFlex(row, flex-start, center);
    width: 90%;
    button {
      cursor: pointer;
      margin-bottom: 2rem;
      margin-right: 1rem;
      width: auto;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: transparent;
      border: none;
      background-color: $green;
      color: $font-color-primary;
      &.tag-manage-button {
        width: 140px;
      }
      &.tag-generate-pdf-button {
        width: 110px;
      }
    }
  }
  .mid-content-writing-level-record {
    @include displayFlex(row, space-between, flex-end);
    width: 99%;
    z-index: 1 !important;
    .mid-element-writing-level-record {
      .header-writing-level-record {
        @include displayFlex(row, flex-start, center);
        h1 {
          font-style: italic;
          font-size: 1.7rem;
          color: $gray;
          &:nth-child(1) {
            padding-left: 0px;
          }
        }
      }
      .dashbord-content {
        width: 100%;
        @include displayFlex(row, flex-end, flex-end);
        height: 130px !important;
        z-index: 1 !important;
      }
    }
  }
  .header-table-element {
    width: 99%;
    background-color: $green;
    .header-table-ul {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 0.2fr 1.5fr 1.2fr 1.4fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr 0.8fr;
      list-style: none;
      .header-table-ul-li {
        text-align: start;
        font-size: 1.5rem;
        padding: 0.5rem;
        color: white;
      }
    }
  }
  .main-table {
    @include displayFlex(column, flex-start, center);
    overflow-y: auto;
    width: 99%;
    height: 64vh;
    padding: 1rem 0rem;
    margin-bottom: 2em;
    padding-bottom: 2rem;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
  }
}


@media (max-width: 550px) {
  .table-container-writing-level-record {
    @include displayFlex(column, flex-start, start);
    overflow-x: auto;
    .header-table-element {
      width: 1200px;
    }
    .mid-content-writing-level-record {
      .mid-element-writing-level-record {
        .header-writing-level-record {
          h1 {
            font-size: 1.3rem;
          }
        }
      }
    }
    .main-table {
      width: 1200px;
    }
  }
}
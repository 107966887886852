@import './../../../config/style/style.scss';
@import './../../../config/style/mixins.scss';

.table-content-medical-record-psychologist {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  list-style: none;
  .date-content {
    @include displayFlex(row, flex-start, center);
  }
  li {
    border: 1px solid $green;
    border-top: 0px;
    h1 {
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: 500;
    }
    &:nth-child(2) {
      border-left: 0px solid black;
    }
  }
  .button-content-psychologist {
    @include displayFlex(row, center, center);
    position: absolute;
    right: -100px;
    height: 100%;
    background-color: transparent;
    transition: all 0.1s ease-in-out;
    button {
      cursor: pointer;
      border: 0px;
      padding: 0.2rem;
      margin-right: 1rem;
      border-radius: 3px;
      background-color: #fff;
    }
  }
  &:hover {
    .button-content-psychologist {
      right: 0px;
    }
  }
}
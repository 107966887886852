@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.mathematics-problem-solving-general-container {
  position: relative;
  @include displayFlex(row, center, flex-start);
  width: 100%;
  height: 100%;
  &.display-false {
    display: none;
  }
}
@import './../../config/style/mixins.scss';
@import './../../config/style/style.scss';

.psychologist-student-register {
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100vh;

  .psychologist-student-register-header {
    width: 90%;
    margin-top: 2rem;
    .psychologist-student-register-header-title {
      @include displayFlex(row, space-between, center);
      h1 {
        font-size: 2rem;
        font-style: italic;
        &:nth-child(2) {
          font-size: 1.5rem;
        }
      }
    }
    .button-content {
      margin-top: 2rem;

      button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        color: #fff;
        background-color: $green;
        border: none;
        border-radius: 5px;
      }
    }
  }
  .section-student-list {
    margin-top: 2rem;
    width: 90%;
    padding: 0.5rem;
    .section-student-list-header {
      width: 100%;
      border-top: 2px solid $green;
      box-shadow: 1px 1px 4px $gray-tree;
      border-radius: 5px;
      ul {
        width: 100%;
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 0.5fr 0.5fr;
        list-style: none;
        li {
          font-size: 1.5rem;
          padding: 1rem;
        }
      }
    }
    .section-student-list-table {
      @include displayFlex(column, flex-start, center);
      overflow-y: auto;
      width: 100%;
      height: 62vh;
      padding: 0.5rem;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}

@import '../../config/style/mixins.scss';
@import '../../config/style/style.scss';

.ethnicity-chart-content-general {
    overflow-y: auto;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 90vh;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
    }
    .ethnicity-chart-content-header {
        width: 90%;
        margin-top: 2rem;
        font-size: 1.2rem;
        font-style: italic;
    }
    .ethnicity-chart-general-content-filters {
      width: 90%;
    }
    .ethnicity-chart-content {
      .display-ethnicity-information {
        width: 90%;
        ul {
          list-style: none;
          li {
            @include displayFlex(flex, flex-start, center);
            margin-top: 1.5rem;
            font-size: 2rem;
            .ethnicity-color-span {
              margin-right: 1rem;
            }
          }
        }
      }
    }
}

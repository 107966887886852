@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.new-writing-hypothesis-container {
  position: relative;
  @include displayFlex(row, center, flex-start);
  width: 100%;
  height: 100%;
  &.display-false {
    display: none;
  }
  h1 {
    padding: 2rem;
    color: black;
  }
}

.question-content-response-from-student {
  width: 90%;
  margin-top: 10rem;
  .header-question-content-response-from-student {
    margin-bottom: 2rem;
    h1 {
      font-size: 2rem;
      
    }
  }
}
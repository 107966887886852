@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.form-edit-school-elementary-monthly-plan {
  overflow-y: auto;
  position: absolute;
  width: 600px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 4px $gray-tree;
  &::-webkit-scrollbar {
      width: 10px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
  }
  &.display-false {
      display: none;
  }
  .header-form-add-element-content {
      @include displayFlex(row, space-between, center);
      padding: 1rem 3rem;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #13975a;
      h1 {
          font-size: 1.7rem;
          padding: 0px;
          color: $font-color-primary;
      }
      svg {
          cursor: pointer;
      }
  }
  .main-form-edit-monthly-plan {
    padding-bottom: 2rem;
      .input-element-content {
          @include displayFlex(column, center, flex-start);
          width: 100%;
          label {
              font-size: 1.8rem;
              margin-top: 1rem;
          }
          input {
              border: none;
              outline: none;
              font-size: 1.6rem;
              padding: 1rem;
              width: 100%;
              border-bottom: 1px solid $green;
          }
          .error-element {
              padding: 0.5rem;
              font-size: 1.6rem;
              color: red;
          }
          &.date {
              input {
                  width: 300px;
              }
          }
          .input-content {
              width: 100%;
              margin: 1rem 4rem;
              input {
                  width: 100%;
              }
          }
      }
      .title {
          h1 {
              margin-top: 4rem;
              padding: 2rem 1rem
          }
      }
      .input-file-element {
          @include displayFlex(row, center, center);
          label {
              @include displayFlex(row, center, center);
              cursor: pointer;
              width: 80%;
              height: 200px;
              margin: 3rem 0rem;
              border-radius: 10px;
              border: 2px solid rgba(0, 128, 0, 0.151);
              .icon-content {
                  font-size: 5.5rem;
                  color: $green !important;
                  &.pdf-icon {
                      color: crimson;
                  }
                  &.img-icon {
                      color: rgb(0, 155, 226);
                  }
                  &.img-docx {
                      color: rgb(0, 73, 156);
                  }
              }
              
          }
          input {
              display: none;
          }
      }
      .button-content {
          @include displayFlex(row, flex-end, center);
          width: 100%;
          margin-top: 2rem;
          button {
              cursor: pointer;
              margin-right: 1rem;
              padding: 0.5rem 1rem;
              border-radius: 5px;
              background-color: transparent;
              border: none;
              &.save-button {
                  background-color: $green;
                  color: $font-color-primary;
              }
              &.cancel-button {
                  border: 1px solid $green;
              }
          }
      }
  }
  z-index: 999;
}

@media (max-width: 610px) {
  .form-edit-school-elementary-monthly-plan {
    width: 100%;
  }
}
@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.table-new-secretary-writing-hypothesis {
    @include displayFlex(column, start, center);
    position: relative;
    width: 100%;
    height: 100vh;
    .new-secretary-writing-hypothesis-table-header {
        width: 90%;
        margin-top: 3rem;
        h1 {
            font-style: italic;
        }
    }
    .new-secretary-writing-hypothesis-middle-content {
        display: grid;
        align-items: center;
        grid-template-columns: 0.8fr 1.5fr 50px;
        width: 90%;
        h1 {
            cursor: pointer;
            @include displayFlex(row, space-between, center);
            padding: 0.5rem 0.7rem;
            border-radius: 5px;
            color: #fff;
            background-color: $green;
            font-size: 1.5rem;
            .icon-graph {
                font-size: 2rem;
                color: #fff;
            }
        }
    }
    .new-secretary-writing-hypothesis-table-content {
        width: 90%;
        .new-secretary-writing-hypothesis-table-content-header {
            margin-top: 1rem;
            border-top: 2px solid $green;
            border-radius: 5px;
            box-shadow: 1px 1px 3px $gray-for;
            ul {
                display: grid;
                grid-template-columns: 0.5fr 3fr 3fr 2fr 0.5fr;
                list-style: none;
                li {
                    text-align: start;
                    font-size: 1.5rem;
                    padding: 1rem 1rem;
                }
            }
        }
        .new-secretary-writing-hypothesis-table-content-list {
            overflow: auto;
            width: 100%;
            height: 60vh;
            margin-top: 2rem;
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $green;
                border-radius: 20px;
            }
        }
    }
}

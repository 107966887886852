@import './../../config/style/style.scss';
@import './../../config/style/mixins.scss';

.exam-container {
  .exam-container-table-header {
    width: 90%;
    margin-top: 2rem;
    h1 {
    }
    ul {
      @include displayFlex(row, start, center);
      list-style: none;
      margin-top: 1rem;
      li {
        font-size: 1.7rem;
        font-style: italic;
        &:nth-child(2) {
          margin-left: 2rem;
        }
      }
    }
  }
  .exam-container-table {
    @include displayFlex(column, start, center);
    overflow-y: auto;
    scroll-behavior: smooth;
    height: 90vh;
    width: 100%;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
    .button-submit-content {
      @include displayFlex(row, end, center);
      width: 90%;
      margin: 3rem 0rem;
      button {
        cursor: pointer;
        padding: 1rem 2rem;
        border-radius: 4px;
        font-size: 1.5rem;
        border: none;
        background-color: $green;
        color: white;
      }
    }
  }
}

@media (max-width: 560px) {
  .exam-container {
    .exam-container-table-header {
      h1 {
        font-size: 1.5rem;
      }
      ul {
        li {
          font-size: 1.5rem;
        }
      }
    }
    .exam-container-table {
      .button-submit-content {
        button {
          padding: 0.5rem 1rem;
          font-size: 1.2rem;
        }
      }
    }
  }
}

@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.math-diagnostic-dashboard-content {
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  &.display-false {
    display: none;
  }
  .math-diagnostic-dashboard-content-header {
    @include displayFlex(row, space-between, center);
    width: 90%;
    margin-top: 3rem;
    h1 {
      font-style: italic;
      font-size: 1.7rem;
    }
    .button-content {
      @include displayFlex(row, space-between, center);
      button {
        cursor: pointer;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        background-color: $green;
        border: 0px;
        color: #fff;
        &:nth-child(1) {
          margin-right: 2rem;
        }
      }
    }
  }
  .mathematic-diagnostic-dashboard-content {
    .dashboard-default-image {
      width: 60%;
    }
    padding-bottom: 2rem;
    .mathematic-diagnostic-dashboard-info {
      width: 100%;
      padding-bottom: 3rem;
      ul {
        list-style: none;
        li {
          @include displayFlex(row, flex-start, center);
          margin-top: 2rem;
          h1 {
            font-size: 1.5rem;
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .math-diagnostic-dashboard-content-input-selector {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .math-diagnostic-dashboard-content {
    .mathematic-diagnostic-dashboard-content {
      .mathematic-diagnostic-dashboard-info {
        margin-left: 2rem;
        width: 90%;
        h1 {
          font-size: 1.4rem;
        }
        ul {
          li {
            h1 {
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}
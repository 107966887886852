@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.mathematics-problem-solving-add-new-tag {
  @include displayFlex(column, flex-start, center);
  overflow: hidden;
  position: absolute;
  top: 10%;
  width: 600px;
  height: 400px;
  background-color: #fff;
  box-shadow: 1px 1px 4px $gray-tree;
  z-index: 9999;
  border-radius: 5px;
  &.display-false {
    display: none;
  }
  .mathematics-problem-solving-add-new-tag-header {
    @include displayFlex(row, space-between, center);
    width: 100%;
    background-color: $green;
    padding: 0.5rem 1rem;
    * {
      color: #fff;
    }
    h1 {
      padding-left: 1rem;
      font-size: 1.5rem;
    }
    .icon-content {
      cursor: pointer;
      padding-right: 1rem;
    }
  }
  .mathematics-problem-solving-add-new-tag-form-content {
    @include displayFlex(row, space-between, center);
    width: 90%;
    margin-top: 3rem;
    .input-content {
      position: relative;
      input {
        width: 300px;
        border: none;
        box-shadow: 1px 1px 4px $gray-tree;
        font-size: 1.3rem;
        outline: none;
        padding: 0.8rem 1.5rem;
        border-radius: 3px;
      }
      .error-element {
        position: absolute;
        width: 100%;
        top: 30px;
        left: 0px;
      }
    }
    .button-content {
      button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: $green;
        border-radius: 5px;
        border: none;
        color: #fff;
      }
    }
  }
  .mathematics-problem-solving-add-new-tag-table {
    width: 90%;
    .mathematics-problem-solving-add-new-tag-table-header {
      width: 100%;
      ul {
        display: grid;
        grid-template-columns: 0.2fr 2fr 0.2fr;
        list-style: none;
        border-bottom: 2px solid $gray-for;
        margin-top: 2rem;
        li {
          h1 {
            font-size: 1.5rem;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
    .mathematics-problem-solving-add-new-tag-table-content {
      overflow-y: auto;
      height: 200px;
      padding: 0.2rem;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}

.mathematics-problem-solving-add-new-tag-table-list-content {
  width: 100%;
  box-shadow: 1px 1px 4px $gray-tree;
  border-radius: 5px;
  margin-top: 1.5rem;
  &:nth-child(1) {
    margin-top: 2rem;
  }
  ul {
    display: grid;
    grid-template-columns: 0.2fr 2fr 0.2fr;
    list-style: none;
    width: 100%;
    li {
      @include displayFlex(row, flex-start, center);
      h1 {
        font-weight: 300;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
      }
      .delete-icon {
        cursor: pointer;
      }
    }
  }
}

@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.student-exams-table-content {
  width: 100%;
  cursor: pointer;
  margin-top: 3rem;
  .student-exams-table-content-ul {
    display: grid;
    grid-template-columns: 0.3fr 3fr 2fr 2fr 1fr 0.5fr 0.5fr;
    list-style: none;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    width: 100%;
    box-shadow: 1px 1px 4px $gray-tree;
    border-radius: 10px;
    &:hover {
      background-color: rgba(128, 128, 128, 0.075);
    }
    li {
      @include displayFlex(row, flex-start, center);
      padding: 1rem;
      text-align: start;
      font-weight: 300;
      font-size: 1.5rem;
      color: $gray;
      &:nth-child(1)::before {
        display: none;
      }
      &:nth-child(6)::before {
        display: none;
      }
      &:nth-child(7)::before {
        display: none;
      }
      &::before {
        position: relative;
        content: '';
        left: -10px;
        width: 1px;
        height: 50px;
        background-color: $gray-for;
        z-index: 1;
      }
      .icon-content {
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 620px) {
  .student-exams-table-content {
    .student-exams-table-content-ul {
      grid-template-columns: 3fr 2fr 2fr 1fr 0.5fr 0.5fr;
      li {
        font-size: 1.2rem;
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 380px) {
  .student-exams-table-content {
    .student-exams-table-content-ul {
      li {
        font-size: 1rem;
      }
    }
  }
}
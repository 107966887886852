@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.individual-card-report {
  overflow-y: auto;
  position: relative;
  @include displayFlex(row, center, flex-start);
  width: 100%;
  height: 90vh;
  &::-webkit-scrollbar {
    width: 10px;
    margin-left: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  &.display-false {
    display: none;
  }
  h1 {
    padding: 2rem;
    color: black;
  }
}

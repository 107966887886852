@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.municipal-education-plan-monitoring-sheet-indicators-table {
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100vh;

  .municipal-education-plan-monitoring-sheet-indicators-section {
    width: 90%;
    .municipal-education-plan-monitoring-sheet-indicators-header {
      @include displayFlex(row, space-between, start);
      width: 100%;
      margin-top: 3rem;
      h1 {
        font-size: 1.8rem;
        font-style: italic;
      }
      .button-content {
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: $green;
        color: #fff;
        border: none;
        border-radius: 5px;
      }
    }
  }
  .municipal-education-plan-monitoring-sheet-indicators-table {
    width: 90%;
    margin-top: 2rem;
    overflow-y: auto;
    height: 70vh;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
  }
}

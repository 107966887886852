@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.writing-level-manager-container-student {
  position: fixed;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100vh;
  &.display-false {
    display: none;
  }
  .form-add-element {
    overflow-y: auto;
    background-color: white;
    width: 800px;
    height: 500px;
    border-radius: 3px;
    box-shadow: 1px 1px 4px $gray-tree;
    .header-form-add-element-content {
      @include displayFlex(row, space-between, center);
      padding: 1rem 3rem;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #13975a;
      h1 {
        font-size: 1.7rem;
        padding: 0px;
        color: $font-color-primary;
      }
      svg {
        cursor: pointer;
      }
    }
    .nav-content-write-level-manager {
      @include displayFlex(row, center, center);
      width: 100%;
      margin-bottom: 2rem;
      ul {
        width: 90%;
        overflow-y: auto;
        @include displayFlex(row, flex-start, center);
        list-style: none;
        border-bottom: 1px solid rgba(18, 207, 18, 0.507);
        &::-webkit-scrollbar {
          height: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
        }
        .tag-title-table-select {
          position: relative;
          cursor: pointer;
          padding: 0.5rem 1rem;
          font-size: 1.5rem;
          h1 {
            width: 120px;
            text-align: center;
            font-weight: 500;
            padding: 0px;
            font-size: 1.5rem;
          }
          .delete-tag-title-table-select-button {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0px;
            z-index: 9999;
            background-color: #fff;
            transition: all 0.5s ease-in-out;
            button {
              cursor: pointer;
              width: 50%;
              height: 100%;
              color: #fff;
              border: none;
              &:nth-child(1) {
                background-color: $green;
              }
              &:nth-child(2) {
                background-color: rgb(219, 14, 14)
              }
            }
            &.display-false {
              top: -50px;
            }
          }
          &.display-true {
            background-color: rgba(18, 207, 18, 0.507) !important;
          }
          &:hover {
            background-color: rgba(18, 207, 18, 0.116);
          }
          &:nth-child(1) {
            margin-left: 0rem;
          }
          &:nth-last-child(1) {
            cursor: inherit;
            &:hover {
              background-color: #ffffff00 !important;
            }
          }
          .add-new-table-select {
            @include displayFlex(row, flex-start, center);
            overflow: hidden;
            width: 240px;
            transition: all 0.2s ease-in-out;
            &.display-false {
              width: 50px;
              .input-content-add-new-table-select {
                input {
                  padding: 0rem;
                  width: 0px;
                }
              }
              .add-Button {
                button {
                  right: 0;
                }
              }
              .submit-end-cancel-Button {
                cursor: pointer;
                right: -50px;
                button {
                  cursor: pointer;
                }
              }
            }
            .input-content-add-new-table-select {
              input {
                width: 150px;
                padding: 0.5rem;
                border: none;
                outline: none;
                border-bottom: 1px solid $green;
                background-color: transparent;
                transition: all 0.2s ease-in-out;
              }
            }
            .add-Button {
              button {
                cursor: pointer;
                position: relative;
                right: -90px;
                border: none;
                background-color: #ffffff00;
                margin-left: 1rem;
                transition: all 0.2s ease-in-out;
              }
            }
            .submit-end-cancel-Button {
              cursor: pointer;
              @include displayFlex(row, center, center);
              position: relative;
              right: 40px;
              transition: all 0.2s ease-in-out;
              button {
                cursor: pointer;
                border: none;
                background-color: #ffffff00;
                margin-left: 1rem;
                transition: all 0.2s ease-in-out;
              }
            }
          }
        }
      }
    }
    .form-content {
      @include displayFlex(row, center, center);
      width: 100%;
      .input-content {
        @include displayFlex(row, space-between, center);
        width: 90%;
        .input-text {
          width: 200px;
          font-size: 1.5rem;
          padding: 0.7rem 1rem;
          border-radius: 5px;
          border: none;
          outline: none;
          box-shadow: 1px 1px 3px $gray-for;
        }
        .input-color-content {
          position: relative;
          @include displayFlex(row, center, center);
          .label-input-color {
            position: absolute;
            width: 30px;
            height: 30px;
            border: 1px solid rgb(0, 0, 0);
            border-radius: 50%;
          }
          .input-color {
            width: 0px;
            height: 0px;
            padding: 0px;
            margin: 0px;
            outline: none;
            border: none;
            background-color: transparent;
          }
        }
        button {
          cursor: pointer;
          padding: 0.7rem 1rem;
          border-radius: 5px;
          background-color: transparent;
          border: none;
          background-color: $green;
          color: $font-color-primary;
        }
      }
    }
    .header-table-content {
      @include displayFlex(row, center, center);
      width: 100%;
      margin-top: 3rem;
      .header-table-content-ul {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr 0.5fr;
        width: 90%;
        list-style: none;
        border-bottom: 2px solid $gray-tree;
        li {
          padding: 0.2rem;
          h1 {
            padding: 0.2rem;
            text-align: start;
            font-size: 1.3rem;
          }
        }
      }
    }
    .table-of-tags {
      @include displayFlex(column, center, center);
      width: 100%;
      .table {
        @include displayFlex(column, flex-start, center);
        overflow-y: auto;
        top: 0px;
        width: 90%;
        height: 220px;
        padding: 1rem 0.2rem;
        &:hover {
          li {
            background-color: transparent;
          }
        }
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
        }
      }
    }
  }
  z-index: 2;
}

@media (max-width: 600px) {
  .writing-level-manager-container-student {
    .form-add-element {
      width: 100%;
    }
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.student-frequency-content-body {
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 0.2rem;
    height: 300px;
    max-height: 300px;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
    }
    .student-frequency-content-body-list {
        display: grid;
        grid-template-columns: 0.2fr 1.5fr 1fr;
        list-style: none;
        border-radius: 5px;
        box-shadow: 1px 1px 4px $gray-tree;
        margin-bottom: 1rem;
        h2 {
            padding: 1rem;
        }
        li {
            .frequency-input {
                padding: 1rem;
                height: 100%;
                cursor: pointer;
                input {
                    display: none;
                }
                .input-frequency-label {
                    position: relative;
                    width: 50px;
                    border-radius: 5px;
                    background-color: rgba(56, 56, 56, 0.192);
                    .input-radio-element {
                        position: relative;
                        left: 0px;
                        width: 25px;
                        height: 20px;
                        border-radius: 5px;
                        background-color: crimson;
                        transition: all 0.2s ease-in-out;
                        z-index: 2;
                    }
                    &.display-true {
                        .input-radio-element {
                            left: 50px - 25px;
                            background-color: $green;
                        }
                    }
                    .frequency-name {
                        @include displayFlex(row, space-between, center);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        p {
                            font-size: 1.5rem;
                            padding: 0rem 0.8rem;
                            color: black;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}
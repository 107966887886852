@import '../../config/style/mixins.scss';
@import '../../config/style/style.scss';

.read-checking-element-decent-work {
  overflow: hidden;
  position: absolute;
  width: 450px;
  border-radius: 3px;
  margin-top: 6rem;
  &.display-false {
    display: none;
  }
  background-color: #ffff;
  box-shadow: 1px 1px 4px $gray-tree;
  z-index: 99999;
  .header-form-describe-element-content {
    @include displayFlex(row, space-between, center);
    padding: 1rem 3rem;
    margin-bottom: 2rem;
    color: $font-color-primary;
    background-color: #13975a;
    h1 {
        font-size: 1.7rem;
        padding: 0px;
        color: $font-color-primary;
    }
    svg {
        cursor: pointer;
    }
  }
  .main-content-element {
    padding: 0.5rem 3rem;
  }
  .button-confirm-content {
    @include displayFlex(row, space-between, center);
    width: auto;
    margin-top: 2rem;
    padding: 2rem 3rem;
    button {
        width: 150px;
        height: 30px;
        cursor: pointer;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        font-size: 1.5rem;
        background-color: transparent;
        border: none;
        &.button-confirm {
            background-color: $green;
            color: $font-color-primary;
        }
        &.button-cancel {
            border: 1px solid $green;
        }
    }
}
}
@import "../../../../../config/style/style.scss";
@import "../../../../../config/style/mixins.scss";

.to-compare-dashboard-component {
  @include displayFlex(column, center, center);
    width: 100%;
    &.display-false {
        display: none;
    }
    .dashboard-content {
      @include displayFlex(row, space-between, center);
      width: 100%;
      .graphic-content {
        margin-top: 0px;
      }
    }
    .breakthrough-dashboard {
        @include displayFlex(row, center, center);
        width: 100%;
        margin-top: 4rem !important;
    }
}
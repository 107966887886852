@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.mathematic-diagnostic-assessment-table-container {
  position: relative;
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  height: 90vh;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  .mathematic-diagnostic-assessment-table-header {
    @include displayFlex(row, space-between, center);
    width: 90%;
    margin-top: 4rem;
    h1 {
      font-size: 1.8rem;
      font-style: italic;
    }
    .buttons-content {
      @include displayFlex(row, center, center);
      button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        color: #fff;
        border: none;
        background-color: $green;
        border-radius: 4px;
        margin-right: 1rem;
        &:nth-child(3) {
          margin: 0px;
        }
      }
    }
  }
  .mathematic-diagnostic-assessment-table-content {
    width: 90%;
    margin: 2rem;
    .mathematic-diagnostic-assessment-table-element {
      border-collapse: collapse;
      border: 2px solid $green;
      border-top: 0px;

      width: 100%;
      tr {
        th {
          background-color: $green;
          padding: 0.5rem;
          font-size: 1.2rem;
          width: max-content;
          text-align: start;
          color: #fff;
        }

        td {
          padding: 0.5rem;
          font-size: 1.3rem;
        }

        &:nth-child(2n) {
          td {
            background-color: rgb(226, 226, 226);
          }
        }
      }
    }
  }
}

.mathematic-diagnostic-assessment-input-select {
  position: relative;
  width: 100%;
  .mathematic-diagnostic-assessment-input-content {
    @include displayFlex(row, center, center);
    input {
      width: 100%;
      border: none;
      outline: none;
      font-size: 1.5rem;
      background-color: transparent;
    }
    .icon-arrow {
      color: black;
      &.display-true {
        transform: rotate(180deg);
      }
    }
  }
  .mathematic-diagnostic-assessment-input-select-options {
    position: absolute;
    top: 30px;
    width: 100%;
    background-color: #fff;
    list-style: none;
    border-radius: 3px;
    box-shadow: 1px 1px 4px $gray-tree;
    z-index: 9999;
    li {
      padding: 0.7rem;
      font-size: 1.3rem;
      &:hover {
        cursor: pointer;
        background-color: $gray-for;
      }
    }
    &.display-false {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .mathematic-diagnostic-assessment-table-container {
    @include displayFlex(column, flex-start, start);
    padding: 1rem;
    .mathematic-diagnostic-assessment-table-content {
      min-width: 1200px
    }
  }
}
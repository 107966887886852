@import "../../../config/style/style.scss";
@import "../../../config/style/mixins.scss";

.content-table-element-reading-fluency-page {
    width: 90%;
    margin-top: 3rem;
    .content-table-ul-rom {
        cursor: pointer;
        display: grid;
        grid-template-columns: 0.2fr 2.3fr 1fr;
        list-style: none;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        width: 100%;
        box-shadow: 1px 1px 4px $gray-tree;
        border-radius: 10px;
        &:hover {
            background-color: rgba(128, 128, 128, 0.075);
        }
        li {
            input {
                display: none;
            }
        }
        .input-select-content {
            position: relative;
            .label-select-input-content {
                @include displayFlex(row, flex-start, center);
                h1 {
                    cursor: pointer;
                    font-size: 1.4rem;
                    font-weight: 400;
                    z-index: 1;
                }
            }
            .options-select-content {
                overflow-y: auto;
                position: absolute;
                width: 75%;
                padding: 0.2rem;
                border-radius: 5px;
                max-height: 100px;
                background-color: #fff;
                list-style: none;
                box-shadow: 1px 1px 4px $gray-tree;
                z-index: 10;
                &::-webkit-scrollbar {
                    width: 5px;
                    padding-right: 0.5rem;
                  }
                &::-webkit-scrollbar-thumb {
                    background-color: $green;
                    border-radius: 20px;
                }
                &.display-false {
                    display: none;
                }
                li {
                    font-size: 1.6rem;
                    padding: 0.5rem 1rem;
                    background-color: #fff;
                    &:hover {
                        background-color: $gray-for;
                    }
                }
            }
        }
        .input-radio-content {
            width: 130px;
            @include displayFlex(row, center, center);
            .radio-input-style-element {
                @include displayFlex(column, center, center);
                width: 24px;
                height: 24px;
                padding: 0px;
                margin: 0px;
                border: 2px solid $green;
                border-radius: 50px;
                .icon-content {
                    @include displayFlex(column, center, center);
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    background-color: $green;
                    &.display-false {
                        display: none;
                    }
                    .icon {
                        color: #fff;
                    }
                }
            }
        }
        .content-table-ul-li {
            @include displayFlex(row, flex-start, center);
            padding: 1rem;
            text-align: start;
            font-weight: 300;
            color: $gray;
            &:nth-child(1)::before {
                display: none;
            }
            &:nth-child(8)::before {
                display: none;
            }
            &::before {
                position: relative;
                content: "";
                left: -10px;
                width: 1px;
                height: 50px;
                background-color: $gray-for;
                z-index: 1;
            }
            h2 {
                @include displayFlex(column, flex-start, flex-start);
                font-size: 1.3rem;
                span {
                    color: gray;
                }
            }
            p {
                font-size: 1.3rem;
            }
        }
    }
}

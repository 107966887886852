@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.content-table-element-cardReport {
    width: 90%;
    margin-top: 0rem;
    border-bottom: 1px solid  $gray-for;
    border-collapse: collapse;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    &.display-animation-false {
      opacity: 0;
    }
    .content-table-ul {
        cursor: pointer;
        display: grid;
        grid-template-columns: 0.5fr 6fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
        list-style: none;
        justify-content: center;
        align-items: center;
        width: 100%;
        &:hover {
            background-color: rgba(128, 128, 128, 0.075);
        }
        .content-table-ul-li {
            @include displayFlex(row, flex-start, center);
            padding: 0;
            text-align: start;
            font-weight: 300;
            color: $gray;
            padding: 0rem 1rem;
            .input-content-table-ul-li {
                width: 25px;
                height: 25px;
                text-align: center;
                border: none;
            }
            &:nth-child(1) {
                padding-left: 1rem;
            }
            &:nth-child(1)::before {
                display: none;
            }
            &::before {
                position: relative;
                content: '';
                left: -10px;
                width: 1px;
                height: 50px;
                background-color: $gray-for;
                z-index: 1;
            }
            h2 {
                font-size: 1.3rem;
            }
            p {
                font-size: 1.3rem;
            }
        }
    }
}

@media (max-width: 600px) {
  .content-table-element-cardReport {
    width: 100%;
  }
}
@import "../../../config/style/style.scss";
@import "../../../config/style/mixins.scss";

.content-table-element-tags{
    width: 100%;
    margin-top: 1rem;
    .content-table-ul-tag {
        cursor: pointer;
        display: grid;
        grid-template-columns:  1fr 3fr 1fr 0.5fr;
        list-style: none;
        justify-content: center;
        align-items: center;
        min-height: 30px;
        box-shadow: 1px 1px 4px $gray-tree;
        border-radius: 5px;
        &:hover {
            background-color: rgba(128, 128, 128, 0.075);
        }
        li {
            h2 {
                padding: 1.5rem 1rem;
                font-size: 1.2rem;
            }
            box-shadow: none;
            border: none;
            .content-color {
                border: 1px solid rgb(0, 0, 0);
                border-radius: 50%;
            }
        }
    }
}

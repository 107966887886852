@import '../../../../config/style/mixins.scss';
@import '../../../../config/style/style.scss';

.option-element {
  @include displayFlex(row, start, center);
  margin-top: 0.5rem;
  background-color: #00000000;
  transition: background-color 0.3s ease-in-out;
  .option-element-checkbox-content {
    @include displayFlex(row, center, center);
    margin-right: 1rem;
    input {
        width: 20px;
        height: 20px;
    }
  }
  .option-element-text-content {
    width: 100%;
    input {
      width: 100%;
      border: none;
      font-size: 1.7rem;
      padding: 0.5rem;
      outline: none;
      color: #000000;
      background-color: transparent;
      &:focus {
        border-bottom: 2px solid $green;
      }
    }
  }
  .option-element-trash-content {
    .icon-content {
      color: rgba(255, 0, 0, 0);
      transition: color 0.2s ease-in-out;
    }
  }

  &.saved-true {
    background-color: #c9ffc9;
  }

  &:hover {
    .option-element-trash-content {
      .icon-content {
        color: rgb(255, 0, 0);
        transition: color 0.2s ease-in-out;
      }
    }
  }
}

@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.form-recover-by-email-main {
    @include displayFlex(row, center, center);
    width: 400px;
    height: 300px;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.075);
    background-color: #fff;
    .form-login-element {
        width: 90%;
        .form-login-title {
            text-align: center;
            margin-bottom: 6rem;
            color: $green;
        }
        &.display-false {
            display: none;
        }
        .input-login-content {
            @include displayFlex(column, flex-start, flex-start);
            margin-bottom: 2rem;
            label {
                font-size: 1.2rem;
            }
            .input-content-recover-by-email {
                @include displayFlex(row, center, center);
                position: relative;
                width: 100%;
                padding: 0rem 0.5rem;
                border-bottom: 1px solid $green;
                input {
                    width: 100%;
                    padding: 1rem 0.2rem; 
                    outline: none;
                    border: none;
                    background-color: transparent;
                }
                .icon-user-login {
                    margin-right: 0.5rem;
                    color: $gray-tree;
                }
            }
            .error-element {
                padding: 1rem 0.1rem;
                border-radius: 5px;
                font-size: 1.2rem;
                font-style: italic;
                color: red;
            }
            &.button-login-content  {
                @include displayFlex(row, space-between, flex-start);
                margin-top: 4rem;
                button {
                    cursor: pointer;
                    width: 130px;
                    padding: 0.7rem 0rem;
                    border: none;
                    border-radius: 5px;
                    &:nth-child(1) {
                        color: $font-color-primary;
                        background-color: $green;
                    }
                    &:nth-child(2) {
                        border: 1px solid $green;
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
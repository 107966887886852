@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.student-exams-form-edit {
  overflow: hidden;
  position: absolute;
  width: 600px;
  height: 470px;
  margin-top: 4rem;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 1px 4px $gray-tree;
  background-color: #fff;
  &.display-false {
    display: none;
  }
  .student-exams-form-add-header {
    @include displayFlex(row, space-between, center);
    padding: 1rem 3rem;
    margin-bottom: 2rem;
    color: $font-color-primary;
    background-color: #13975a;
    h1 {
      font-size: 1.7rem;
      padding: 0px;
      color: $font-color-primary;
    }
    svg {
      cursor: pointer;
    }
    z-index: 9999;
  }
  .input-container {
    @include displayFlex(column, start, center);
    overflow-y: auto; 
    height: 400px;
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    .input-content {
      @include displayFlex(column, center, start);
      width: 80%;
      label {
        font-size: 1.8rem;
      }
      input {
        margin-top: 1rem;
        width: 100%;
        outline: none;
        padding: 1rem 0.5rem;
        border: none;
        border-bottom: 1px solid $green;
      }
      &.date-input input {
        width: 60%;
      }
    }
  }
  .input-element-select {
    width: 80%;
    margin-top: 3rem;
    padding: 0rem 1rem;
    h1 {
      font-size: 1.8rem;
      font-weight: 400;
    }
    .input-select {
      position: relative;
      cursor: pointer;
      @include displayFlex(row, start, center);
      width: 220px;
      margin-top: 2rem;
      box-shadow: 1px 1px 4px $gray-tree;
      border-radius: 4px;
      input {
        cursor: pointer;
        width: 75%;
        border: none;
        outline: none;
        padding: 1rem;
        background-color: #fff;
      }
      .icon-arrow {
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
        color: $gray;
        &.display-true {
          transform: rotate(-180deg);
        }
      }
      .option-list {
        position: absolute;
        overflow-y: auto;
        top: 45px;
        border-radius: 4px;
        height: 150px;
        box-shadow: 1px 1px 4px $gray-tree;
        background-color: white;
        width: 100%;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
        }
        &.display-false {
          display: none;
        }
        z-index: 9999;
        li {
          padding: 1rem;
          font-size: 1.5rem;
          &:hover {
            background-color: $gray-for;
          }
        }
      }
    }
  }
  .bt-form-content {
    @include displayFlex(row, end, center);
    margin-top: 5rem;
    width: 80%;
    button {
      cursor: pointer;
      padding: 0.5rem 1rem;
      background-color: $green;
      border: none;
      color: white;
      border-radius: 3px;

      &.cancel-button {
        margin-right: 1rem;
        background-color: #fff;
        border: 1px solid $green;
        color: #000000;
      }
    }
  }
  z-index: 99999;
}

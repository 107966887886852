.municipal-education-planMonitoring-sheet-page {
  display: flex;
  width: 100%;
  .content-container {
    scroll-behavior: smooth;
    overflow-y: hidden;
    width: 90%;
    height: 100vh;
    .content {
      width: 100%;
    }
    .content#display-false {
      display: none;
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.dashboard-writing-level {
  overflow-y: auto;
  @include displayFlex(column, flex-start, center);
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100vh;
  background-color: #ffff;
  z-index: 9999;
  .button-content {
    @include displayFlex(row, flex-start, center);
    width: 80%;
    padding: 1rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    border-top: 3px solid $green;
    box-shadow: 1px 1px 4px $gray-tree;
    border-radius: 4px;
    button {
      cursor: pointer;
      margin-right: 2rem;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: transparent;
      border: none;
      background-color: $green;
      color: $font-color-primary;
    }
    .input-select {
      margin-top: 0px;
      @include displayFlex(row, flex-start, center);
      .input-content-title {
        @include displayFlex(row, flex-start, center);
        margin-right: 3rem;
        padding: 0rem;
      }
      .input-content-select {
        @include displayFlex(row, center, center);
        margin: 0px;
        input {
          padding: 0px;
        }
      }
      .input-content-list-options-y {
        overflow-y: auto;
        position: absolute;
        padding: 0px;
        top: 30px;
        right: 0px;
        width: 220px;
        max-height: 150px;
        border-radius: 5px;
        box-shadow: 1px 1px 4px $gray-tree;
        background-color: #fff;
        z-index: 99999;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
        }
        &.display-false {
          overflow: hidden;
          height: 0px;
        }
        ul {
          list-style: none;
          width: 100%;
          li {
            cursor: pointer;
            padding: 1rem 2.5rem;
            font-size: 1.6rem;
            &:hover {
              background-color: $gray-for;
            }
          }
        }
      }
    }
  }
  h1 {
    font-size: 2rem;
    color: rgb(56, 56, 56) !important;
  }
  .category-count-information {
    width: 50%;
    padding-bottom: 2rem;
    ul {
      list-style: none;
      li {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        h1 {
          font-size: 1.7rem;
          padding: 0px;
          margin-left: 1rem;
        }
        .color-content {
          width: 20px;
          height: 20px;
          border-radius: 100%;
        }
      }
    }
  }
  &.display-false {
    display: none;
  }
  .graphic-content {
    margin-top: 5rem;
    &:nth-child(2) {
      margin-top: 0rem;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  z-index: 2;
}

@media (max-width: 600px) {
  .dashboard-writing-level {
    h2 {
      font-size: 1.4rem;
    }
    .button-content {
      @include displayFlex(row, flex-start, center);
      width: 95%;
      button {
        font-size: 1.2rem;
      }
      .input-select {
        .input-content-select {
          @include displayFlex(row, start, center);
          margin: 0px;
          width: 120px;
          input {
            width: 120px;
          }
        }
        .input-content-list-options-y {
          width: 160px;
          left: 70px;
        }
      }
    }
    .category-count-information {
      margin-left: 2rem;
      ul {
        li {
          h1 {
            font-size: 1.3rem;
          }
          .color-content {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}

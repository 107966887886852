@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.content-table-element-student-report {
  width: 90%;
  margin-top: 2rem;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  &.display-animation-false {
    opacity: 0;
  }
  .content-table-ul {
    cursor: pointer;
    display: grid;
    grid-template-columns: 0.1fr 0.2fr 1fr 0.5fr 0.5fr 0.5fr 1fr 0.2fr 0.2fr 0.2fr 0.2fr;
    list-style: none;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    width: 100%;
    box-shadow: 1px 1px 4px $gray-tree;
    border-radius: 10px;
    &:hover {
      background-color: rgba(128, 128, 128, 0.075);
    }
    .content-table-ul-li {
      @include displayFlex(row, flex-start, center);
      padding: 1rem;
      text-align: start;
      font-weight: 300;
      color: $gray;
      .icon-hicheck-element {
        color: $green;
        border-radius: 50%;
        padding: 0.1rem;
        transition: all 0.1s ease-in-out;
        &:hover {
          background-color: $green;
          color: white;
        }
      }
      &.status-icon-element {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.status-icon-element {
        margin-left: 1rem;
      }
      &:nth-child(1)::before {
        display: none;
      }
      &:nth-child(2)::before {
        display: none;
      }

      &:nth-child(6)::before {
        display: none;
      }
      &:nth-child(7)::before {
        display: none;
      }
      &:nth-child(8)::before {
        display: none;
      }
      &:nth-child(9)::before {
        display: none;
      }
      &:nth-child(10)::before {
        display: none;
      }
      &::before {
        position: relative;
        content: '';
        left: -10px;
        width: 1px;
        height: 50px;
        background-color: $gray-for;
        z-index: 1;
      }
      .icon-content {
        font-size: 3rem;
        color: $green;
        &.pdf-icon {
          color: crimson;
        }
        &.img-icon {
          color: rgb(0, 155, 226);
        }
        &.img-docx {
          color: rgb(0, 73, 156);
        }
      }
      h2 {
        font-size: 1.3rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
  }
}

@media (max-width: 610px) {
  .content-table-element-student-report {
    width: 98% !important;
    .content-table-ul {
      .content-table-ul-li {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          display: none;
        }
        .icon-content {
          font-size: 2rem !important;
        }
        padding: 0.5rem !important;
        &::before {
          display: none;
        }
        h2 {
          font-size: 1rem !important;
        }
      }
    }
  }
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.config-student-exams {
  @include displayFlex(column, start, center);
  position: fixed;
  overflow: hidden;
  width: 600px;
  height: 200px;
  padding-bottom: 2rem;
  margin-top: 4rem;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 1px 4px $gray-tree;
  background-color: #fff;
  border-radius: 4px;
  z-index: 9999;
  &.display-false {
    display: none;
  }
  .config-student-exam-header {
    @include displayFlex(row, space-between, center);
    width: 100%;
    padding: 1rem 3rem;
    margin-bottom: 2rem;
    color: $font-color-primary;
    background-color: #13975a;

    h3 {
      font-size: 1.7rem;
      padding: 0rem 2rem;
      color: $font-color-primary;
    }

    .icon-content {
      cursor: pointer;
      font-size: 2rem;
    }
  }
  .config-student-exam-input-container {
    width: 90%;
    margin-top: 3rem;
    .input-content {
      width: 100%;
      @include displayFlex(row, start, center);
      h3 {
        font-size: 1.5rem;
        margin-right: 2rem;
      }
      .button-exam-visibility {
        position: relative;
        width: 60px;
        border: none;
        border-radius: 3px;
        .icon-move-center {
          position: relative;
          top: 0px;
          left: 0px;
          width: 30px;
          height: 20px;
          background-color: red;
          border-radius: 5px;
          transition: all 0.1s ease-in-out;
        }
        &.display-true {
          justify-content: end;
          .icon-move-center {
            left: 30px;
            background-color: $green;
          }
        }
      }
    }
  }
}

@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.search-element-add-form {
    position: relative;
    @include displayFlex(column, center, center);
    width: 90%;
    margin: 2rem 0rem;
    .header-input-content {
        @include displayFlex(row, flex-start, center);
        width: 80%;
        margin: 2rem 0rem;
        font-size: 1.7rem
    }
    .input-content {
        @include displayFlex(column, flex-start, start);
        width: 80%;
        padding: 1rem 0.2rem;
        height: 20px;
        border-bottom: 1px solid $green;
        .error-element {
          position: relative;
          top: 20px;
          right: 0px;
        }
        .search-element {
            width: 20px;
            color: $gray-tree;
        }
        input {
            width: 300px;
            border: none;
            outline: none;
            font-size: 1.5rem;
        }
    }
    .options-list {
        overflow: auto;
        position: absolute;
        top: 120px;
        width: 80%;
        max-height: 100px;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 1px 1px 3px $gray-for;
        z-index: 3;
        &::-webkit-scrollbar {
            width: 5px;
            padding-right: 0.5rem;
          }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
        .list-element {
            list-style: none;
            li {
                @include displayFlex(row, space-between, center);
                cursor: pointer;
                padding: 1rem;
                font-size: 1.5rem;
                &:hover {
                    background-color: $gray-for;
                }
            }
        }
    }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-workshop-monthly-plan {
    position: relative;
    @include displayFlex(column, flex-start, center);
    width: 100%;
    height: 100%;
    h1 {
        padding: 2rem 0rem;
    }
    .title-table {
        text-align: start;
        font-style: italic;
        font-size: 1.6rem;
        width: 90%;
    }
    .btn-create-new-coordinator-container {
        @include displayFlex(row, space-between, center);
        width: 90%;
        button {
            cursor: pointer;
            margin-bottom: 2rem;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary; 
        }
    }
    .search-filter-content {
      position: relative;
      @include displayFlex(row, flex-start, center);
      width: 90%;
      h2 {
        margin: 0rem 1rem;
      }
      .date-container {
        @include displayFlex(row, flex-start, center);
        margin-left: 1rem;
        .search-input-content {
          &:nth-child(3) {
            margin-left: 0rem;           
          }
        }
      }
      .input-content {
          position: relative;
          @include displayFlex(row, center, center);
          overflow: hidden;
          border-radius: 10px;
          box-shadow: 1px 1px 3px $gray-for;
          margin-left: 2rem;
          padding: 0.7rem;
          &:nth-child(1) {
              margin-left: 0px;
          }
          &:nth-child(4) {
              margin-left: 0px;
          }
          input {
              width: 200px;
              outline: none;
              border: none;
              font-size: 1.5rem;
          }
          &.input-data {
            width: 120px;
          }
      }
      .search-input-content {
          @include displayFlex(row, center, center);
      }
      .search-date-input-content {
          margin-left: 3rem;
          label {
              font-size: 1.5rem;
          }
          input {
              width: 60px;
              margin-left: 1rem;
          }
      }
      .submit-form-button-content {
          margin-left: 3rem;
          button {
              cursor: pointer;
              padding: 0.7rem 1.2rem;
              border-radius: 5px;
              background-color: transparent;
              border: none;
              background-color: $green;
              color: $font-color-primary;
          }
      }
    }
    .header-table-element {
        width: 90%;
        margin-top: 1rem;
        border-top: 2px solid $green;
        border-radius: 5px;
        box-shadow: 1px 1px 3px $gray-for;
        .header-table-ul {
            display: grid;
            grid-template-columns: 0.1fr 0.2fr 1fr 0.5fr 0.5fr 1fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
            list-style: none;
            .header-table-ul-li {
                text-align: start;
                font-size: 1.5rem;
                padding: 1rem;
                &.visualization-element {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                &.status-icon-element {
                    padding: 1rem;
                }
            }
        }
    }
    .main-table {
        overflow-y: auto;
        @include displayFlex(column, flex-start, center);
        width: 100%;
        padding: 1rem 0rem;
        max-height: 54vh;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $green;
            border-radius: 20px;
        }
    }
}

@media (max-width: 600px) {
  .table-container-workshop-monthly-plan {
    .search-filter-content {
      @include displayFlex(column, flex-end, start);
      .input-content {
        margin-left: 0px;
        margin-top: 1rem
      }
      .date-container {
        @include displayFlex(row, flex-start, center);
        width: 100%;
        margin-top: 1rem;
        .input-data {
          margin-left: 0px;
        }
      }
      .submit-form-button-content {
        width: 100%;
        margin-left: 0px;
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .table-container-workshop-monthly-plan {
    .btn-create-new-coordinator-container {
      width: 98%;
    }
    .search-filter-content {
      width: 98%;
    }
    .header-table-element {
      width: 98%;
      .header-table-ul {
        .header-table-ul-li {
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
  }
}
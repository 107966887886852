@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.nav-component-strategy-of-plans {
    background-color: $gray;
    width: 250px;
    height: 100vh;
    .action-title {
        @include displayFlex(column, center, center);
        padding: 1.5rem;
        text-align: center;
        background-color: $gray-two;
        color: $font-color-primary;
        h1 {
            margin-bottom: 1rem;
        }
        p {
            font-size: 1.3rem;
        }
    }
    .ul-option {
            text-decoration: none;
            li {
                @include displayFlex(row, flex-start, center);
                width: auto;
                position: relative;
                padding: 1rem;
                h1 {
                    width: fit-content;
                    margin-left: 1rem;
                    font-weight: 500;
                    font-size: 1.4rem;
                    color: $font-color-primary;
    
                }
                &:hover {
                    cursor: pointer;
                    background-color: $gray-two;
                    &::before {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        float: left;
                        content: '';
                        width: 3px;
                        height: 100%;
                        background-color: $green;
                    }
                }
            }
        }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-elementary-container-digital-httpi {
  position: relative;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  z-index: 3;
  .header-digital-httpi {
    @include displayFlex(column, start, start);
    width: 90%;
    h1 {
      padding-left: 0rem;
      text-align: start;
      font-style: italic;
      font-size: 1.6rem;
      width: 90%;
    }
  }
  .btn-create-new-student-container {
      @include displayFlex(row, flex-start, center);
      width: 90%;
      button {
          cursor: pointer;
          margin-bottom: 2rem;
          padding: 0.5rem 1rem;
          border-radius: 5px;
          background-color: transparent;
          border: none;
          background-color: $green;
          color: $font-color-primary; 
      }
  }
  .header-table-element {
      border-top: 2px solid $green;
      border-radius: 5px;
      margin-top: 1rem;
      width: 90%;
      box-shadow: 1px 1px 3px $gray-for;
      .header-table-ul {
          display: grid;
          grid-template-columns: 0.3fr 2fr 1fr 2fr 0.4fr 0.5fr 0.5fr;
          list-style: none;
          .header-table-ul-li {
              text-align: start;
              font-size: 1.5rem;
              padding: 1rem 1rem;
              &.visualization-content {
                @include displayFlex(row, center, center);
              }
          }
      }
  }
  .search-digital-httpi-content {
    @include displayFlex(row, start, center);
    width: 90%;
    .input-date-content {
      @include displayFlex(row, center, center);
      margin-left: 4rem;
      h2 {
        padding: 0rem 2rem;
      }
      .date-content {
        @include displayFlex(row, flex-start, center);
      }
      .input-content {
        input {
          border: none;
          padding: 0.8rem 1rem;
          outline: none;
          border-radius: 5px;
          font-size: 1.5rem;
          background-color: #fff;
          box-shadow: 1px 1px 4px $gray-tree;
        }
        &:nth-child(2) {
          margin-left: 2rem;
        }
      }
      .button-content {
        button {
          cursor: pointer;
          margin-left: 1.5rem;
          padding: 0.8rem 1rem;
          border: 0px;
          border-radius: 5px;
          font-size: 1.5rem;
          color: #fff;
          background-color: $green;
        }
      }
    }
  }
  .main-table {
      overflow-y: auto;
      @include displayFlex(column, flex-start, center);
      width: 100%;
      padding: 1rem 0rem;
      height: 49vh;
      &::-webkit-scrollbar {
          width: 12px;
      }
      &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
      }
  }
}

@media (max-width: 800px) {
  .table-elementary-container-digital-httpi {
    overflow-x: hidden;
    .header-digital-httpi {
      width: 95%;
    }
    .btn-create-new-student-container {
      width: 95%;
    }
    .search-digital-httpi-content {
      @include displayFlex(column, center, start);
      width: 95%;
      .input-date-content {
        @include displayFlex(column, center, start);
        margin: 0px;
        margin-top: 1rem;
        .input-content {
          width: 30%;
          input {
            width: 90%;
          }
        }
        .button-content {
          margin-top: 1rem;
          button {
            margin: 0px;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
    .header-table-element {
      width: 98%;
      .header-table-ul {
        .header-table-ul-li {
          &:nth-child(1) {
            display: none;
          }
          font-size: 1.5rem;
        }
      }
    }
  }
}
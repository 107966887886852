@import '../../config/style/style.scss';

.header-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0rem 1rem;
  background-color: $green;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(102, 102, 102, 1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(102, 102, 102, 1);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.116));
  h1 {
    padding: 2rem;
    color: $font-color-primary;
  }
  z-index: 99999;
  img {
    width: 55px;
    height: 55px;
    &:nth-child(1) {
      width: 59px;
      height: 59px;
    }
  }
}

@media (max-width: 600px) {
  .header-component {
    h1 {
      font-size: 1.2rem;
    }
    img {
      &:nth-child(1) {
        display: none;
      }
      width: 40px;
      height: 40px;
    }
  }
}
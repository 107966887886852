@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';
.notification-table-container {
  @include displayFlex(column, center, center);
  .header-content {
    width: 95%;
    margin-top: 2rem;
    .notification-content {
      text-align: start;
      font-style: italic;
      font-size: 1.6rem;
    }
    .button-content {
      margin-top: 2rem;
      .button-submit {
        cursor: pointer;
        padding: 0.7rem;
        border-radius: 5px;
        border: none;
        font-size: 1.3rem;
        background-color: $green;
        color: #fff;
      }
    }
  }
  .notification-search-content {
    @include displayFlex(row, flex-start, center);
    width: 95%;
    margin-top: 2rem;
    h3 {
      @include displayFlex(row, center, center);
      margin: 0rem 1rem;
      font-size: 1.5rem;
    }
    .notification-input-content {
      input {
        outline: none;
        padding: 0.6rem;
        border: none;
        border-radius: 10px;
        box-shadow: 1px 1px 3px $gray-for;
      }
    }
    .submit-button-content {
      margin-left: 1rem;
      button {
        background-color: $green;
        padding: 0.6rem 1rem;
        color: #fff;
        border-radius: 3px;
        font-size: 1.3rem;
        border: none;
      }
    }
  }
  .notification-table-content {
    width: 95%;
    margin-top: 3rem;
    .notification-table-content-header {
      border-top: 2px solid $green;
      border-radius: 5px;
      box-shadow: 1px 1px 3px $gray-for;
      .notification-table-content-header-list {
        display: grid;
        grid-template-columns: 0.3fr 1.5fr 1fr 1fr 1fr 0.3fr 0.3fr 0.3fr;
        list-style: none;
        .notification-table-content-header-li {
          h1 {
            text-align: start;
            font-size: 1.4rem;
            font-weight: 200;
            padding: 1rem;
          }
          &.notification-visualization-content {
            h1 {
              text-align: center;
            } 
          }
        }
      }
    }
    .notification-table {
      overflow-y: scroll;
      width: 100%;
      height: 56vh;
      padding: 0.3rem;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.table-container-elementary-school-weekly-plan {
  position: relative;
  @include displayFlex(column, flex-start, center);
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  h1 {
      padding: 2rem 0rem;
  }
  .title-table {
      text-align: start;
      font-style: italic;
      font-size: 1.6rem;
      width: 90%;
  }
  .btn-create-new-coordinator-container {
      @include displayFlex(row, space-between, center);
      width: 90%;
      button {
          cursor: pointer;
          margin-bottom: 2rem;
          padding: 0.5rem 1rem;
          border-radius: 5px;
          background-color: transparent;
          border: none;
          background-color: $green;
          color: $font-color-primary; 
      }
  }
  .search-filter-content {
    position: relative;
    @include displayFlex(row, flex-start, center);
    width: 90%;
    h2 {
      margin: 0rem 1rem;
    }
    .input-content {
        position: relative;
        @include displayFlex(row, center, center);
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 1px 1px 3px $gray-for;
        padding: 0.7rem;
        &:nth-child(2) {
            margin-left: 1rem;
        }
        input {
            width: 200px;
            outline: none;
            border: none;
            font-size: 1.5rem;
        }
        &.input-data {
          width: 120px;
        }
    }
    .date-container {
      @include displayFlex(row, center, center);
      margin-left: 1rem;
      input {
        margin: 0px;
      }
      .search-date-input-content {
        margin: 0px;  
        label {
              font-size: 1.5rem;
          }
          input {
              width: 60px;
          }
      }
    }
    .search-input-content {
        @include displayFlex(row, center, center);
    }
    .submit-form-button-content {
        margin-left: 3rem;
        button {
            cursor: pointer;
            padding: 0.7rem 1.2rem;
            border-radius: 5px;
            background-color: transparent;
            border: none;
            background-color: $green;
            color: $font-color-primary;
        }
    }
  }
  .header-table-element {
      width: 90%;
      border-radius: 5px;
      margin-top: 2rem;
      border-top: 2px solid $green;
      box-shadow: 1px 1px 3px $gray-for;
      .header-table-ul {
          display: grid;
          grid-template-columns: 0.1fr 0.2fr 1fr 0.5fr 0.5fr 1fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
          list-style: none;
          .header-table-ul-li {
              text-align: start;
              font-size: 1.5rem;
              padding: 1rem;
              &.visualization-element {
                display: flex;
                justify-content: center;
                align-items: center;
              }
              &.status-icon-element {
                  padding: 1rem;
              }
          }
      }
  }
  .main-table {
      overflow-y: auto;
      @include displayFlex(column, flex-start, center);
      width: 100%;
      padding: 1rem 0rem;
      max-height: 51vh;
      &::-webkit-scrollbar {
          width: 10px;
      }
      &::-webkit-scrollbar-thumb {
          background-color: $green;
          border-radius: 20px;
      }
  }
}

@media (max-width: 930px) {
  .table-container-elementary-school-weekly-plan {
    .title-table {
      width: 95%;
    }
    .btn-create-new-coordinator-container {
      width: 95%;
    }
    .search-filter-content {
      width: 95%;
      @include displayFlex(column, center, start);
      .input-content {
        margin: 0px;
        &:nth-child(2) {
          margin: 0px;
          margin-top: 1rem;
        }
      }
      .date-container {
        margin: 0px;
        margin-top: 1rem;
      }
      .submit-form-button-content {
        margin: 0px;
        margin-top: 1rem;
        button {
          padding: 0.5rem 1rem;
        }
      }
    }
    .header-table-element {
      width: 98%;
      .header-table-ul {
        .header-table-ul-li {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: none;
          }
          font-size: 1.5rem;
        }
      }
    }
    .main-table {
      max-height: 45vh;
    }
  }
}
@import '../../../../../config/style/mixins.scss';
@import '../../../../../config/style/style.scss';

.textual-genre-tag-table {
  @include displayFlex(column, flex-start, center);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 90vh;
  z-index: 9999;
  background-color: #fff;
  button {
    cursor: pointer;
    padding: 0.5rem 1rem;
    background-color: $green;
    color: #fff;
    border-radius: 5px;
    border: none;
    font-size: 1.5rem;
    margin-left: 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
  .textual-genre-tag-table-header {
    width: 90%;
    margin-top: 0rem;
    margin-top: 1rem;
    .textual-genre-tag-table-header-title {
      display: flex;
      justify-content: space-between;
      h1 {
        font-style: italic;
      }

      .textual-genre-tag-table-header-button {
        margin-top: 1rem;
      }
    }
  }
  &.display-false {
    display: none;
  }
  .textual-genre-tag-table-main {
    @include displayFlex(column, flex-start, center);
    width: 100%;
    margin-top: 2rem;
    .textual-genre-tag-table-main-title {
      width: 90%;
      border-top: 2px solid $green;
      box-shadow: 1px 1px 3px $gray-for;
      border-radius: 5px;
      ul {
        display: grid;
        grid-template-columns: 0.3fr 1fr 1fr 1fr 0.3fr 0.3fr;
        list-style: none;
        li {
          padding: 1rem;
          font-size: 1.5rem;
          &.color-content {
            @include displayFlex(row, center, center);
          }
        }
      }
    }
    .textual-genre-tag-table-main-elements {
      @include displayFlex(column, flex-start, center);
      overflow-y: scroll;
      width: 100%;
      height: 70vh;
      padding-bottom: 1rem;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}

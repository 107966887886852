@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';

.content-table-element-digital-httpi {
    width: 90%;
    margin-top: 1rem;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    &.display-animation-false {
      opacity: 0;
    }
    .content-table-ul {
        cursor: pointer;
        display: grid;
        grid-template-columns: 0.3fr 2fr 1fr 2fr 0.4fr 0.5fr 0.5fr;
        list-style: none;
        justify-content: center;
        align-items: center;
        min-height: 50px;
        width: 100%;
        box-shadow: 1px 1px 4px $gray-tree;
        border-radius: 10px;
        &:hover {
            background-color: rgba(128, 128, 128, 0.075);
        }
        .content-table-ul-li {
            @include displayFlex(row, flex-start, center);
            padding: 1rem;
            text-align: start;
            font-weight: 300;
            color: $gray;
            &.status-icon-element {
              display: flex;
              justify-content: center;
              align-items: center;
          }
            .checked-icon {
              color: $green;
              border-radius: 50%;
              padding: 0.1rem;
              transition: all 0.1s ease-in-out;
              &#icon-hicheck-element:hover {
                color: #fff !important;
                background-color: $green;
              }
            }
            &.status-icon-element {
                margin-left: 1rem;
            }
            &:nth-child(4)::before {
                display: none;
            }
            &:nth-child(5)::before {
                display: none;
            }
            &:nth-child(6)::before {
                display: none;
            }
            &::before {
                position: relative;
                content: '';
                left: -10px;
                width: 1px;
                height: 30px;
                background-color: $gray-for;
                z-index: 1;
            }
            .icon-content {
                font-size: 3rem;
                color: $green;
                &.pdf-icon {
                    color: crimson;
                }
                &.img-icon {
                    color: rgb(0, 155, 226);
                }
                &.img-docx {
                    color: rgb(0, 73, 156);
                }
            }
            h2 {
                font-size: 1.3rem;
            }
            p {
                font-size: 1.3rem;
            }
        }
    }
}

@media (max-width: 600px) {
  .content-table-element-digital-httpi {
    width: 98%;
    .content-table-ul {
      li {
        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
}
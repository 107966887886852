@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.secretary-new-writing-hypothesis-table-content {
  cursor: pointer;
  width: 100%;
  box-shadow: 1px 1px 3px $gray-for;
  padding: 0.5rem 0rem;
  border-radius: 10px;
  margin-top: 1rem;
  ul {
    display: grid;
    grid-template-columns: 0.5fr 3fr 3fr 2fr 0.5fr;
    align-items: center;
    list-style: none;
    li {
      font-size: 1.4rem;
      padding: 1rem;
      .plus-icon {
        color: $green;
        font-size: 3rem;
      }
    }
  }
  &:hover {
    background-color: #f8f8f8;
  }
}


@import '../../config/style/style.scss';
@import '../../config/style/mixins.scss';
.login-container {
  @include displayFlex(row, space-between, flex-start);
  width: 800px;
  height: 500px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.075);
  background-color: #fff;
  .form-login-content {
    @include displayFlex(row, center, center);
    width: 400px;
    height: 100%;
  }
  .img-login-content {
    @include displayFlex(row, flex-start, center);
    width: 50%;
    height: 100%;
    img {
      width: 400px;
    }
  }
}
@media (max-width: 800px) {
  .login-container {
    @include displayFlex(row, center, center);
    width: 100%;
    .img-login-content {
      display: none;
    }
  }
}

@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.dashboard-container-writing-level-coordinator {
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  position: absolute;
  top: -10px;
  width: 100%;
  height: 86vh;
  background-color: #ffff;
  z-index: 999;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  &.display-false {
    display: none;
  }
  .dashboard-container-writing-level-coordinator-header {
    @include displayFlex(row, flex-start, center);
    margin-bottom: 4rem;
    padding-left: 1rem;
    border-top: 3px solid $green;
    box-shadow: 1px 1px 4px $gray-tree;
    border-radius: 4px;
    button {
      cursor: pointer;
      padding: 0.7rem 1.2rem;
      border-radius: 5px;
      background-color: transparent;
      border: none;
      background-color: $green;
      color: $font-color-primary;
      &.compare-button {
        margin-left: 4rem;
      }
    }
    .input-content {
      padding: 1rem;
      @include displayFlex(column, flex-start, flex-start);
      position: relative;
      width: 130px;
      margin-left: 5rem;
      &.display-false {
        display: none;
      }
      &.input-content-tag {
        margin: 0px;
      }
      .input-container-element {
        cursor: pointer;
        @include displayFlex(row, flex-start, center);
        input {
          cursor: pointer;
          width: 100px;
          border: none;
          font-size: 1.5rem;
          background-color: transparent;
        }
        .icon-arrow {
          color: $gray-tree;
          transition: all 0.2s ease-in-out;
          &.display-true {
            transform: rotate(180deg);
          }
          z-index: 3;
        }
      }
      .list-option-content {
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: auto;
        top: 45px;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 1px 1px 4px $gray-tree;
        list-style: none;
        z-index: 3;
        &.display-false {
          height: 0px;
        }
        li {
          cursor: pointer;
          padding: 0.5rem 1rem;
          font-size: 1.5rem;
          &:hover {
            background-color: $gray-for;
          }
        }
      }
    }
    .to-compare-button-content {
      @include displayFlex(row, center, center);
      button {
        cursor: pointer;
        margin-left: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        background-color: transparent;
        border: none;
        background-color: $green;
        color: $font-color-primary;
      }
    }
    .to-compare-input-container {
      @include displayFlex(row, flex-start, center);
      margin-left: 0rem;
      h1 {
        width: 90px;
        padding-right: 0px;
        padding-left: 0.5rem;
        font-size: 1.3rem;
      }
      .input-content-to-compare {
        margin-left: 0rem;
      }
      &.display-false {
        display: none;
      }
      &:nth-child(2) {
        margin-left: 0px;
      }
    }
  }
  .dashboard-container-writing-level-coordinator-main {
    width: 98%;
    height: 82vh;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
    .dashboard-container {
      @include displayFlex(row, center, center);
    }
  }
}


@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.form-edit-textual-genre {
  overflow: hidden;
  position: fixed;
  top: 200px;
  width: 500px;
  height: 200px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 4px $gray-tree;
  &.display-false {
    display: none;
  }
  .header-form-edit-form-edit-textual-genre {
    display: flex;
    justify-content: space-between;
    padding: 0.8rem;
    background-color: $green;
    color: #fff;
    h1 {
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: 1.5rem;
      padding: 0rem;
    }
    .icon-close {
      cursor: pointer;
    }
  }
  .body-form-edit-textual-genre {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 70%;
    .form-edit-textual-genre-input-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      margin-top: 1.5rem;
      label {
        font-size: 1.8rem;
      }
      input {
        margin-top: 1rem;
        font-size: 1.5rem;
        padding: 0.5rem 1rem;
        outline: none;
        border: none;
        border-bottom: 1px solid $green;
      }
    }
    .form-edit-textual-genre-button-content {
      display: flex;
      justify-content: end;
      width: 90%;
      .button-element {
        cursor: pointer;
        padding: 0.5rem 1rem;
        background-color: $green;
        color: #fff;
        border: none;
        border-radius: 5px;
        &.button-cancel {
          margin-right: 1rem;
          background-color: transparent;
          color: #000;
          border: 1px solid $green;
        }
      }
    }
  }
  z-index: 9999;
} 
@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.new-writing-hypothesis-table {
  @include displayFlex(column, flex-start, center);
  width: 100%;
  .button {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: none;
    font-size: 1.5rem;
    border-radius: 5px;
    background-color: $green;
    color: #fff;
  }
  .new-writing-hypothesis-table-header {
    width: 90%;
    h1 {
      font-size: 2rem;
      font-style: italic;
      padding: 2rem 0rem;
    }
  }
  .new-writing-hypothesis-table-middle {
    @include displayFlex(row, space-between, center);
    width: 90%;
    .new-writing-hypothesis-table-middle-result {
      @include displayFlex(row, center, center);
      background-color: $gray-for;
      border-radius: 5px;
      h1 {
        padding: 0.5rem;
        font-size: 1.5rem;
        font-weight: 400;
        color: black;
      }
    }
    .input-select {
      display: flex;
      margin-top: 1rem;
      .input-content-list-options-y {
        top: 50px;
      }
    }
    .button-content {
      @include displayFlex(row, start, center);
      .button {
        &:nth-child(2) {
          margin-left: 2rem;
        }
      }
    }
  }
  .new-writing-hypothesis-table-body {
    @include displayFlex(column, center, center);
    width: 100%;
    .new-writing-hypothesis-table-body-header {
      width: 90%;
      border-radius: 5px;
      border-top: 2px solid $green;
      box-shadow: 1px 1px 3px $gray-for;
      ul {
        display: grid;
        grid-template-columns: 0.3fr 3fr 1fr;
        list-style: none;
        li {
          font-size: 1.5rem;
          padding: 1rem;
        }
      }
    }
    .new-writing-hypothesis-table-body-table {
      @include displayFlex(column, start, center);
      overflow-y: scroll;
      width: 90%;
      height: 70vh;
      padding-bottom: 1rem;
      .img-table-empty-container {
        @include displayFlex(column, center, center);
        width: 90%;
        .img-table-empty {
          margin-top: 1.5rem;
        }
        h1 {
          font-size: 1.8rem;
          font-weight: 300;
          color: $green;
        }
      }        
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}
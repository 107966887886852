@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';
.new-writing-hypothesi-graph {
  @include displayFlex(column, flex-start, center);
  overflow-y: auto;
  width: 100%;
  height: 90vh;
  .button {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: none;
    font-size: 1.5rem;
    border-radius: 5px;
    background-color: $green;
    color: #fff;
  }
  .new-writing-hypothesi-graph-header {
    width: 90%;
    h1 {
      font-size: 2rem;
      font-style: italic;
      padding: 2rem 0rem;
    }
  }
  .new-writing-hypothesi-graph-middle {
    @include displayFlex(row, space-between, center);
    width: 90%;
    .input-select {
      display: flex;
      margin-top: 1rem;
      .input-content-list-options-y {
        top: 50px;
      }
    }
  }
  .new-writing-hypothesi-graph-content {
    img {
      animation: 0.5s ease-in-out entryImg ;
      width: 700px;
      @keyframes entryImg {
        0% {
          width: 650px;
          opacity: 0;
        }
        100% {
          width: 700px;
          opacity: 1;
        }
      }
    }
    .graphic-content {
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      animation: 0.5s ease-in-out entryGraph;

      &.display-true {
        opacity: 1;
      }
    
      @keyframes entryGraph {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  .new-writing-hypothesi-graph-content-info {
    width: 50%;
    margin-top: 4rem;
    h1 {
      margin: 3rem 0rem;
    }
    opacity: 0;
    &.display-true {
      opacity: 1;
    }
    .new-writing-hypothesi-graph-content-info-list {
      list-style: none;
      li {
        @include displayFlex(row, flex-start, center);
        margin-top: 1rem;
        span {
          margin-left: 1rem;
          font-size: 2rem;
        }
      }
    }
  }
}
@import '../../../../config/style/style.scss';
@import '../../../../config/style/mixins.scss';

.writing-hypothesis-table-add-textual-genre {
  @include displayFlex(column, center, center);
  position: relative;
  .title-add-textual-genre-table {
    width: 90%;
    font-size: 2rem;
    font-style: italic;
    margin-top: 2rem;
  }

  .btn-add-textual-genre-table {
    width: 90%;
    margin-top: 2rem;
    button {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      background-color: $green;
      font-size: 1.5rem;
      border: none;
      color: #fff;
    }
  }
  .add-textual-genre-table-content {
    width: 90%;
    margin-top: 2rem;
    .add-textual-genre-table-title {
      border-top: 2px solid $green;
      box-shadow: 1px 1px 3px $gray-for;
      border-radius: 5px;
      ul {
        display: grid;
        grid-template-columns: 0.3fr 2fr 1fr 0.3fr 0.3fr;
        list-style: none;
        li {
          font-size: 1.4rem;
          padding: 1rem;
        }
      }
    }
    .add-textual-genre-table-main {
      @include displayFlex(column, start, center);
      overflow-y: scroll;
      width: 100%;
      height: 70vh;
      padding-bottom: 1rem;
      padding-bottom: 1rem;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}
@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.student-exams-response {
  @include displayFlex(column, start, center);
  overflow-y: auto;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: #ffF;
  z-index: 999;
  .exam-student-response-information {
    overflow: hidden;
    position: fixed;
    width: 600px;
    height: 400px;
    margin-top: 2rem;
    background-color: white;
    box-shadow: 1px 1px 3px $gray-for;
    border-radius: 5px;
    .test-answer-sheet-student-name {
      margin-left: 3rem;
      margin-top: 4rem
    }
    .exam-student-response-information-header {
      @include displayFlex(row, space-between, center);
      padding: 1rem;
      background-color: $green;
      h1 {
        color: #fff;
        font-size: 1.5rem;
      }
    }
    .test-answer-sheet-container {
      overflow-y: auto;
      padding: 3rem 4rem;
      max-height: 250px;
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      .test-answer-sheet-element {
        margin-top: 1rem;
        .test-answer-sheet-header {
          h1 {
            font-size: 2rem;
          }
        }
        .test-answer-sheet {
          h1 {
            padding: 0.3rem 1rem;
            margin-top: 1rem;
            font-size: 1.6rem;
            color: $gray;
          }
        }
        &.correct {
          .test-answer-sheet {
            h1 {
              background-color: #00ff003d;
              border-radius: 5px;
            }
          }
        }
        &.not-correct {
          .test-answer-sheet {
            h1 {
              background-color: rgba(255, 0, 0, 0.171);
              border-radius: 5px;
            }
          }
        }       
      }
    }
    &.display-false {
      display: none;
    }
  }


  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  &.display-false {
    display: none;
  }
  .student-exams-response-header {
    @include displayFlex(row, space-between, center);
    margin-top: 2rem;    
    width: 90%;
    h1 {
      font-style: italic;
    }
    .btn-container {
      button {
        cursor: pointer;
        padding: 0.8rem 1.5rem;
        font-size: 1.5rem;
        background-color: $green;
        border: none;
        border-radius: 3px;
        color: #fff;
        margin-right: 3rem;
        &:nth-last-child(1) {
          margin-right: 0px;
        }
      }
    }
  }
  .student-exams-response-table {
    width: 90%;
    margin-top: 3rem;
    margin-bottom: 2rem;
    border: 1px solid $green;
    .student-exams-response-table-header {
      background-color: $green;
      width: 100%;
      ul {
        display: grid;
        grid-template-columns: 2fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
        list-style: none;
        width: 100%;
        li {
          padding: 1rem;
          font-size: 1.5rem;
          color: #fff;
        }
      }
    }
    .student-response-content {
      display: grid;
      grid-template-columns: 2fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr;
      list-style: none;
      width: 100%;
      border-bottom: 1px solid $green;
      &:nth-last-child(1) {
        border: none;
      }
      &:hover {
        cursor: pointer;
        background-color: #ececec;
      }
      li {
        font-size: 1.5rem;
        padding: 2rem 1rem;
        border-left: 1px solid $green;
        &:nth-child(1) {
          border: none;
        }
      }
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
html {
    font-size: 62.5%;
}

body {
    overflow-y: hidden;
}

* {
  font-family: sans-serif;
  padding: 0px;
  margin: 0px;
  .error-element {
    padding: 0.6rem 1rem;
    font-size: 1.5rem;
    border-radius: 5px;
    width: 100%;
    margin-top: 0.5rem;
    color: rgb(255, 0, 0);
    background-color: #ff00002a;
  }
}
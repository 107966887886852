@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.medical-record-by-student {
  @include displayFlex(column, flex-start, center);
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
  background-color: #fff;
  &.display-false {
    display: none;
  }
  .button-content {
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    border: none;
    border-radius: 3px;
    background-color: $green;
    color: #fff;
  }
  .medical-record-by-student-header {
    @include displayFlex(row, space-between, start);
    width: 90%;
    margin-top: 2rem;
    h1 {
      font-size: 1.7rem;
      font-style: italic;
    }
  }
  .medical-record-by-student-nav {
    width: 90%;
  }
  .medical-record-table {
    overflow-y: auto;
    width: 90%;
    height: 70vh;
    margin-top: 3rem;
    .medical-record-table-header {
      .medical-record-table-header-list {
        display: grid;
        grid-template-columns: 1fr 2fr;
        list-style: none;
        background-color: $green;
        h1 {
          font-size: 1.5rem;
          padding: 1rem;
          color: #fff;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
      border-radius: 20px;
    }
  }
}
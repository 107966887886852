@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.student-info-psychologist {
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  @include displayFlex(column, flex-start, center);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  padding: 0rem;
  background-color: #fff;
  z-index: 99999;
  &.display-false {
    display: none;
  }
  .button-content {
    @include displayFlex(row, space-between, center);
    width: 90%;
    margin-top: 1rem;
    button {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 3px;
      background-color: $green;
      color: #fff;
    }
  }
  .crp-input-content {
    width: 90%;
    margin-top: 2rem;

    label {
      font-size: 1.6rem;
      margin-right: 1rem;
    }
    input {
      border: none;
      outline: none;
      border-bottom: 1px solid $green;
      padding: 0.5rem 1rem;
    }
  }
  .container-table-info-psychologist {
    width: 70%;
    margin-top: 2rem;
    .table-student-info-psychologist {
      width: 100%;
      border-collapse: collapse;
      .table-header-student-info-psychologist {
        border-bottom: 5px solid black;
        
        h1 {
          width: 100%;
          text-align: center;
          font-size: 1.8rem;
          padding: 1.5rem;
          color: #000000;
        }
      }
      .table-body-student-inf-psychologist {
        .td-table-student-info-psychologist {
          border-top: 0px;
          .div-title-inf-student-psychologist {
            // border-top: 5px solid black;
            padding: 1rem;
            background-color: #00000021;
            h1 {
              width: 100%;
              text-align: start;
              font-size: 2rem;
              font-weight: 200;
            }
          }
          &:nth-child(1) {
            .div-title-inf-student-psychologist {
              border: none;
            }
          }
          ul {
            padding: 2rem;
            list-style: none;
            li {
              margin-top: 2rem;
              h1 {
              font-size: 1.7rem;
                span {
                  color: rgb(48, 48, 48);
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

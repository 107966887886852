@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.student-exams-form-add-element {
  @include displayFlex(row, center, start);
  position: fixed;
  top: 100px;
  width: 100%;
  height: 100vh;
  .student-exams-form-add-element-content {
    overflow: hidden;
    position: absolute;
    width: 600px;
    padding-bottom: 2rem;
    margin-top: 4rem;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 1px 1px 4px $gray-tree;
    background-color: #fff;
    border-radius: 4px;
    .student-exams-form-add-element-header {
      @include displayFlex(row, space-between, center);
      padding: 1rem 3rem;
      margin-bottom: 2rem;
      color: $font-color-primary;
      background-color: #13975a;
      h1 {
        font-size: 1.7rem;
        padding: 0px;
        color: $font-color-primary;
      }
      svg {
        cursor: pointer;
      }
      z-index: 9999;
    }
    .form-content {
      @include displayFlex(column, space-between, center);
      overflow-y: auto;
      height: 400px;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
      .input-content {
        width: 90%;
        .input-element {
          margin-top: 4rem;
          input {
            cursor: pointer;
            display: none;
          }
          img {
            width: 300px;
          }
          &.img-input {
            @include displayFlex(row, center, center);
            width: 90%;
            color: #0084ff;
            margin-bottom: 2rem;
          }
          &.display-false {
            display: none;
          }
        }
      }
      .button-content {
        @include displayFlex(row, end, center);
        width: 90%;
        button {
          cursor: pointer;
          background-color: $green;
          color: #fff;
          padding: 0.5rem 1rem;
          border: none;
          border-radius: 3px;
          &.cancel-button {
            margin-right: 1rem;
            background-color: transparent;
            border: 1px solid $green;
            color: $green;
          }
        }
      }
    }
  }
  &.display-false {
    display: none;
  }
  z-index: 999;
}
@import '../../../config/style/mixins.scss';
@import '../../../config/style/style.scss';

.student-exams-table {
  @include displayFlex(column, start, center);
  width: 100%;
  height: 100dvh;
  .student-exams-table-title {
    width: 90%;
    margin-top: 5rem;
    h1 {
      text-align: start;
      font-style: italic;
      font-size: 2rem;
    }
  }
  .btn-student-exams-table {
    width: 90%;
    button {
      cursor: pointer;
      background-color: $green;
      padding: 0.5rem 1rem;
      margin-top: 2rem;
      border-radius: 5px;
      border: none;
      color: white;
    }
  }
  .student-exams-search-container {
    width: 90%;
    @include displayFlex(row, start, center);
    .search-element {
      width: auto;
      &:nth-child(1) {
        margin-right: 1rem;
      }
    }
  }
  .table-container {
    width: 90%;
    .table-content-header {
      @include displayFlex(row, start, center);
      width: 100%;
      border: none;
      border-top: 2px solid $green;
      border-radius: 5px;
      box-shadow: 1px 1px 3px $gray-for;
      .header-table-ul {
        display: grid;
        grid-template-columns: 0.3fr 3fr 2fr 2fr 1fr 0.5fr 0.5fr;
        list-style: none;
        width: 100%;
        li {
          text-align: start;
          font-size: 1.5rem;
          padding: 1rem;
          &.visualization-element {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.status-icon-element {
            padding: 1rem;
          }
        }
      }
    }
    .table-content {
      @include displayFlex(column, start, center);
      overflow-y: auto;
      width: 100%;
      height: 40vh;
      padding: 1rem;
      .empty-element-content {
        margin-top: 6rem;
      }
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $green;
        border-radius: 20px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .student-exams-table {
    .student-exams-search-container {
      width: 90%;
      @include displayFlex(column, center, start);
      .search-element {
        @include displayFlex(row, center, center);
        width: auto;
        &:nth-child(1) {
          margin-right: 0px;
          margin-bottom: 0px;
        }
        svg.search-element {
          margin-top: 0px;
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .student-exams-table {
    .student-exams-table-title {
      width: 98%;
    }
    .student-exams-search-container {
      width: 98%;
    }
    .btn-student-exams-table {
      width: 98%;
    }
    .table-container {
      width: 98%;
      .table-content-header {
        .header-table-ul {
          grid-template-columns: 3fr 2fr 2fr 1fr 0.5fr 0.5fr;
          li {
            &:nth-child(1) {
              display: none;
            }
          }
        }
      }
    }
  }
}

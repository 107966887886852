@import '../../../config/style/style.scss';
@import '../../../config/style/mixins.scss';

.student-exam-main {
  @include displayFlex(column, start, center);
  overflow-y: auto;
  position: relative;
  width: 100%;
  height: 90vh;
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
    border-radius: 20px;
  }
  .student-exam-main-header {
    @include displayFlex(row, space-between, center);
    width: 90%;
    margin-top: 2rem;
    .response-button-content {
      button {
        @include displayFlex(row, start, center);
        cursor: pointer;
        width: 200px;
        padding: 1rem 2rem;
        font-size: 1.7rem;
        text-align: start;
        background-color: $green;
        color: #fff;
        border: none;
        border-radius: 5px;
        span {
          margin-left: 0.5rem;
        }
        .icon-content {
          margin-left: 4rem;
          margin-right: 0rem;
          font-size: 2.5rem;          
        }
      }
    }
    .button-content {
      .back-button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        font-size: 1.5rem;
        background-color: #fff;
        color: $green;
        border: none;
        border-radius: 5px;
      }
      .gear-button {
        cursor: pointer;
        padding: 0.5rem 1rem;
        font-size: 1.5rem;
        background-color: #fff;
        color: $green;
        border: none;
        border-radius: 5px;
        .gear-icon {
          transition: all 0.4s ease-in-out;
          transform: rotate(0deg);
          &:hover {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .student-exam-main-title {
    width: 90%;
    margin-top: 3rem;
    font-size: 0.9rem;
    font-weight: 300;
    font-style: italic;

  }
  .settings-bar-right {
    @include displayFlex(row, end, center);
    position: fixed;
    top: 200px;
    width: 97%;
    button {
      @include displayFlex(row, center, center);
      cursor: pointer;
      border-radius: 100%;
      background-color: #fff;
      width: 50px;
      height: 50px;
      color: $green;
      border: none;
      box-shadow: 2px 3px 9px rgb(175, 175, 175);
    }
  }
  .student-exam-main-question-content {
    width: 90%;
    padding-bottom: 1rem;
    .default-img-content {
      @include displayFlex(row, center, center);
      width: 100%;
      img {
        width: 60%;
      }
    }
  }
}
